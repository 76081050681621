import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

const types = {
    GET_SUBJECT_MCQTEST_REQUESTED: "GET_SUBJECT_MCQTEST_REQUESTED",
    GET_SUBJECT_MCQTEST_SUCCEEDED: "GET_SUBJECT_MCQTEST_SUCCEEDED",
    GET_SUBJECT_MCQTEST_FAILED: "GET_SUBJECT_MCQTEST_FAILED",
    GET_SUBJECT_MCQTEST_LIST_REQUESTED: "GET_SUBJECT_MCQTEST_LIST_REQUESTED",
    GET_SUBJECT_MCQTEST_LIST_SUCCEEDED: "GET_SUBJECT_MCQTEST_LIST_SUCCEEDED",
    GET_SUBJECT_MCQTEST_LIST_FAILED: "GET_SUBJECT_MCQTEST_LIST_FAILED",
    GET_SUBJECT_MCQTEST_QUESTION_REQUESTED:
        "GET_SUBJECT_MCQTEST_QUESTION_REQUESTED",
    GET_SUBJECT_MCQTEST_QUESTION_SUCCEEDED:
        "GET_SUBJECT_MCQTEST_QUESTION_SUCCEEDED",
    GET_SUBJECT_MCQTEST_QUESTION_FAILED: "GET_SUBJECT_MCQTEST_QUESTION_FAILED",
    SEND_SUBJECT_MCQTEST_ANSWER_REQUESTED:
        "SEND_SUBJECT_MCQTEST_ANSWER_REQUESTED",
    SEND_SUBJECT_MCQTEST_ANSWER_SUCCEEDED:
        "SEND_SUBJECT_MCQTEST_ANSWER_SUCCEEDED",
    SEND_SUBJECT_MCQTEST_ANSWER_FAILED: "SEND_SUBJECT_MCQTEST_ANSWER_FAILED",
    GET_SUBJECT_MCQTEST_RESULTS_REQUESTED:
        "GET_SUBJECT_MCQTEST_RESULTS_REQUESTED",
    GET_SUBJECT_MCQTEST_RESULTS_SUCCEEDED:
        "GET_SUBJECT_MCQTEST_RESULTS_SUCCEEDED",
    GET_SUBJECT_MCQTEST_RESULTS_FAILED: "GET_SUBJECT_MCQTEST_RESULTS_FAILED",
    SEND_SUBJECT_MCQTEST_TIMEUP_REQUESTED:
        "SEND_SUBJECT_MCQTEST_TIMEUP_REQUESTED",
    SEND_SUBJECT_MCQTEST_TIMEUP_SUCCEEDED:
        "SEND_SUBJECT_MCQTEST_TIMEUP_SUCCEEDED",
    SEND_SUBJECT_MCQTEST_TIMEUP_FAILED: "SEND_SUBJECT_MCQTEST_TIMEUP_FAILED"
};

export const actions = {
    get: (testId, callback) => {
        return {
            type: types.GET_SUBJECT_MCQTEST_REQUESTED,
            testId,
            callback
        };
    },
    getAll: callback => {
        return {
            type: types.GET_SUBJECT_MCQTEST_LIST_REQUESTED,
            callback
        };
    },
    getQuestion: (testId, callback) => {
        return {
            type: types.GET_SUBJECT_MCQTEST_QUESTION_REQUESTED,
            testId,
            callback
        };
    },
    sendAnswer: (testId, questionId, optionId, callback) => {
        return {
            type: types.SEND_SUBJECT_MCQTEST_ANSWER_REQUESTED,
            testId,
            questionId,
            optionId,
            callback
        };
    },
    getResults: (testId, callback) => {
        return {
            type: types.GET_SUBJECT_MCQTEST_RESULTS_REQUESTED,
            testId,
            callback
        };
    },
    sendTimeUp: (testId, questionId, callback) => {
        return {
            type: types.SEND_SUBJECT_MCQTEST_TIMEUP_REQUESTED,
            testId,
            questionId,
            callback
        };
    }
};

const defaultState = {
    single: {},
    singleLoading: false,
    question: {},
    questionLoading: false,
    list: [],
    listLoading: false,
    sendingAnswer: false,
    results: null,
    resultsLoading: false,
    error: ""
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_SUBJECT_MCQTEST_REQUESTED:
            return {
                ...state,
                single: {},
                singleLoading: true
            };
        case types.GET_SUBJECT_MCQTEST_SUCCEEDED:
            return {
                ...state,
                single: action.payload.data,
                singleLoading: false
            };
        case types.GET_SUBJECT_MCQTEST_FAILED:
            return {
                ...state,
                singleLoading: false
            };
        case types.GET_SUBJECT_MCQTEST_LIST_REQUESTED:
            return {
                ...state,
                list: [],
                listLoading: true
            };
        case types.GET_SUBJECT_MCQTEST_LIST_SUCCEEDED:
            return {
                ...state,
                list: action.payload.data,
                listLoading: false
            };
        case types.GET_SUBJECT_MCQTEST_LIST_FAILED:
            return {
                ...state,
                listLoading: false
            };
        case types.GET_SUBJECT_MCQTEST_QUESTION_REQUESTED:
            return {
                ...state,
                questionLoading: true
            };
        case types.GET_SUBJECT_MCQTEST_QUESTION_SUCCEEDED:
            return {
                ...state,
                question: action.payload.data,
                questionLoading: false
            };
        case types.GET_SUBJECT_MCQTEST_QUESTION_FAILED:
            return {
                ...state,
                questionLoading: false
            };
        case types.SEND_SUBJECT_MCQTEST_TIMEUP_REQUESTED:
        case types.SEND_SUBJECT_MCQTEST_ANSWER_REQUESTED:
            return {
                ...state,
                sendingAnswer: true
            };
        case types.SEND_SUBJECT_MCQTEST_TIMEUP_SUCCEEDED:
        case types.SEND_SUBJECT_MCQTEST_ANSWER_SUCCEEDED:
            return {
                ...state,
                question: {},
                sendingAnswer: false
            };
        case types.SEND_SUBJECT_MCQTEST_TIMEUP_FAILED:
        case types.SEND_SUBJECT_MCQTEST_ANSWER_FAILED:
            return {
                ...state,
                sendingAnswer: false
            };
        case types.GET_SUBJECT_MCQTEST_RESULTS_REQUESTED:
            return {
                ...state,
                results: null,
                resultsLoading: true
            };
        case types.GET_SUBJECT_MCQTEST_RESULTS_SUCCEEDED:
            return {
                ...state,
                results: action.payload.data,
                resultsLoading: false
            };
        case types.GET_SUBJECT_MCQTEST_RESULTS_FAILED:
            return {
                ...state,
                results: null,
                resultsLoading: false
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(types.GET_SUBJECT_MCQTEST_REQUESTED, getWorker);
    yield takeLatest(types.GET_SUBJECT_MCQTEST_LIST_REQUESTED, getListWorker);
    yield takeLatest(
        types.GET_SUBJECT_MCQTEST_QUESTION_REQUESTED,
        getQuestionWorker
    );
    yield takeLatest(
        types.SEND_SUBJECT_MCQTEST_ANSWER_REQUESTED,
        sendAnswerWorker
    );
    yield takeLatest(
        types.SEND_SUBJECT_MCQTEST_TIMEUP_REQUESTED,
        sendTimeUpWorker
    );
    yield takeLatest(
        types.GET_SUBJECT_MCQTEST_RESULTS_REQUESTED,
        getResultsWorker
    );
}

// Saga callback
function* getWorker({ testId, callback }) {
    try {
        const response = yield call(get, { testId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_MCQTEST_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_SUBJECT_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getListWorker({ callback }) {
    try {
        const response = yield call(getList);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_MCQTEST_LIST_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_SUBJECT_MCQTEST_LIST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getQuestionWorker({ testId, callback }) {
    try {
        const response = yield call(getQuestion, { testId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_MCQTEST_QUESTION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_SUBJECT_MCQTEST_QUESTION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* sendAnswerWorker({ testId, questionId, optionId, callback }) {
    try {
        const response = yield call(sendAnswer, {
            testId,
            questionId,
            optionId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEND_SUBJECT_MCQTEST_ANSWER_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        if (e.errors == "Time passed") {
            yield put({
                type: types.SEND_SUBJECT_MCQTEST_ANSWER_SUCCEEDED,
                payload: null
            });
            callSuccess(callback, null);
        } else {
            yield put({
                type: types.SEND_SUBJECT_MCQTEST_ANSWER_FAILED,
                payload: e.errors
            });
            callFail(callback, e.errors);
        }
    }
}

function* sendTimeUpWorker({ testId, questionId, callback }) {
    try {
        const response = yield call(sendTimeUp, {
            testId,
            questionId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEND_SUBJECT_MCQTEST_TIMEUP_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SEND_SUBJECT_MCQTEST_TIMEUP_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getResultsWorker({ testId, callback }) {
    try {
        const response = yield call(getResults, {
            testId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_MCQTEST_RESULTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_SUBJECT_MCQTEST_RESULTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function get({ testId }) {
    return api.get(`/api/subject/skill-tests/${testId}`);
}

function getResults({ testId }) {
    return api.get(`/api/skill-test/${testId}/results`);
}

function getList() {
    return api.get(`/api/subject/skill-tests`);
}

function getQuestion({ testId }) {
    return api.get(`/api/skill-test/${testId}/questions`);
}

function sendAnswer({ testId, questionId, optionId }) {
    return api.post(`/api/skill-test/${testId}/answers`, {
        question_id: questionId,
        option_id: optionId
    });
}

function sendTimeUp({ testId, questionId }) {
    return api.post(`/api/skill-test/${testId}/time-up`, {
        question_id: questionId
    });
}
