import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_TEST_RESULT_REQUESTED: "GET_TEST_RESULT_REQUESTED",
    GET_TEST_RESULT_SUCCEEDED: "GET_TEST_RESULT_SUCCEEDED",
    GET_TEST_RESULT_FAILED: "GET_TEST_RESULT_FAILED",
    GET_TEAM_COMPARISON_RESULTS_REQUESTED:
        "GET_TEAM_COMPARISON_RESULTS_REQUESTED",
    GET_TEAM_COMPARISON_RESULTS_SUCCEEDED:
        "GET_TEAM_COMPARISON_RESULTS_SUCCEEDED",
    GET_TEAM_COMPARISON_RESULTS_FAILED: "GET_TEAM_COMPARISON_RESULTS_FAILED",
    GET_COMPARISON_TEXTS_REQUESTED: "GET_COMPARISON_TEXTS_REQUESTED",
    GET_COMPARISON_TEXTS_SUCCEEDED: "GET_COMPARISON_TEXTS_SUCCEEDED",
    GET_COMPARISON_TEXTS_FAILED: "GET_COMPARISON_TEXTS_FAILED",
    GET_CAMPAIGN_RESULT_REQUESTED: "GET_CAMPAIGN_RESULT_REQUESTED",
    GET_CAMPAIGN_RESULT_SUCCEEDED: "GET_CAMPAIGN_RESULT_SUCCEEDED",
    GET_CAMPAIGN_RESULT_FAILED: "GET_CAMPAIGN_RESULT_FAILED",
    GET_INTERPERSONAL_FIT_REQUESTED: "GET_INTERPERSONAL_FIT_REQUESTED",
    GET_INTERPERSONAL_FIT_SUCCEEDED: "GET_INTERPERSONAL_FIT_SUCCEEDED",
    GET_INTERPERSONAL_FIT_FAILED: "GET_INTERPERSONAL_FIT_FAILED"
};

// Action Creators
export const actions = {
    getComparisonTexts: callback => {
        return {
            type: types.GET_COMPARISON_TEXTS_REQUESTED,
            callback
        };
    },
    getCampaignResult: (campaignId, callback) => {
        return {
            type: types.GET_CAMPAIGN_RESULT_REQUESTED,
            campaignId,
            callback
        };
    },
    getTestResult: (subjectId, callback) => {
        return {
            type: types.GET_TEST_RESULT_REQUESTED,
            subjectId,
            callback
        };
    },
    getTeamComparisonResults: (subjectIds, callback) => {
        return {
            type: types.GET_TEAM_COMPARISON_RESULTS_REQUESTED,
            subjectIds,
            callback
        };
    },
    getInterpersonalFit: (subject1, subject2, callback) => {
        return {
            type: types.GET_INTERPERSONAL_FIT_REQUESTED,
            subject1,
            subject2,
            callback
        };
    }
};

// Default state
const defaultState = {
    tests: [],
    test: {},
    result: {},
    results: {},
    campaignResults: {},
    comparisonTexts: [],
    teamComparisonResults: [],
    teamComparisonResultsLoading: true,
    interpersonalFit: null,
    interpersonalFitLoading: false,
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_INTERPERSONAL_FIT_REQUESTED:
            return {
                ...state,
                interpersonalFit: null,
                interpersonalFitLoading: true
            };
        case types.GET_INTERPERSONAL_FIT_SUCCEEDED:
            return {
                ...state,
                interpersonalFit: action.payload.data,
                interpersonalFitLoading: false
            };
        case types.GET_INTERPERSONAL_FIT_FAILED:
            return {
                ...state,
                interpersonalFit: null,
                interpersonalFitLoading: false
            };
        case types.GET_TEAM_COMPARISON_RESULTS_REQUESTED:
            return {
                ...state,
                teamComparisonResultsLoading: true
            };
        case types.GET_TEAM_COMPARISON_RESULTS_SUCCEEDED:
            return {
                ...state,
                teamComparisonResults: action.payload.data,
                teamComparisonResultsLoading: false
            };
        case types.GET_TEAM_COMPARISON_RESULTS_FAILED:
            return {
                ...state,
                teamComparisonResultsLoading: false
            };
        case types.GET_CAMPAIGN_RESULT_REQUESTED:
        case types.GET_TEST_RESULT_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_CAMPAIGN_RESULT_SUCCEEDED:
            return {
                ...state,
                campaignResults: {
                    ...state.campaignResults,
                    [action.campaignId]: action.result
                },
                loading: false
            };
        case types.GET_TEST_RESULT_SUCCEEDED:
            return {
                ...state,
                result: action.result,
                results: {
                    ...state.results,
                    [action.subjectId]: action.result
                },
                loading: false
            };
        case types.GET_CAMPAIGN_RESULT_FAILED:
        case types.GET_TEST_RESULT_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case types.GET_COMPARISON_TEXTS_SUCCEEDED:
            return {
                ...state,
                comparisonTexts: action.texts
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeEvery(types.GET_TEST_RESULT_REQUESTED, startGetTestResultWorker);
    yield takeEvery(
        types.GET_CAMPAIGN_RESULT_REQUESTED,
        startGetCampaignResultWorker
    );
    yield takeLatest(
        types.GET_COMPARISON_TEXTS_REQUESTED,
        startGetComparisonTextsWorker
    );
    yield takeLatest(
        types.GET_TEAM_COMPARISON_RESULTS_REQUESTED,
        startGetTeamComparisonResultsWorker
    );
    yield takeLatest(
        types.GET_INTERPERSONAL_FIT_REQUESTED,
        startGetInterpersonalFitWorker
    );
}

// Saga callback
function* startGetTestResultWorker({ subjectId, callback }) {
    try {
        const response = yield call(getTestResult, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_TEST_RESULT_SUCCEEDED,
            result: response.data,
            subjectId
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.error({ e });
        yield put({ type: types.GET_TEST_RESULT_FAILED, error: e.error });
    }
}

function* startGetCampaignResultWorker({ campaignId, callback }) {
    try {
        const response = yield call(getCampaignResult, { campaignId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CAMPAIGN_RESULT_SUCCEEDED,
            result: response.data,
            campaignId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        callFail(callback, e.errors);
        yield put({ type: types.GET_CAMPAIGN_RESULT_FAILED, error: e.errors });
    }
}

function* startGetComparisonTextsWorker({ callback }) {
    try {
        const response = yield call(getComparisonTexts);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPARISON_TEXTS_SUCCEEDED,
            texts: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.error({ e });
        yield put({ type: types.GET_COMPARISON_TEXTS_FAILED, error: e.error });
    }
}

function* startGetTeamComparisonResultsWorker({ subjectIds, callback }) {
    try {
        const response = yield call(getTeamComparisonResults, {
            subjectIds
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_TEAM_COMPARISON_RESULTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.error);
        callFail(callback, e.errors);
        yield put({
            type: types.GET_TEAM_COMPARISON_RESULTS_FAILED,
            error: e.error
        });
    }
}

function* startGetInterpersonalFitWorker({ subject1, subject2, callback }) {
    try {
        const response = yield call(getInterpersonalFit, {
            subject1,
            subject2
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_INTERPERSONAL_FIT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.error);
        callFail(callback, e.errors);
        yield put({
            type: types.GET_INTERPERSONAL_FIT_FAILED,
            error: e.error
        });
    }
}

// API call
function getTestResult({ subjectId }) {
    return api.get(`/api/admin/results/personality-test/subject/${subjectId}`);
}

function getCampaignResult({ campaignId }) {
    return api.get(
        `/api/admin/campaigns/${campaignId}/personality-test/average-results`
    );
}

function getComparisonTexts() {
    return api.get(`/api/personality-test/comparison-texts`);
}

function getTeamComparisonResults({ subjectIds }) {
    const subjectsParam = subjectIds.map(
        subjectId => `subjects[]=${subjectId}`
    );

    return api.get(
        `/api/admin/results/personality-test/subjects/?${subjectsParam.join(
            "&"
        )}`
    );
}

function getInterpersonalFit({ subject1, subject2 }) {
    return api.get(`/api/admin/interpersonal-fit/${subject1}/${subject2}`);
}
