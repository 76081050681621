import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

const types = {
    SAVE_PROFILE_REQUESTED: "SAVE_PROFILE_REQUESTED",
    SAVE_PROFILE_SUCCEEDED: "SAVE_PROFILE_SUCCEEDED",
    SAVE_PROFILE_FAILED: "SAVE_PROFILE_FAILED",
    GET_PROFILE_REQUESTED: "GET_PROFILE_REQUESTED",
    GET_PROFILE_SUCCEEDED: "GET_PROFILE_SUCCEEDED",
    GET_PROFILE_FAILED: "GET_PROFILE_FAILED",
    DELETE_PROFILE_REQUESTED: "DELETE_PROFILE_REQUESTED",
    DELETE_PROFILE_SUCCEEDED: "DELETE_PROFILE_SUCCEEDED",
    DELETE_PROFILE_FAILED: "DELETE_PROFILE_FAILED",
    DELETE_SUBJECT_TESTS_REQUESTED: "DELETE_SUBJECT_TESTS_REQUESTED",
    DELETE_SUBJECT_TESTS_SUCCEEDED: "DELETE_SUBJECT_TESTS_SUCCEEDED",
    DELETE_SUBJECT_TESTS_FAILED: "DELETE_SUBJECT_TESTS_FAILED",
    REQUEST_TO_DELETE_SUBJECT_TESTS_REQUESTED:
        "REQUEST_TO_DELETE_SUBJECT_TESTS_REQUESTED",
    REQUEST_TO_DELETE_SUBJECT_TESTS_SUCCEEDED:
        "REQUEST_TO_DELETE_SUBJECT_TESTS_SUCCEEDED",
    REQUEST_TO_DELETE_SUBJECT_TESTS_FAILED:
        "REQUEST_TO_DELETE_SUBJECT_TESTS_FAILED"
};

export const actions = {
    saveProfile: (data, callback) => {
        return {
            type: types.SAVE_PROFILE_REQUESTED,
            data,
            callback
        };
    },
    getProfile: callback => {
        return {
            type: types.GET_PROFILE_REQUESTED,
            callback
        };
    },
    deleteProfile: callback => {
        return {
            type: types.DELETE_PROFILE_REQUESTED,
            callback
        };
    },
    deleteTests: (tests, callback) => {
        return {
            type: types.DELETE_SUBJECT_TESTS_REQUESTED,
            tests,
            callback
        };
    },
    deleteTestsRequest: (tests, callback) => {
        return {
            type: types.REQUEST_TO_DELETE_SUBJECT_TESTS_REQUESTED,
            tests,
            callback
        };
    }
};

const defaultState = {
    data: {},
    subject: {},
    results: {},
    error: "",
    loading: true
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SAVE_PROFILE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data
            };
        case types.SAVE_PROFILE_FAILED:
            return {
                ...state,
                error: action.payload
            };
        case types.GET_PROFILE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_PROFILE_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data.profile,
                subject: action.payload.data.subject,
                results: action.payload.data.results,
                error: "",
                loading: false
            };
        case types.GET_PROFILE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(types.SAVE_PROFILE_REQUESTED, startSaveProfileWorker);
    yield takeLatest(types.GET_PROFILE_REQUESTED, startGetProfileWorker);
    yield takeLatest(types.DELETE_PROFILE_REQUESTED, startDeleteProfileWorker);
    yield takeLatest(
        types.DELETE_SUBJECT_TESTS_REQUESTED,
        startDeleteTestsWorker
    );
    yield takeLatest(
        types.REQUEST_TO_DELETE_SUBJECT_TESTS_REQUESTED,
        startRequestDeleteTestsWorker
    );
}

// Saga callback
function* startGetProfileWorker({ callback }) {
    try {
        const response = yield call(getProfile);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_PROFILE_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_PROFILE_FAILED, payload: e.errors });
    }
}

function* startSaveProfileWorker({ data, callback }) {
    try {
        const response = yield call(saveProfile, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PROFILE_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.SAVE_PROFILE_FAILED, payload: e.error });
    }
}

function* startDeleteProfileWorker({ callback }) {
    try {
        const response = yield call(deleteProfile);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_PROFILE_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.DELETE_PROFILE_FAILED, payload: e.errors });
    }
}

function* startDeleteTestsWorker({ tests, callback }) {
    try {
        const response = yield call(deleteTests, {
            tests
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_SUBJECT_TESTS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.DELETE_SUBJECt_TESTS_FAILED, error: e.error });
        callFail(callback, e.errors);
    }
}

function* startRequestDeleteTestsWorker({ tests, callback }) {
    try {
        const response = yield call(requestDeleteTests, {
            tests
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REQUEST_TO_DELETE_SUBJECT_TESTS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.REQUEST_TO_DELETE_SUBJECt_TESTS_FAILED,
            error: e.error
        });
        callFail(callback, e.errors);
    }
}

function getProfile() {
    return api.get("/api/subject-profile");
}

function deleteProfile() {
    return api.delete("/api/subject-profile");
}

function saveProfile({ data }) {
    let form = new FormData();

    // api.setHeader("Content-Type", "multipart/form-data");

    const country = data.country.split(" , ");
    const degree = data.degree.split(" , ");

    form.append("birthdate", data.dob);
    form.append("gender", data.gender);
    form.append("country_name", country[0]);
    form.append("country_id", parseInt(country[1]));
    form.append("degree_name", degree[0]);
    form.append("degree_id", parseInt(degree[1]));

    if (data.country_origin) {
        const countryOrigin = data.country_origin.split(" , ");
        form.append("country_origin_name", countryOrigin[0]);
        form.append("country_origin_id", parseInt(countryOrigin[1]));
        form.append("alpha3code", countryOrigin[2]);
    }
    if (data.major) {
        form.append("major_name", data.major.name);
        form.append("major_id", data.major.id);
    }

    if (data.job_title) {
        form.append("job_title", data.job_title);
    } else {
        form.append("job_title", "");
    }

    if (data.job_profile_id) {
        form.append("job_profile_id", data.job_profile_id);
    } else {
        form.append("job_profile_id", "");
    }

    if (data.linkedin_url) {
        form.append("linkedin_url", data.linkedin_url);
    } else {
        form.append("linkedin_url", "");
    }

    if (data.first_name) {
        form.append("first_name", data.first_name);
    }
    if (data.last_name) {
        form.append("last_name", data.last_name);
    }

    // if (data.cv_file) {
    //     form.append("cv_file", data.cv_file);
    // }

    return api.post("/api/subject-profile", form);
}

function deleteTests({ tests }) {
    return api.delete(`/api/subject-profile/tests`, {
        data: {
            test_type_ids: tests
        }
    });
}

function requestDeleteTests({ tests }) {
    return api.post(`/api/subject-profile/ask-to-delete-tests`, {
        data: {
            test_type_ids: tests
        }
    });
}
