import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_SUBJECT_ROLE_PINS_REQUESTED: "GET_SUBJECT_ROLE_PINS_REQUESTED",
    GET_SUBJECT_ROLE_PINS_SUCCEEDED: "GET_SUBJECT_ROLE_PINS_SUCCEEDED",
    GET_SUBJECT_ROLE_PINS_FAILED: "GET_SUBJECT_ROLE_PINS_FAILED",
    GET_SUBJECT_SUCCESSION_PINS_REQUESTED:
        "GET_SUBJECT_SUCCESSION_PINS_REQUESTED",
    GET_SUBJECT_SUCCESSION_PINS_SUCCEEDED:
        "GET_SUBJECT_SUCCESSION_PINS_SUCCEEDED",
    GET_SUBJECT_SUCCESSION_PINS_FAILED: "GET_SUBJECT_SUCCESSION_PINS_FAILED",
    PIN_SUBJECT_PINS_REQUESTED: "PIN_SUBJECT_PINS_REQUESTED",
    PIN_SUBJECT_PINS_SUCCEEDED: "PIN_SUBJECT_PINS_SUCCEEDED",
    PIN_SUBJECT_PINS_FAILED: "PIN_SUBJECT_PINS_FAILED",
    UNPIN_SUBJECT_PINS_REQUESTED: "UNPIN_SUBJECT_PINS_REQUESTED",
    UNPIN_SUBJECT_PINS_SUCCEEDED: "UNPIN_SUBJECT_PINS_SUCCEEDED",
    UNPIN_SUBJECT_PINS_FAILED: "UNPIN_SUBJECT_PINS_FAILED"
};

// Action Creators
export const actions = {
    getRolePins: (subjectId, callback) => {
        return {
            type: types.GET_SUBJECT_ROLE_PINS_REQUESTED,
            subjectId,
            callback
        };
    },
    getSuccessionPins: (subjectId, callback) => {
        return {
            type: types.GET_SUBJECT_SUCCESSION_PINS_REQUESTED,
            subjectId,
            callback
        };
    },
    pin: (subjectId, entityId, pinType, roleType, callback) => {
        return {
            type: types.PIN_SUBJECT_PINS_REQUESTED,
            subjectId,
            entityId,
            roleType,
            pinType,
            callback
        };
    },
    unpin: (subjectId, entityId, pinType, roleType, callback) => {
        return {
            type: types.UNPIN_SUBJECT_PINS_REQUESTED,
            subjectId,
            entityId,
            roleType,
            pinType,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    rolePins: [],
    rolePinsLoading: false,
    successionPins: [],
    successionPinsLoading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.PIN_SUBJECT_PINS_REQUESTED:
            return {
                ...state,
                rolePins:
                    action.pinType == "role"
                        ? [
                              ...state.rolePins,
                              {
                                  role_id: action.entityId,
                                  type: action.roleType
                              }
                          ]
                        : [...state.rolePins],
                successionPins:
                    action.pinType == "succession"
                        ? [...state.successionPins, action.entityId]
                        : [...state.successionPins]
            };
        case types.PIN_SUBJECT_PINS_SUCCEEDED:
            return {
                ...state
            };
        case types.PIN_SUBJECT_PINS_FAILED:
            return {
                ...state
            };
        case types.UNPIN_SUBJECT_PINS_REQUESTED:
            return {
                ...state,
                rolePins:
                    action.pinType == "role"
                        ? [
                              ...state.rolePins.filter(pin => {
                                  return !(
                                      pin.role_id == action.entityId &&
                                      pin.type == action.roleType
                                  );
                              })
                          ]
                        : [...state.rolePins],
                successionPins:
                    action.pinType == "succession"
                        ? [
                              ...state.successionPins.filter(
                                  pin => pin != action.entityId
                              )
                          ]
                        : [...state.successionPins]
            };
        case types.UNPIN_SUBJECT_PINS_SUCCEEDED:
            return {
                ...state
            };
        case types.UNPIN_SUBJECT_PINS_FAILED:
            return {
                ...state
            };
        case types.GET_SUBJECT_ROLE_PINS_REQUESTED:
            return {
                ...state,
                rolePinsLoading: true
            };
        case types.GET_SUBJECT_ROLE_PINS_SUCCEEDED:
            return {
                ...state,
                rolePins: action.payload.map(pin => {
                    return { role_id: pin.role_id, type: pin.type };
                }),
                rolePinsLoading: true
            };
        case types.GET_SUBJECT_ROLE_PINS_FAILED:
            return {
                ...state,
                rolePins: [],
                rolePinsLoading: true
            };
        case types.GET_SUBJECT_SUCCESSION_PINS_REQUESTED:
            return {
                ...state,
                successionPinsLoading: true
            };
        case types.GET_SUBJECT_SUCCESSION_PINS_SUCCEEDED:
            return {
                ...state,
                successionPins: action.payload.map(pin => pin.successor_id),
                successionPinsLoading: true
            };
        case types.GET_SUBJECT_SUCCESSION_PINS_FAILED:
            return {
                ...state,
                successionPins: [],
                successionPinsLoading: true
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_SUBJECT_ROLE_PINS_REQUESTED, getRolePinsWorker);
    yield takeLatest(
        types.GET_SUBJECT_SUCCESSION_PINS_REQUESTED,
        getSuccessionPinsWorker
    );
    yield takeLatest(types.PIN_SUBJECT_PINS_REQUESTED, pinWorker);
    yield takeLatest(types.UNPIN_SUBJECT_PINS_REQUESTED, unpinWorker);
}

// Saga callback
function* getRolePinsWorker({ subjectId, callback }) {
    try {
        const response = yield call(getRolePins, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_ROLE_PINS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_SUBJECT_ROLE_PINS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getSuccessionPinsWorker({ subjectId, callback }) {
    try {
        const response = yield call(getSuccessionPins, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_SUCCESSION_PINS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_SUBJECT_SUCCESSION_PINS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* pinWorker({ subjectId, entityId, roleType, pinType, callback }) {
    try {
        const response = yield call(pin, {
            subjectId,
            entityId,
            roleType,
            pinType
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.PIN_SUBJECT_PINS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.PIN_SUBJECT_PINS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* unpinWorker({ subjectId, entityId, roleType, pinType, callback }) {
    try {
        const response = yield call(unpin, {
            subjectId,
            entityId,
            roleType,
            pinType
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UNPIN_SUBJECT_PINS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.UNPIN_SUBJECT_PINS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function getRolePins({ subjectId }) {
    return api.get(`/api/admin/subject/pins/${subjectId}/role`);
}

function getSuccessionPins({ subjectId }) {
    return api.get(`/api/admin/subject/pins/${subjectId}/succession`);
}

function pin({ subjectId, entityId, roleType, pinType }) {
    return api.put(
        `/api/admin/subject/pins/pin/${subjectId}/${pinType}/${entityId}/${roleType}`
    );
}

function unpin({ subjectId, entityId, roleType, pinType }) {
    return api.put(
        `/api/admin/subject/pins/unpin/${subjectId}/${pinType}/${entityId}/${roleType}`
    );
}
