/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Button from "@material-ui/core/Button";

const NavLink = ({ active = false, children, css: additional, ...props }) => {
    return (
        <Button
            css={css`
                display: flex;
                align-items: center;
                padding: 1.25rem 1rem;
                text-transform: capitalize;
                border-radius: 0;

                font-size: 1rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: ${active ? "#2000ff" : "#37455d"};
                border-bottom: 0.125rem solid #fff;
                border-bottom-color: ${active ? "#2000ff" : "#fff"};

                &:hover {
                    background: #fff;
                    border-bottom-color: #2000ff;
                    color: #2000ff;
                    text-decoration: none;
                }

                ${additional};
            `}
            {...props}
        >
            {children}
        </Button>
    );
};

export default NavLink;
