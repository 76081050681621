import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

const types = {
    GET_LABELS_REQUESTED: "GET_LABELS_REQUESTED",
    GET_LABELS_SUCCEEDED: "GET_LABELS_SUCCEEDED",
    GET_LABELS_FAILED: "GET_LABELS_FAILED",
    DELETE_LABELS_REQUESTED: "DELETE_LABELS_REQUESTED",
    DELETE_LABELS_SUCCEEDED: "DELETE_LABELS_SUCCEEDED",
    DELETE_LABELS_FAILED: "DELETE_LABELS_FAILED",
    EDIT_LABEL_REQUESTED: "EDIT_LABEL_REQUESTED",
    EDIT_LABEL_SUCCEEDED: "EDIT_LABEL_SUCCEEDED",
    EDIT_LABEL_FAILED: "EDIT_LABEL_FAILED",
    CREATE_LABEL_REQUESTED: "CREATE_LABEL_REQUESTED",
    CREATE_LABEL_SUCCEEDED: "CREATE_LABEL_SUCCEEDED",
    CREATE_LABEL_FAILED: "CREATE_LABEL_FAILED",
    GET_LABEL_REQUESTED: "GET_LABEL_REQUESTED",
    GET_LABEL_SUCCEEDED: "GET_LABEL_SUCCEEDED",
    GET_LABEL_FAILED: "GET_LABEL_FAILED",
    ASSIGN_LABELS_REQUESTED: "ASSIGN_LABELS_REQUESTED",
    ASSIGN_LABELS_SUCCEEDED: "ASSIGN_LABELS_SUCCEEDED",
    ASSIGN_LABELS_FAILED: "ASSIGN_LABELS_FAILED",
    ASSIGN_MULTIPLE_SUBJECTS_LABELS_REQUESTED:
        "ASSIGN_MULTIPLE_SUBJECTS_LABELS_REQUESTED",
    ASSIGN_MULTIPLE_SUBJECTS_LABELS_SUCCEEDED:
        "ASSIGN_MULTIPLE_SUBJECTS_LABELS_SUCCEEDED",
    ASSIGN_MULTIPLE_SUBJECTS_LABELS_FAILED:
        "ASSIGN_MULTIPLE_SUBJECTS_LABELS_FAILED",
    GET_SUBJECT_LABELS_REQUESTED: "GET_SUBJECT_LABELS_REQUESTED",
    GET_SUBJECT_LABELS_SUCCEEDED: "GET_SUBJECT_LABELS_SUCCEEDED",
    GET_SUBJECT_LABELS_FAILED: "GET_SUBJECT_LABELS_FAILED"
};

export const actions = {
    getAll: callback => {
        return {
            type: types.GET_LABELS_REQUESTED,
            callback
        };
    },
    remove: (id, callback) => {
        return {
            type: types.DELETE_LABELS_REQUESTED,
            id,
            callback
        };
    },
    create: (data, callback) => {
        return {
            type: types.CREATE_LABEL_REQUESTED,
            data,
            callback
        };
    },
    edit: (id, data, callback) => {
        return {
            type: types.EDIT_LABEL_REQUESTED,
            id,
            data,
            callback
        };
    },
    get: (id, callback) => {
        return {
            type: types.GET_LABEL_REQUESTED,
            id,
            callback
        };
    },
    assign: (ids, subjectId, callback) => {
        return {
            type: types.ASSIGN_LABELS_REQUESTED,
            ids,
            subjectId,
            callback
        };
    },
    assignMultiple: (ids, subjectIds, callback) => {
        return {
            type: types.ASSIGN_MULTIPLE_SUBJECTS_LABELS_REQUESTED,
            ids,
            subjectIds,
            callback
        };
    },
    getSubjectLabels: (subjectId, callback) => {
        return {
            type: types.GET_SUBJECT_LABELS_REQUESTED,
            subjectId,
            callback
        };
    }
};

const defaultState = {
    list: [],
    single: {},
    error: "",
    listLoading: false,
    singleLoading: false,
    subjectLabels: [],
    subjectLabelsLoading: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_LABELS_REQUESTED:
            return {
                ...state,
                listLoading: true
            };
        case types.GET_LABELS_SUCCEEDED:
            return {
                ...state,
                list: action.payload,
                listLoading: false
            };
        case types.GET_LABELS_FAILED:
        case types.DELETE_LABELS_FAILED:
            return {
                ...state,
                error: action.error
            };
        case types.DELETE_LABELS_REQUESTED:
            return {
                ...state,
                listLoading: true
            };
        case types.DELETE_LABELS_SUCCEEDED:
            return {
                ...state,
                listLoading: false,
                list: state.list.filter(el => el.id !== action.payload)
            };
        case types.GET_LABEL_REQUESTED:
            return {
                ...state,
                single: {},
                error: "",
                singleLoading: true
            };
        case types.GET_LABEL_SUCCEEDED:
            return {
                ...state,
                single: action.data,
                error: "",
                singleLoading: false
            };
        case types.GET_LABEL_FAILED:
            return {
                ...state,
                single: {},
                error: action.payload,
                singleLoading: false
            };
        case types.GET_SUBJECT_LABELS_REQUESTED:
            return {
                ...state,
                subjectLabels: [],
                subjectLabelsLoading: true
            };
        case types.GET_SUBJECT_LABELS_SUCCEEDED:
            return {
                ...state,
                subjectLabels: action.payload,
                subjectLabelsLoading: false
            };
        case types.GET_SUBJECT_LABELS_FAILED:
            return {
                ...state,
                subjectLabels: [],
                subjectLabelsLoading: false
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(types.GET_LABELS_REQUESTED, startGetLabelsWorker);
    yield takeLatest(types.DELETE_LABELS_REQUESTED, startDeleteLabelWorker);
    yield takeLatest(types.CREATE_LABEL_REQUESTED, startCreateLabelWorker);
    yield takeLatest(types.EDIT_LABEL_REQUESTED, startEditLabelWorker);
    yield takeLatest(types.GET_LABEL_REQUESTED, startGetLabelWorker);
    yield takeLatest(types.ASSIGN_LABELS_REQUESTED, startAssignLabelsWorker);
    yield takeLatest(
        types.ASSIGN_MULTIPLE_SUBJECTS_LABELS_REQUESTED,
        startAssignMultipleLabelsWorker
    );
    yield takeLatest(
        types.GET_SUBJECT_LABELS_REQUESTED,
        startGetSubjectLabelsWorker
    );
}

// Saga callback
function* startGetLabelsWorker({ callback }) {
    try {
        const response = yield call(getLabels);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_LABELS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.GET_LABELS_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function* startDeleteLabelWorker({ id, callback }) {
    try {
        const response = yield call(removeLabel, { id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_LABELS_SUCCEEDED,
            payload: id
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.DELETE_LABELS_FAILED, payload: e.error });
        callFail(callback, e.errors);
    }
}

function* startCreateLabelWorker({ data, callback }) {
    try {
        const response = yield call(createLabel, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.CREATE_LABEL_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.CREATE_LABEL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* startEditLabelWorker({ id, data, callback }) {
    try {
        const response = yield call(editLabel, { id, data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EDIT_LABEL_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.EDIT_LABEL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* startGetLabelWorker({ id, callback }) {
    try {
        const response = yield call(getLabel, { id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_LABEL_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_LABEL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* startAssignLabelsWorker({ ids, subjectId, callback }) {
    try {
        const response = yield call(assignLabels, { ids, subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_LABEL_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_LABEL_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* startAssignMultipleLabelsWorker({ ids, subjectIds, callback }) {
    try {
        const response = yield call(assignMultipleLabels, { ids, subjectIds });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ASSIGN_MULTIPLE_SUBJECTS_LABELS_SUCCEEDED
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.ASSIGN_MULTIPLE_SUBJECTS_LABELS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* startGetSubjectLabelsWorker({ subjectId, callback }) {
    try {
        const response = yield call(getSubjectLabels, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_LABELS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_SUBJECT_LABELS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function getLabels() {
    return api.get("/api/admin/labels");
}

function removeLabel({ id }) {
    return api.delete(`/api/admin/labels/${id}`);
}

function createLabel({ data }) {
    return api.post(`/api/admin/labels`, { ...data });
}

function editLabel({ id, data }) {
    return api.put(`/api/admin/labels/${id}`, { ...data });
}

function getLabel({ id }) {
    return api.get(`/api/admin/labels/${id}`);
}

function assignLabels({ ids, subjectId }) {
    return api.post(`/api/admin/labels/subject`, {
        labels: ids,
        subject_id: subjectId
    });
}

function assignMultipleLabels({ ids, subjectIds }) {
    return api.post(`/api/admin/labels/multiple-subject`, {
        labels: ids,
        subjects: subjectIds
    });
}

function getSubjectLabels({ subjectId }) {
    return api.get(`/api/admin/subject/labels/${subjectId}`);
}
