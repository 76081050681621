import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { stopSubmit } from "redux-form";
import findIndex from "lodash/findIndex";
import { callSuccess, callFail } from "Util/callback";
import extractErrorForForm from "Util/extractErrorForForm";

// Actions
const types = {
    GET_ORGANIZATIONS_REQUESTED: "GET_ORGANIZATIONS_REQUESTED",
    GET_ORGANIZATIONS_SUCCEEDED: "GET_ORGANIZATIONS_SUCCEEDED",
    GET_ORGANIZATIONS_FAILED: "GET_ORGANIZATIONS_FAILED",
    GET_ORGANIZATIONS_PAGE_REQUESTED: "GET_ORGANIZATIONS_PAGE_REQUESTED",
    GET_ORGANIZATIONS_PAGE_SUCCEEDED: "GET_ORGANIZATIONS_PAGE_SUCCEEDED",
    GET_ORGANIZATIONS_PAGE_FAILED: "GET_ORGANIZATIONS_PAGE_FAILED",
    SAVE_ORGANIZATION_REQUESTED: "SAVE_ORGANIZATION_REQUESTED",
    SAVE_ORGANIZATION_SUCCEEDED: "SAVE_ORGANIZATION_SUCCEEDED",
    SAVE_ORGANIZATION_FAILED: "SAVE_ORGANIZATION_FAILED",
    GET_ORGANIZATION_REQUESTED: "GET_ORGANIZATION_REQUESTED",
    GET_ORGANIZATION_SUCCEEDED: "GET_ORGANIZATION_SUCCEEDED",
    GET_ORGANIZATION_FAILED: "GET_ORGANIZATION_FAILED",
    SAVE_MANAGER_REQUESTED: "SAVE_MANAGER_REQUESTED",
    SAVE_MANAGER_SUCCEEDED: "SAVE_MANAGER_SUCCEEDED",
    SAVE_MANAGER_FAILED: "SAVE_MANAGER_FAILED",
    DELETE_ORGANIZATION_REQUESTED: "DELETE_ORGANIZATION_REQUESTED",
    DELETE_ORGANIZATION_SUCCEEDED: "DELETE_ORGANIZATION_SUCCEEDED",
    DELETE_ORGANIZATION_FAILED: "DELETE_ORGANIZATION_FAILED",
    DELETE_MANAGER_REQUESTED: "DELETE_MANAGER_REQUESTED",
    DELETE_MANAGER_SUCCEEDED: "DELETE_MANAGER_SUCCEEDED",
    DELETE_MANAGER_FAILED: "DELETE_MANAGER_FAILED",
    GET_FILTERED_ORGANIZATIONS_REQUESTED:
        "GET_FILTERED_ORGANIZATIONS_REQUESTED",
    GET_FILTERED_ORGANIZATIONS_SUCCEEDED:
        "GET_FILTERED_ORGANIZATIONS_SUCCEEDED",
    GET_FILTERED_ORGANIZATIONS_FAILED: "GET_FILTERED_ORGANIZATIONS_FAILED",
    GET_ORGANIZATION_PACKAGES_REQUESTED: "GET_ORGANIZATION_PACKAGES_REQUESTED",
    GET_ORGANIZATION_PACKAGES_SUCCEEDED: "GET_ORGANIZATION_PACKAGES_SUCCEEDED",
    GET_ORGANIZATION_PACKAGES_FAILED: "GET_ORGANIZATION_PACKAGES_FAILED",
    RENEW_ORGANIZATION_PACKAGE_REQUESTED:
        "RENEW_ORGANIZATION_PACKAGE_REQUESTED",
    RENEW_ORGANIZATION_PACKAGE_SUCCEEDED:
        "RENEW_ORGANIZATION_PACKAGE_SUCCEEDED",
    RENEW_ORGANIZATION_PACKAGE_FAILED: "RENEW_ORGANIZATION_PACKAGE_FAILED",
    GET_ORGANIZATION_SUBJECTS_COUNT_REQUESTED:
        "GET_ORGANIZATION_SUBJECTS_COUNT_REQUESTED",
    GET_ORGANIZATION_SUBJECTS_COUNT_SUCCEEDED:
        "GET_ORGANIZATION_SUBJECTS_COUNT_SUCCEEDED",
    GET_ORGANIZATION_SUBJECTS_COUNT_FAILED:
        "GET_ORGANIZATION_SUBJECTS_COUNT_FAILED"
};

// Action Creators
export const actions = {
    getOrganizations: callback => {
        return {
            type: types.GET_ORGANIZATIONS_REQUESTED,
            callback
        };
    },
    getOrganizationSubjectsCount: (organizationId, from, to, callback) => {
        return {
            type: types.GET_ORGANIZATION_SUBJECTS_COUNT_REQUESTED,
            organizationId,
            from,
            to,
            callback
        };
    },
    getOrganizationsPage: (page, callback) => {
        return {
            type: types.GET_ORGANIZATIONS_PAGE_REQUESTED,
            page,
            callback
        };
    },
    getFilteredOrganizations: (page, name, order, callback) => {
        return {
            type: types.GET_FILTERED_ORGANIZATIONS_REQUESTED,
            page,
            name,
            order,
            callback
        };
    },
    saveOrganization: (organization, organizationId, callback) => {
        return {
            type: types.SAVE_ORGANIZATION_REQUESTED,
            organization,
            organizationId,
            callback
        };
    },
    getOrganization: (organizationId, callback) => {
        return {
            type: types.GET_ORGANIZATION_REQUESTED,
            organizationId,
            callback
        };
    },
    saveManager: (manager, organizationId, callback) => {
        return {
            type: types.SAVE_MANAGER_REQUESTED,
            manager,
            organizationId,
            callback
        };
    },
    delete: (id, callback) => {
        return {
            type: types.DELETE_ORGANIZATION_REQUESTED,
            id,
            callback
        };
    },
    deleteManager: (id, callback) => {
        return {
            type: types.DELETE_MANAGER_REQUESTED,
            id,
            callback
        };
    },
    getPackages: callback => {
        return {
            type: types.GET_ORGANIZATION_PACKAGES_REQUESTED,
            callback
        };
    },
    renewPackage: (organizationId, callback) => {
        return {
            type: types.RENEW_ORGANIZATION_PACKAGE_REQUESTED,
            organizationId,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    organizationSubjectsCount: null,
    organizations: [],
    organization: {},
    packages: [],
    packagesLoading: true,
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_ORGANIZATION_SUBJECTS_COUNT_SUCCEEDED:
            return {
                ...state,
                organizationSubjectsCount: action.payload.data
            };
        case types.GET_ORGANIZATION_PACKAGES_SUCCEEDED:
            return {
                ...state,
                packages: action.packages,
                packagesLoading: false
            };
        case types.GET_ORGANIZATIONS_SUCCEEDED:
            return {
                ...state,
                organizations: action.organizations,
                error: "",
                loading: false
            };
        case types.SAVE_ORGANIZATION_SUCCEEDED:
            return {
                ...state,
                error: "",
                loading: false
            };
        case types.GET_ORGANIZATION_SUCCEEDED:
            return {
                ...state,
                organization: action.organization,
                error: "",
                loading: false
            };
        case types.RENEW_ORGANIZATION_PACKAGE_SUCCEEDED:
            return {
                ...state,
                organization: action.organization,
                error: "",
                loading: false
            };
        case types.DELETE_ORGANIZATION_SUCCEEDED: {
            const index = findIndex(state.organizations, {
                id: action.id
            });
            const organizations = [...state.organizations];
            if (index !== -1) {
                organizations.splice(index, 1);
            }

            return {
                ...state,
                organizations,
                error: "",
                loading: false
            };
        }
        case types.DELETE_MANAGER_SUCCEEDED:
            const index = findIndex(state.organization.managers, {
                id: action.id
            });
            const managers = [...state.organization.managers];
            if (index !== -1) {
                managers.splice(index, 1);
            }

            return {
                ...state,
                organization: { ...state.organization, managers },
                error: "",
                loading: false
            };
        case types.SAVE_ORGANIZATION_REQUESTED:
        case types.SAVE_MANAGER_REQUESTED:
        case types.GET_ORGANIZATIONS_REQUESTED:
        case types.GET_ORGANIZATION_REQUESTED:
        case types.DELETE_ORGANIZATION_REQUESTED:
        case types.DELETE_MANAGER_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_ORGANIZATION_PACKAGES_REQUESTED:
            return {
                ...state,
                packagesLoading: true
            };
        case types.SAVE_ORGANIZATION_FAILED:
        case types.SAVE_MANAGER_FAILED:
        case types.GET_ORGANIZATIONS_FAILED:
        case types.GET_ORGANIZATION_FAILED:
        case types.DELETE_ORGANIZATION_FAILED:
        case types.DELETE_MANAGER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_ORGANIZATION_PACKAGES_FAILED:
            return {
                ...state,
                packagesLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.GET_ORGANIZATIONS_REQUESTED,
        startGetOrganizationsWorker
    );
    yield takeLatest(
        types.GET_ORGANIZATION_SUBJECTS_COUNT_REQUESTED,
        startGetOrganizationSubjectsCountWorker
    );
    yield takeLatest(
        types.GET_ORGANIZATIONS_PAGE_REQUESTED,
        startGetOrganizationsPageWorker
    );
    yield takeLatest(
        types.SAVE_ORGANIZATION_REQUESTED,
        startSaveOrganizationWorker
    );
    yield takeLatest(types.SAVE_MANAGER_REQUESTED, startSaveManagerWorker);
    yield takeLatest(
        types.GET_ORGANIZATION_REQUESTED,
        startGetOrganizationWorker
    );
    yield takeLatest(
        types.DELETE_ORGANIZATION_REQUESTED,
        startDeleteOrganizationWorker
    );
    yield takeLatest(types.DELETE_MANAGER_REQUESTED, startDeleteManagerWorker);
    yield takeLatest(
        types.GET_FILTERED_ORGANIZATIONS_REQUESTED,
        startGetFilteredOrganizationsWorker
    );
    yield takeLatest(
        types.GET_ORGANIZATION_PACKAGES_REQUESTED,
        startGetOrganizationPackagesWorker
    );
    yield takeLatest(
        types.RENEW_ORGANIZATION_PACKAGE_REQUESTED,
        startRenewOrganizationPackageWorker
    );
}

// Saga callback
function* startGetOrganizationPackagesWorker({ callback }) {
    try {
        const response = yield call(getOrganizationPackages);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATION_PACKAGES_SUCCEEDED,
            packages: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        callFail(callback, e.errors);
        yield put({
            type: types.GET_ORGANIZATION_PACKAGES_FAILED,
            error: e.errors
        });
    }
}

function* startRenewOrganizationPackageWorker({ organizationId, callback }) {
    try {
        const response = yield call(postRenewPackage, { organizationId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.RENEW_ORGANIZATION_PACKAGE_SUCCEEDED,
            organization: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        callFail(callback, e.errors);
        yield put({
            type: types.RENEW_ORGANIZATION_PACKAGE_FAILED,
            error: e.errors
        });
    }
}

function* startGetOrganizationsWorker({ callback }) {
    try {
        const response = yield call(getOrganizations);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATIONS_SUCCEEDED,
            organizations: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_ORGANIZATIONS_FAILED, payload: e.error });
    }
}

function* startGetOrganizationSubjectsCountWorker({
    organizationId,
    from,
    to,
    callback
}) {
    try {
        const response = yield call(getOrganizationSubjectsCount, {
            organizationId,
            from,
            to
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATION_SUBJECTS_COUNT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        callFail(callback, e.errors);
        yield put({
            type: types.GET_ORGANIZATION_SUBJECTS_COUNT_FAILED,
            payload: e.error
        });
    }
}

function* startGetOrganizationsPageWorker({ page, callback }) {
    try {
        const response = yield call(getOrganizationsPage, { page });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATIONS_PAGE_SUCCEEDED,
            organizations: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_ORGANIZATIONS_PAGE_FAILED,
            payload: e.error
        });
    }
}

function* startSaveOrganizationWorker({
    organization,
    organizationId,
    callback
}) {
    try {
        const response = yield call(saveOrganization, {
            organization,
            organizationId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_ORGANIZATION_SUCCEEDED
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        // console.log(e);
        yield put({ type: types.SAVE_ORGANIZATION_FAILED, payload: e.errors });
        const formError = extractErrorForForm(e.errors);
        yield put(stopSubmit("organization", formError));
        window.scrollTo(0, 0);
    }
}

function* startSaveManagerWorker({ manager, organizationId, callback }) {
    try {
        const response = yield call(saveManager, { manager, organizationId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_MANAGER_SUCCEEDED
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.SAVE_MANAGER_FAILED, payload: e.message });
        const formError = extractErrorForForm(e.errors);
        yield put(stopSubmit("manager", formError));
        window.scrollTo(0, 0);
    }
}

function* startGetOrganizationWorker({ organizationId, callback }) {
    try {
        const response = yield call(getOrganization, { organizationId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATION_SUCCEEDED,
            organization: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_ORGANIZATION_FAILED,
            payload: e.error
        });
    }
}

function* startDeleteOrganizationWorker({ id, callback }) {
    try {
        const response = yield call(deleteOrganization, { id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_ORGANIZATION_SUCCEEDED,
            id: id
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.DELETE_ORGANIZATION_FAILED, payload: e.error });
    }
}

function* startDeleteManagerWorker({ id, callback }) {
    try {
        const response = yield call(deleteManager, { id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_MANAGER_SUCCEEDED,
            id: id
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.DELETE_MANAGER_FAILED, payload: e.error });
    }
}

function* startGetFilteredOrganizationsWorker({ page, name, order, callback }) {
    try {
        const response = yield call(getFilteredOrganizations, {
            page,
            name,
            order
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_FILTERED_ORGANIZATIONS_SUCCEEDED
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_FILTERED_ORGANIZATIONS_FAILED,
            payload: e.error
        });
    }
}

// API call
function postRenewPackage({ organizationId }) {
    const sendData = { organization_id: organizationId };
    return api.put("/api/super-admin/organizations/packages/reset", {
        ...sendData
    });
}

function getOrganizationPackages() {
    return api.get("/api/super-admin/organizations/packages");
}

function getOrganizations() {
    return api.get("/api/super-admin/organizations");
}

function getOrganizationsPage({ page }) {
    return api.get(`/api/super-admin/organizations?page=${page}`);
}

function getOrganizationSubjectsCount({ organizationId, from, to }) {
    return api.get(
        `/api/super-admin/organizations/${organizationId}/subjects-count/${from}/${to}`
    );
}

function saveOrganization({ organization, organizationId }) {
    let form = new FormData();

    form.append("organization_name", organization.organization_name);

    if (organization.package_id) {
        form.append("package_id", organization.package_id);
    }

    if (organization.feature_ids && organization.feature_ids.length) {
        organization.feature_ids.map(fi => {
            form.append("feature_ids[]", fi);
        });
    }

    if (organization.logo) {
        form.append("logo", organization.logo);
    }

    api.setHeader("Content-Type", "multipart/form-data");

    if (organizationId) {
        form.append("organization_id", organizationId);
        form.append("_method", "PUT");
    }

    if (organization.organization_status) {
        form.append("status", organization.organization_status);
    }

    return api.post("/api/super-admin/organizations", form);
}

function saveManager({ manager, organizationId }) {
    const sendData = { ...manager, organization_id: organizationId };
    return api.post("/api/super-admin/managers", { ...sendData });
}

function getOrganization({ organizationId }) {
    return api.get("/api/super-admin/organizations/" + organizationId);
}

function deleteOrganization({ id }) {
    return api.delete("/api/super-admin/organizations/" + id);
}

function deleteManager({ id }) {
    return api.delete("/api/super-admin/managers/" + id);
}

function getFilteredOrganizations({
    page = 1,
    name = "",
    perPage = 10,
    order = ""
}) {
    let url = `/api/super-admin/organizations/filter`;
    let first = true;

    if (order) {
        url = url + (first ? "?" : "&") + `order=${order}`;
        first = false;
    }

    if (perPage) {
        url = url + (first ? "?" : "&") + `perPage=${perPage}`;
        first = false;
    }

    if (page) {
        url = url + (first ? "?" : "&") + `page=${page}`;
        first = false;
    }

    if (name) {
        url = url + (first ? "?" : "&") + `name=${name}`;
        first = false;
    }

    return api.get(
        // `/api/admin/test-type/${testType}/subjects/status/${status}/filter?page=${page}`
        url
    );
}
