import React, { lazy } from "react";

import AuthRoute from "AdminUtil/AuthRoute";

const NewManager = lazy(() => import("./NewManager"));
const NewOrganization = lazy(() => import("./NewOrganization"));
const Organization = lazy(() => import("./Organization"));
const Organizations = lazy(() => import("./Organizations"));
const EditOrganization = lazy(() => import("./EditOrganization"));
const OrganizationCultureTest = lazy(() => import("./culture/Test"));
const OrganizationNewCultureTest = lazy(() => import("./culture/NewTest"));
const OrganizationCultureResults = lazy(() => import("./culture/Results"));
const OrganizationCultureAdminResults = lazy(() =>
    import("./culture/AdminResults")
);
const OrganizationSubscription = lazy(() => import("./Subscription"));
const OrganizationSubscriptionSuccess = lazy(() =>
    import("./SubscriptionSuccess")
);

import routes from "AdminPages/routes";

const definitions = [
    <AuthRoute
        key={routes.ORGANIZATION_SUBSCRIPTION}
        path={routes.ORGANIZATION_SUBSCRIPTION}
        component={OrganizationSubscription}
    />,
    <AuthRoute
        key={routes.ORGANIZATION_SUBSCRIPTION_SUCCESS}
        path={routes.ORGANIZATION_SUBSCRIPTION_SUCCESS}
        component={OrganizationSubscriptionSuccess}
    />,
    <AuthRoute
        key={routes.ORGANIZATION_CULTURE_RESULTS}
        path={routes.ORGANIZATION_CULTURE_RESULTS}
        component={OrganizationCultureResults}
    />,
    <AuthRoute
        key={routes.ORGANIZATION_CULTURE_ADMIN_RESULTS}
        path={routes.ORGANIZATION_CULTURE_ADMIN_RESULTS}
        component={OrganizationCultureAdminResults}
    />,
    <AuthRoute
        key={routes.ORGANIZATION_NEW_CULTURE_TEST}
        path={routes.ORGANIZATION_NEW_CULTURE_TEST}
        component={OrganizationNewCultureTest}
    />,
    <AuthRoute
        key={routes.ORGANIZATION_CULTURE_TEST}
        path={routes.ORGANIZATION_CULTURE_TEST}
        component={OrganizationCultureTest}
    />,
    <AuthRoute
        key={routes.NEW_MANAGER}
        path={routes.NEW_MANAGER}
        component={NewManager}
    />,
    <AuthRoute
        key={routes.NEW_ORGANIZATION}
        path={routes.NEW_ORGANIZATION}
        component={NewOrganization}
    />,
    <AuthRoute
        key={routes.ORGANIZATION}
        path={routes.ORGANIZATION}
        component={Organization}
    />,
    <AuthRoute
        key={routes.EDIT_ORGANIZATION}
        path={routes.EDIT_ORGANIZATION}
        component={EditOrganization}
    />,
    <AuthRoute
        key={routes.ORGANIZATIONS}
        path={routes.ORGANIZATIONS}
        component={Organizations}
    />
];

export default definitions;
