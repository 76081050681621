import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    SEARCH_SUBJECTS_FOR_REVIEW_REQUESTED:
        "SEARCH_SUBJECTS_FOR_REVIEW_REQUESTED",
    SEARCH_SUBJECTS_FOR_REVIEW_SUCCEEDED:
        "SEARCH_SUBJECTS_FOR_REVIEW_SUCCEEDED",
    SEARCH_SUBJECTS_FOR_REVIEW_FAILED: "SEARCH_SUBJECTS_FOR_REVIEW_FAILED"
};

// Action Creators
export const actions = {
    search: (query, page, callback) => {
        return {
            type: types.SEARCH_SUBJECTS_FOR_REVIEW_REQUESTED,
            query,
            page,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    results: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SEARCH_SUBJECTS_FOR_REVIEW_REQUESTED:
            return {
                ...state,
                results: [],
                error: "",
                loading: true
            };
        case types.SEARCH_SUBJECTS_FOR_REVIEW_SUCCEEDED:
            return {
                ...state,
                results: action.data,
                error: "",
                loading: false
            };
        case types.SEARCH_SUBJECTS_FOR_REVIEW_FAILED:
            return {
                ...state,
                results: [],
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.SEARCH_SUBJECTS_FOR_REVIEW_REQUESTED, searchWorker);
}

// Saga callback
function* searchWorker({ query, page, callback }) {
    try {
        const response = yield call(search, { query, page });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEARCH_SUBJECTS_FOR_REVIEW_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.SEARCH_SUBJECTS_FOR_REVIEW_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function search({ query, page }) {
    return api.get(
        `/api/admin/reviews/subjects/filter?name=${query.name}&testType=${query.testTypes}&page=${page}`
    );
}
