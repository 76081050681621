// Actions
const types = {
    SET_SETTINGS_REQUESTED: "SET_SETTINGS_REQUESTED"
};

// Action Creators
export const actions = {
    set: (setting, value) => {
        return {
            type: types.SET_SETTINGS_REQUESTED,
            setting,
            value
        };
    }
};

// Dedault state
const defaultState = {
    data: {}
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_SETTINGS_REQUESTED:
            return {
                ...state,
                data: { ...state.data, [action.setting]: action.value }
            };
        default:
            return state;
    }
}

export function* saga() {}
