import { takeLatest, call, put, takeLeading } from "redux-saga/effects";

import api from "Util/api";
import findIndex from "lodash/findIndex";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_CAMPAIGNS_REQUESTED: "GET_CAMPAIGNS_REQUESTED",
    GET_CAMPAIGNS_SUCCEEDED: "GET_CAMPAIGNS_SUCCEEDED",
    GET_CAMPAIGNS_FAILED: "GET_CAMPAIGNS_FAILED",
    GET_COMPARE_CAMPAIGNS_REQUESTED: "GET_COMPARE_CAMPAIGNS_REQUESTED",
    GET_COMPARE_CAMPAIGNS_SUCCEEDED: "GET_COMPARE_CAMPAIGNS_SUCCEEDED",
    GET_COMPARE_CAMPAIGNS_FAILED: "GET_COMPARE_CAMPAIGNS_FAILED",
    GET_CAMPAIGNS_PAGE_REQUESTED: "GET_CAMPAIGNS_PAGE_REQUESTED",
    GET_CAMPAIGNS_PAGE_SUCCEEDED: "GET_CAMPAIGNS_PAGE_SUCCEEDED",
    GET_CAMPAIGNS_PAGE_FAILED: "GET_CAMPAIGNS_PAGE_FAILED",
    SAVE_CAMPAIGN_REQUESTED: "SAVE_CAMPAIGN_REQUESTED",
    SAVE_CAMPAIGN_SUCCEEDED: "SAVE_CAMPAIGN_SUCCEEDED",
    SAVE_CAMPAIGN_FAILED: "SAVE_CAMPAIGN_FAILED",
    EDIT_ACTIVE_CAMPAIGN_REQUESTED: "EDIT_ACTIVE_CAMPAIGN_REQUESTED",
    EDIT_ACTIVE_CAMPAIGN_SUCCEEDED: "EDIT_ACTIVE_CAMPAIGN_SUCCEEDED",
    EDIT_ACTIVE_CAMPAIGN_FAILED: "EDIT_ACTIVE_CAMPAIGN_FAILED",
    COPY_CAMPAIGN_REQUESTED: "COPY_CAMPAIGN_REQUESTED",
    COPY_CAMPAIGN_SUCCEEDED: "COPY_CAMPAIGN_SUCCEEDED",
    COPY_CAMPAIGN_FAILED: "COPY_CAMPAIGN_FAILED",
    ACTIVATE_CAMPAIGN_REQUESTED: "ACTIVATE_CAMPAIGN_REQUESTED",
    ACTIVATE_CAMPAIGN_SUCCEEDED: "ACTIVATE_CAMPAIGN_SUCCEEDED",
    ACTIVATE_CAMPAIGN_FAILED: "ACTIVATE_CAMPAIGN_FAILED",
    GET_CAMPAIGN_REQUESTED: "GET_CAMPAIGN_REQUESTED",
    GET_CAMPAIGN_SUCCEEDED: "GET_CAMPAIGN_SUCCEEDED",
    GET_CAMPAIGN_FAILED: "GET_CAMPAIGN_FAILED",
    DELETE_CAMPAIGN_REQUESTED: "DELETE_CAMPAIGN_REQUESTED",
    DELETE_CAMPAIGN_SUCCEEDED: "DELETE_CAMPAIGN_SUCCEEDED",
    DELETE_CAMPAIGN_FAILED: "DELETE_CAMPAIGN_FAILED",
    ASK_PERMISSION_REQUESTED: "ASK_PERMISSION_REQUESTED",
    ASK_PERMISSION_SUCCEEDED: "ASK_PERMISSION_SUCCEEDED",
    ASK_PERMISSION_FAILED: "ASK_PERMISSION_FAILED",
    ADD_TO_CAMPAIGN_REQUESTED: "ADD_TO_CAMPAIGN_REQUESTED",
    ADD_TO_CAMPAIGN_SUCCEEDED: "ADD_TO_CAMPAIGN_SUCCEEDED",
    ADD_TO_CAMPAIGN_FAILED: "ADD_TO_CAMPAIGN_FAILED",
    DEFAULT_EMAIL_TEXT_REQUESTED: "DEFAULT_EMAIL_TEXT_REQUESTED",
    DEFAULT_EMAIL_TEXT_SUCCEEDED: "DEFAULT_EMAIL_TEXT_SUCCEEDED",
    DEFAULT_EMAIL_TEXT_FAILED: "DEFAULT_EMAIL_TEXT_FAILED",
    DEFAULT_EMAIL_REMINDER_TEXT_REQUESTED:
        "DEFAULT_EMAIL_REMINDER_TEXT_REQUESTED",
    DEFAULT_EMAIL_REMINDER_TEXT_SUCCEEDED:
        "DEFAULT_EMAIL_REMINDER_TEXT_SUCCEEDED",
    DEFAULT_EMAIL_REMINDER_TEXT_FAILED: "DEFAULT_EMAIL_REMINDER_TEXT_FAILED",
    GET_TABULAR_RESULTS_REQUESTED: "GET_TABULAR_RESULTS_REQUESTED",
    GET_TABULAR_RESULTS_SUCCEEDED: "GET_TABULAR_RESULTS_SUCCEEDED",
    GET_TABULAR_RESULTS_FAILED: "GET_TABULAR_RESULTS_FAILED",
    GET_FILTERED_CAMPAIGNS_REQUESTED: "GET_FILTERED_CAMPAIGNS_REQUESTED",
    GET_FILTERED_CAMPAIGNS_SUCCEEDED: "GET_FILTERED_CAMPAIGNS_SUCCEEDED",
    GET_FILTERED_CAMPAIGNS_FAILED: "GET_FILTERED_CAMPAIGNS_FAILED",
    GET_CAMPAIGN_PROFILES_REQUESTED: "GET_CAMPAIGN_PROFILES_REQUESTED",
    GET_CAMPAIGN_PROFILES_SUCCEEDED: "GET_CAMPAIGN_PROFILES_SUCCEEDED",
    GET_CAMPAIGN_PROFILES_FAILED: "GET_CAMPAIGN_PROFILES_FAILED",
    GET_CUSTOM_CAMPAIGN_PROFILES_REQUESTED:
        "GET_CUSTOM_CAMPAIGN_PROFILES_REQUESTED",
    GET_CUSTOM_CAMPAIGN_PROFILES_SUCCEEDED:
        "GET_CUSTOM_CAMPAIGN_PROFILES_SUCCEEDED",
    GET_CUSTOM_CAMPAIGN_PROFILES_FAILED: "GET_CUSTOM_CAMPAIGN_PROFILES_FAILED",
    GET_ALT_CAMPAIGN_PROFILES_REQUESTED: "GET_ALT_CAMPAIGN_PROFILES_REQUESTED",
    GET_ALT_CAMPAIGN_PROFILES_SUCCEEDED: "GET_ALT_CAMPAIGN_PROFILES_SUCCEEDED",
    GET_ALT_CAMPAIGN_PROFILES_FAILED: "GET_ALT_CAMPAIGN_PROFILES_FAILED",
    GET_GROUPED_CAMPAIGN_PROFILES_REQUESTED:
        "GET_GROUPED_CAMPAIGN_PROFILES_REQUESTED",
    GET_GROUPED_CAMPAIGN_PROFILES_SUCCEEDED:
        "GET_GROUPED_CAMPAIGN_PROFILES_SUCCEEDED",
    GET_GROUPED_CAMPAIGN_PROFILES_FAILED:
        "GET_GROUPED_CAMPAIGN_PROFILES_FAILED",
    GET_CAMPAIGN_QR_CODE_REQUESTED: "GET_CAMPAIGN_QR_CODE_REQUESTED",
    GET_CAMPAIGN_QR_CODE_SUCCEEDED: "GET_CAMPAIGN_QR_CODE_SUCCEEDED",
    GET_CAMPAIGN_QR_CODE_FAILED: "GET_CAMPAIGN_QR_CODE_FAILED"
};

// Action Creators
export const actions = {
    getCompareCampaigns: callback => {
        return {
            type: types.GET_COMPARE_CAMPAIGNS_REQUESTED,
            callback
        };
    },
    getCampaigns: callback => {
        return {
            type: types.GET_CAMPAIGNS_REQUESTED,
            callback
        };
    },
    saveCampaign: (campaign, campaignId, callback) => {
        return {
            type: types.SAVE_CAMPAIGN_REQUESTED,
            campaign,
            campaignId,
            callback
        };
    },
    editActiveCampaign: (campaign, campaignId, callback) => {
        return {
            type: types.EDIT_ACTIVE_CAMPAIGN_REQUESTED,
            campaign,
            campaignId,
            callback
        };
    },
    copyCampaign: (campaign, campaignId, callback) => {
        return {
            type: types.COPY_CAMPAIGN_REQUESTED,
            campaign,
            campaignId,
            callback
        };
    },
    addSubjectsToCampaign: (campaign, campaignId, callback) => {
        return {
            type: types.ADD_TO_CAMPAIGN_REQUESTED,
            campaign,
            campaignId,
            callback
        };
    },
    activateCampaign: (campaignId, callback) => {
        return {
            type: types.ACTIVATE_CAMPAIGN_REQUESTED,
            campaignId,
            callback
        };
    },
    getCampaign: (campaignId, callback) => {
        return {
            type: types.GET_CAMPAIGN_REQUESTED,
            campaignId,
            callback
        };
    },
    delete: (id, callback) => {
        return {
            type: types.DELETE_CAMPAIGN_REQUESTED,
            id,
            callback
        };
    },
    askForPermission: (subjectId, callback) => {
        return {
            type: types.ASK_PERMISSION_REQUESTED,
            subjectId,
            callback
        };
    },
    getDataForPage: (page, callback) => {
        return {
            type: types.GET_CAMPAIGNS_PAGE_REQUESTED,
            page,
            callback
        };
    },
    getFilteredCampaigns: (page, name, perPage, order, createdAt, callback) => {
        return {
            type: types.GET_FILTERED_CAMPAIGNS_REQUESTED,
            page,
            name,
            perPage,
            order,
            createdAt,
            callback
        };
    },
    getDefaultEmailText: (campaignId, callback) => {
        return {
            type: types.DEFAULT_EMAIL_TEXT_REQUESTED,
            campaignId,
            callback
        };
    },
    getDefaultEmailReminderText: (campaignId, callback) => {
        return {
            type: types.DEFAULT_EMAIL_REMINDER_TEXT_REQUESTED,
            campaignId,
            callback
        };
    },
    getTabularResults: (campaignId, testName, callback) => {
        return {
            type: types.GET_TABULAR_RESULTS_REQUESTED,
            campaignId,
            testName,
            callback
        };
    },
    getCampaignProfiles: callback => {
        return {
            type: types.GET_CAMPAIGN_PROFILES_REQUESTED,
            callback
        };
    },
    getCustomCampaignProfiles: callback => {
        return {
            type: types.GET_CUSTOM_CAMPAIGN_PROFILES_REQUESTED,
            callback
        };
    },
    getAltCampaignProfiles: callback => {
        return {
            type: types.GET_ALT_CAMPAIGN_PROFILES_REQUESTED,
            callback
        };
    },
    getGroupedCampaignProfiles: callback => {
        return {
            type: types.GET_GROUPED_CAMPAIGN_PROFILES_REQUESTED,
            callback
        };
    },
    getCampaignQRCode: (campaignId, callback) => {
        return {
            type: types.GET_CAMPAIGN_QR_CODE_REQUESTED,
            campaignId,
            callback
        };
    }
};

// DEFAULT state
const defaultState = {
    campaigns: [],
    compareCampaigns: [],
    compareCampaignsLoading: true,
    campaign: {},
    campaignQRCode: null,
    activePage: 1,
    totalPages: 1,
    totalItems: 0,
    tabularResults: [],
    campaignProfiles: [],
    campaignProfilesLoading: true,
    customCampaignProfiles: [],
    customCampaignProfilesLoading: true,
    altCampaignProfiles: [],
    altCampaignProfilesLoading: true,
    groupedCampaignProfiles: [],
    groupedCampaignProfilesLoading: true,
    error: "",
    loading: true,
    submitting: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.ADD_TO_CAMPAIGN_REQUESTED:
            return {
                ...state,
                submitting: true
            };
        case types.ADD_TO_CAMPAIGN_SUCCEEDED:
        case types.ADD_TO_CAMPAIGN_FAILED:
            return {
                ...state,
                submitting: false
            };
        case types.ASK_PERMISSION_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.ASK_PERMISSION_SUCCEEDED:
            const index = findIndex(state.campaign.subjects, {
                id: action.subjectId
            });
            const subjects = [...state.campaign.subjects];
            subjects[index] = {
                ...subjects[index],
                permissionAsked: true
            };
            const campaign = { ...state.campaign, subjects };

            return {
                ...state,
                campaign,
                error: "",
                loading: false
            };
        case types.ASK_PERMISSION_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_CAMPAIGNS_SUCCEEDED:
            return {
                ...state,
                campaigns: action.campaigns,
                error: "",
                loading: false
            };
        case types.GET_COMPARE_CAMPAIGNS_REQUESTED:
            return {
                ...state,
                error: "",
                compareCampaignsLoading: true
            };
        case types.GET_COMPARE_CAMPAIGNS_SUCCEEDED:
            return {
                ...state,
                compareCampaigns: action.campaigns,
                error: "",
                compareCampaignsLoading: false
            };
        case types.GET_COMPARE_CAMPAIGNS_FAILED:
            return {
                ...state,
                error: action.payload,
                compareCampaignsLoading: false
            };
        case types.GET_CAMPAIGNS_PAGE_SUCCEEDED:
            return {
                ...state,
                campaigns: action.campaigns,
                totalPages: action.totalPages,
                activePage: action.activePage,
                totalItems: action.totalItems,
                error: "",
                loading: false
            };
        case types.GET_CAMPAIGNS_REQUESTED:
        case types.GET_CAMPAIGNS_PAGE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_CAMPAIGNS_FAILED:
        case types.GET_CAMPAIGNS_PAGE_FAILED:
            return {
                ...state,
                loading: false
            };
        case types.SAVE_CAMPAIGN_REQUESTED:
            return {
                ...state,
                submitting: true
            };
        case types.SAVE_CAMPAIGN_SUCCEEDED:
            return {
                ...state,
                error: "",
                loading: false,
                submitting: false
            };
        case types.EDIT_ACTIVE_CAMPAIGN_REQUESTED:
            return {
                ...state,
                submitting: true
            };
        case types.EDIT_ACTIVE_CAMPAIGN_SUCCEEDED:
            return {
                ...state,
                error: "",
                loading: false,
                submitting: false
            };
        case types.COPY_CAMPAIGN_SUCCEEDED:
            return {
                ...state,
                error: "",
                loading: false,
                submitting: false
            };
        case types.SAVE_CAMPAIGN_FAILED:
        case types.EDIT_ACTIVE_CAMPAIGN_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
                submitting: false
            };
        case types.COPY_CAMPAIGN_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
                submitting: false
            };
        case types.GET_CAMPAIGN_SUCCEEDED:
            return {
                ...state,
                campaign: action.campaign,
                error: "",
                loading: false
            };
        case types.DELETE_CAMPAIGN_SUCCEEDED:
            const campaignIndex = findIndex(state.campaigns, {
                id: action.id
            });
            const campaigns = [...state.campaigns];
            if (campaignIndex !== -1) {
                campaigns.splice(campaignIndex, 1);
            }

            return {
                ...state,
                campaigns,
                error: "",
                loading: false
            };
        case types.GET_CAMPAIGN_REQUESTED:
        case types.DELETE_CAMPAIGN_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_TABULAR_RESULTS_SUCCEEDED:
            return {
                ...state,
                tabularResults: action.payload.data,
                loading: false
            };
        case types.GET_CAMPAIGN_FAILED:
        case types.DELETE_CAMPAIGN_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_CAMPAIGN_PROFILES_REQUESTED:
            return {
                ...state,
                campaignProfilesLoading: true
            };
        case types.GET_CAMPAIGN_PROFILES_SUCCEEDED:
            return {
                ...state,
                campaignProfiles: action.payload,
                campaignProfilesLoading: false
            };
        case types.GET_CAMPAIGN_PROFILES_FAILED:
            return {
                ...state,
                campaignProfilesLoading: false,
                error: action.payload
            };
        case types.GET_CUSTOM_CAMPAIGN_PROFILES_REQUESTED:
            return {
                ...state,
                customCampaignProfilesLoading: true
            };
        case types.GET_CUSTOM_CAMPAIGN_PROFILES_SUCCEEDED:
            return {
                ...state,
                customCampaignProfiles: action.payload,
                customCampaignProfilesLoading: false
            };
        case types.GET_CUSTOM_CAMPAIGN_PROFILES_FAILED:
            return {
                ...state,
                customCampaignProfilesLoading: false,
                error: action.payload
            };
        case types.GET_ALT_CAMPAIGN_PROFILES_REQUESTED:
            return {
                ...state,
                altCampaignProfilesLoading: true
            };
        case types.GET_ALT_CAMPAIGN_PROFILES_SUCCEEDED:
            return {
                ...state,
                altCampaignProfiles: action.payload,
                altCampaignProfilesLoading: false
            };
        case types.GET_ALT_CAMPAIGN_PROFILES_FAILED:
            return {
                ...state,
                altCampaignProfilesLoading: false,
                error: action.payload
            };
        case types.GET_GROUPED_CAMPAIGN_PROFILES_REQUESTED:
            return {
                ...state,
                groupedCampaignProfilesLoading: true
            };
        case types.GET_GROUPED_CAMPAIGN_PROFILES_SUCCEEDED:
            return {
                ...state,
                groupedCampaignProfiles: action.payload,
                groupedCampaignProfilesLoading: false
            };
        case types.GET_GROUPED_CAMPAIGN_PROFILES_FAILED:
            return {
                ...state,
                groupedCampaignProfilesLoading: false,
                error: action.payload
            };
        case types.GET_CAMPAIGN_QR_CODE_REQUESTED:
            return {
                ...state
            };
        case types.GET_CAMPAIGN_QR_CODE_SUCCEEDED:
            return {
                ...state,
                campaignQRCode: action.payload
            };
        case types.GET_CAMPAIGN_QR_CODE_FAILED:
            return {
                ...state
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_CAMPAIGNS_REQUESTED, startGetCampaignsWorker);
    yield takeLatest(
        types.GET_COMPARE_CAMPAIGNS_REQUESTED,
        startGetCompareCampaignsWorker
    );
    yield takeLatest(
        types.GET_CAMPAIGNS_PAGE_REQUESTED,
        startGetCampaignsPageWorker
    );
    yield takeLatest(types.SAVE_CAMPAIGN_REQUESTED, startSaveCampaignWorker);
    yield takeLatest(
        types.EDIT_ACTIVE_CAMPAIGN_REQUESTED,
        startEditActiveCampaignWorker
    );
    yield takeLatest(types.COPY_CAMPAIGN_REQUESTED, startCopyCampaignWorker);
    yield takeLatest(types.ADD_TO_CAMPAIGN_REQUESTED, startAddToCampaignWorker);
    yield takeLatest(
        types.ACTIVATE_CAMPAIGN_REQUESTED,
        startActivateCampaignWorker
    );
    yield takeLeading(types.GET_CAMPAIGN_REQUESTED, startGetCampaignWorker);
    yield takeLatest(
        types.DELETE_CAMPAIGN_REQUESTED,
        startDeleteCampaignWorker
    );
    yield takeLatest(types.ASK_PERMISSION_REQUESTED, startAskPermissionWorker);
    yield takeLatest(
        types.DEFAULT_EMAIL_TEXT_REQUESTED,
        startDefaultEmailTextWorker
    );
    yield takeLatest(
        types.DEFAULT_EMAIL_REMINDER_TEXT_REQUESTED,
        startDefaultEmailReminderTextWorker
    );
    yield takeLatest(
        types.GET_TABULAR_RESULTS_REQUESTED,
        startGetTabularResultsWorker
    );
    yield takeLatest(
        types.GET_FILTERED_CAMPAIGNS_REQUESTED,
        startGetFilteredCampaignsWorker
    );
    yield takeLatest(
        types.GET_CAMPAIGN_PROFILES_REQUESTED,
        startGetCampaignProfilesWorker
    );
    yield takeLatest(
        types.GET_CUSTOM_CAMPAIGN_PROFILES_REQUESTED,
        startGetCustomCampaignProfilesWorker
    );
    yield takeLatest(
        types.GET_ALT_CAMPAIGN_PROFILES_REQUESTED,
        startGetAltCampaignProfilesWorker
    );
    yield takeLatest(
        types.GET_GROUPED_CAMPAIGN_PROFILES_REQUESTED,
        startGetGroupedCampaignProfilesWorker
    );
    yield takeLatest(
        types.GET_CAMPAIGN_QR_CODE_REQUESTED,
        startGetCampaignQRCodeWorker
    );
}

// Saga callback
function* startGetCampaignsWorker({ callback }) {
    try {
        const response = yield call(getCampaigns);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CAMPAIGNS_SUCCEEDED,
            campaigns: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_CAMPAIGNS_FAILED, payload: e.error });
    }
}

function* startGetCompareCampaignsWorker({ callback }) {
    try {
        const response = yield call(getCompareCampaigns);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPARE_CAMPAIGNS_SUCCEEDED,
            campaigns: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPARE_CAMPAIGNS_FAILED,
            payload: e.error
        });
    }
}

function* startGetCampaignsPageWorker({ page, callback }) {
    try {
        const response = yield call(getCampaignsPage, { page });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CAMPAIGNS_PAGE_SUCCEEDED,
            campaigns: response.data.data,
            totalPages: response.data.meta.last_page,
            activePage: response.data.meta.current_page,
            totalItems: response.data.meta.total
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_CAMPAIGNS_PAGE_FAILED, payload: e.error });
    }
}

function* startSaveCampaignWorker({ campaign, campaignId, callback }) {
    try {
        const response = yield call(saveCampaign, { campaign, campaignId });

        if (!response.ok) {
            throw response.data;
        }

        const emailTextResponse = yield call(saveEmailText, {
            campaign,
            campaignId: response.data.data.id
        });

        if (!emailTextResponse.ok) {
            throw emailTextResponse.data;
        }

        const emailReminderTextResponse = yield call(saveEmailReminderText, {
            campaign,
            campaignId: response.data.data.id
        });

        if (!emailReminderTextResponse.ok) {
            throw emailReminderTextResponse.data;
        }

        yield put({
            type: types.SAVE_CAMPAIGN_SUCCEEDED
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.error({ e });
        yield put({ type: types.SAVE_CAMPAIGN_FAILED, payload: e.errors });
        window.scrollTo(0, 0);
        callFail(callback, e.errors);
    }
}

function* startEditActiveCampaignWorker({ campaign, campaignId, callback }) {
    try {
        const response = yield call(editActiveCampaign, {
            campaign,
            campaignId
        });

        if (!response.ok) {
            throw response.data;
        }

        const emailTextResponse = yield call(saveEmailText, {
            campaign,
            campaignId: response.data.data.id
        });

        if (!emailTextResponse.ok) {
            throw emailTextResponse.data;
        }

        const emailReminderTextResponse = yield call(saveEmailReminderText, {
            campaign,
            campaignId: response.data.data.id
        });

        if (!emailReminderTextResponse.ok) {
            throw emailReminderTextResponse.data;
        }

        yield put({
            type: types.EDIT_ACTIVE_CAMPAIGN_SUCCEEDED
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.EDIT_ACTIVE_CAMPAIGN_FAILED,
            payload: e.error
        });
        window.scrollTo(0, 0);
        callFail(callback, e.errors);
    }
}

function* startCopyCampaignWorker({ campaign, campaignId, callback }) {
    try {
        const response = yield call(copyCampaign, { campaign, campaignId });

        if (!response.ok) {
            throw response.data;
        }

        const emailTextResponse = yield call(saveEmailText, {
            campaign,
            campaignId: response.data.data.id
        });

        if (!emailTextResponse.ok) {
            throw emailTextResponse.data;
        }

        const emailReminderTextResponse = yield call(saveEmailReminderText, {
            campaign,
            campaignId: response.data.data.id
        });

        if (!emailReminderTextResponse.ok) {
            throw emailReminderTextResponse.data;
        }

        yield put({
            type: types.COPY_CAMPAIGN_SUCCEEDED
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.COPY_CAMPAIGN_FAILED, payload: e.error });
        window.scrollTo(0, 0);
        callFail(callback, e.errors);
    }
}

function* startAddToCampaignWorker({ campaign, campaignId, callback }) {
    try {
        const response = yield call(addToCampaign, { campaign, campaignId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ADD_TO_CAMPAIGN_SUCCEEDED
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.ADD_TO_CAMPAIGN_FAILED, payload: e.error });
        window.scrollTo(0, 0);
        callFail(callback, e.errors);
    }
}

function* startActivateCampaignWorker({ campaignId, callback }) {
    try {
        const response = yield call(activateCampaign, { campaignId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ACTIVATE_CAMPAIGN_SUCCEEDED
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.ACTIVATE_CAMPAIGN_FAILED, payload: e.error });
    }
}

function* startDefaultEmailTextWorker({ campaignId, callback }) {
    try {
        const emailTextResponse = yield call(getEmailText, { campaignId });

        if (!emailTextResponse.ok) {
            throw emailTextResponse.data;
        }

        typeof callback == "function" && callback(emailTextResponse.data);
    } catch (e) {
        console.log(e);
    }
}

function* startDefaultEmailReminderTextWorker({ campaignId, callback }) {
    try {
        const emailTextResponse = yield call(getEmailReminderText, {
            campaignId
        });

        if (!emailTextResponse.ok) {
            throw emailTextResponse.data;
        }

        typeof callback == "function" && callback(emailTextResponse.data);
    } catch (e) {
        console.log(e);
    }
}

function* startGetCampaignWorker({ campaignId, callback }) {
    try {
        const response = yield call(getCampaign, { campaignId });

        if (!response.ok) {
            throw response.data;
        }

        const campaign = response.data.data;

        yield put({
            type: types.GET_CAMPAIGN_SUCCEEDED,
            campaign
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_CAMPAIGN_FAILED, payload: e.errors });
    }
}

function* startDeleteCampaignWorker({ id, callback }) {
    try {
        const response = yield call(deleteCampaign, { id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_CAMPAIGN_SUCCEEDED,
            id: id
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.DELETE_CAMPAIGN_FAILED, payload: e.error });
    }
}

function* startAskPermissionWorker({ subjectId, callback }) {
    try {
        const response = yield call(askPermission, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ASK_PERMISSION_SUCCEEDED,
            subjectId
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.ASK_PERMISSION_FAILED, error: e.error });
    }
}

function* startGetTabularResultsWorker({ campaignId, testName, callback }) {
    try {
        const response = yield call(getTabularResults, {
            campaignId,
            testName
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_TABULAR_RESULTS_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_TABULAR_RESULTS_FAILED, error: e.error });
    }
}

function* startGetFilteredCampaignsWorker({
    page,
    name,
    perPage,
    order,
    createdAt,
    callback
}) {
    try {
        const response = yield call(getFilteredCampaigns, {
            page,
            name,
            perPage,
            order,
            createdAt
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_FILTERED_CAMPAIGNS_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_FILTERED_CAMPAIGNS_FAILED,
            error: e.error
        });
    }
}

function* startGetCampaignProfilesWorker({ callback }) {
    try {
        const response = yield call(getCampaignProfiles);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CAMPAIGN_PROFILES_SUCCEEDED,
            payload: response.data.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.GET_CAMPAIGN_PROFILES_FAILED, error: e.error });
        callFail(callback, e.errors);
    }
}

function* startGetCustomCampaignProfilesWorker({ callback }) {
    try {
        const response = yield call(getCustomCampaignProfiles);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CUSTOM_CAMPAIGN_PROFILES_SUCCEEDED,
            payload: response.data.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_CUSTOM_CAMPAIGN_PROFILES_FAILED,
            error: e.error
        });
        callFail(callback, e.errors);
    }
}

function* startGetAltCampaignProfilesWorker({ callback }) {
    try {
        const response = yield call(getAltCampaignProfiles);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ALT_CAMPAIGN_PROFILES_SUCCEEDED,
            payload: response.data.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_ALT_CAMPAIGN_PROFILES_FAILED,
            error: e.error
        });
        callFail(callback, e.errors);
    }
}

function* startGetGroupedCampaignProfilesWorker({ callback }) {
    try {
        const response = yield call(getGroupedCampaignProfiles);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_GROUPED_CAMPAIGN_PROFILES_SUCCEEDED,
            payload: response.data.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_GROUPED_CAMPAIGN_PROFILES_FAILED,
            error: e.error
        });
        callFail(callback, e.errors);
    }
}

function* startGetCampaignQRCodeWorker({ campaignId, callback }) {
    try {
        const response = yield call(getCampaignQRCode, { campaignId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CAMPAIGN_QR_CODE_SUCCEEDED,
            payload: response.data
        });
        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_CAMPAIGN_QR_CODE_FAILED,
            error: e.error
        });
        callFail(callback, e.errors);
    }
}

// API call
function getCampaigns() {
    return api.get("/api/admin/campaigns/completed");
}

function getCompareCampaigns() {
    return api.get("/api/admin/compare/campaigns");
}

function getCampaignsPage({ page }) {
    return api.get(`/api/admin/campaigns?page=${page}`);
}

function saveEmailText({ campaign, campaignId }) {
    return api.put(`/api/admin/campaigns/${campaignId}/email-text`, {
        email_text: campaign.email_text ? campaign.email_text : ""
    });
}

function saveEmailReminderText({ campaign, campaignId }) {
    return api.put(`/api/admin/campaigns/${campaignId}/reminder-text`, {
        reminder_text: campaign.email_reminder_text
            ? campaign.email_reminder_text
            : ""
    });
}

const titleCase = string => {
    return string
        .split(" ")
        .map(
            word =>
                word.substr(0, 1).toUpperCase() + word.substr(1, word.length)
        )
        .join(" ");
};

function saveCampaign({ campaign, campaignId }) {
    const form = new FormData();

    const manualSubjects = campaign.subjects
        ? campaign.subjects.filter(
              subject =>
                  subject.first_name || subject.last_name || subject.email
          )
        : false;
    const hasManual = manualSubjects && manualSubjects.length;

    form.append(
        "campaign_name",
        campaign.campaign_name ? campaign.campaign_name : ""
    );

    form.append("campaign_type", campaign.campaign_type);
    if (campaign.culture_test_id) {
        form.append("culture_test_id", campaign.culture_test_id);
    }

    if (campaign.email_update_on_test_complete) {
        form.append("email_update_on_test_complete", 1);
    } else {
        form.append("email_update_on_test_complete", 0);
    }

    if (campaign.email_update_on_all_complete) {
        form.append("email_update_on_all_complete", 1);
    } else {
        form.append("email_update_on_all_complete", 0);
    }

    const skill_tests = [];
    const test_types = [];
    let burnout = false;
    campaign.test_types.map(tt => {
        if (String(tt).indexOf("mcqt_") != -1) {
            skill_tests.push(tt.replace("mcqt_", ""));
        } else {
            if (tt == "burnout") {
                burnout = true;
            } else {
                test_types.push(tt);
            }
        }
    });

    if (test_types && test_types.length) {
        test_types.map(tt => {
            form.append("test_types[]", tt);
        });
    }

    if (skill_tests && skill_tests.length) {
        skill_tests.map(st => {
            form.append("skill_tests[]", st);
        });
    }

    if (burnout) {
        form.append("burnout_test", 1);
    }

    if (campaign.profile) {
        let profile = String(campaign.profile).split(":");

        if (profile[0] == "custom") {
            form.append("custom_campaign_profile_id", Number(profile[1]));
        } else if (profile[0] == "alt") {
            form.append("alt_campaign_profile_id", Number(profile[1]));
        } else {
            form.append("campaign_profile_id", Number(profile[0]));
        }
    }

    api.setHeader("Content-Type", "multipart/form-data");

    if (campaignId) {
        form.append("campaign_id", campaignId);
    }

    if (campaign.deadline) {
        form.append("deadline", campaign.deadline);
    }

    if (campaign.number_of_reminders) {
        form.append(
            "number_of_reminders",
            Number(campaign.number_of_reminders)
        );
    }

    if (campaign.interview_name) {
        form.append("interview_name", campaign.interview_name);
    }

    if (campaign.interview_questions && campaign.interview_questions.length) {
        campaign.interview_questions.map(q => {
            form.append("interview_questions[]", q.question);
        });
    }

    if (campaign.sender) {
        const sender = campaign.sender.split("|");
        form.append("invite_sender_name", sender[0]);
        form.append("invite_sender_email", sender[1]);
    }

    if (hasManual) {
        const newManualSubjects = manualSubjects.map(manualSubject => {
            return {
                first_name: titleCase(manualSubject.first_name),
                last_name: titleCase(manualSubject.last_name),
                email: manualSubject.email
            };
        });
        form.append("subjects", JSON.stringify(newManualSubjects));
    } else {
        if (campaign.email_list) {
            form.append(
                "email_list",
                campaign.email_list ? campaign.email_list : ""
            );
            form.append(
                "delimiter",
                campaign.delimiter ? campaign.delimiter : ""
            );
        }
    }

    return api.post("/api/admin/campaigns", form);
}

function editActiveCampaign({ campaign, campaignId }) {
    campaign.campaign_id = campaignId;
    campaign.invite_sender_name = null;
    campaign.invite_sender_email = null;

    if (campaign.sender) {
        const sender = campaign.sender.split("|");
        campaign.invite_sender_name = sender[0];
        campaign.invite_sender_email = sender[1];
    }

    if (campaign.profile) {
        let profile = String(campaign.profile).split(":");

        if (profile[0] == "custom") {
            campaign.custom_campaign_profile_id = Number(profile[1]);
        } else if (profile[0] == "alt") {
            campaign.alt_campaign_profile_id = Number(profile[1]);
        } else {
            campaign.campaign_profile_id = Number(profile[0]);
        }
    }

    return api.put("/api/admin/campaigns/active", campaign);
}

function copyCampaign({ campaign, campaignId }) {
    const form = new FormData();

    const manualSubjects = campaign.subjects
        ? campaign.subjects.filter(
              subject =>
                  subject.first_name || subject.last_name || subject.email
          )
        : false;
    const hasManual = manualSubjects && manualSubjects.length;

    form.append(
        "campaign_name",
        campaign.campaign_name ? campaign.campaign_name : ""
    );

    form.append("campaign_type", campaign.campaign_type);
    if (campaign.culture_test_id) {
        form.append("culture_test_id", campaign.culture_test_id);
    }

    if (campaign.email_update_on_test_complete) {
        form.append("email_update_on_test_complete", 1);
    } else {
        form.append("email_update_on_test_complete", 0);
    }

    if (campaign.email_update_on_all_complete) {
        form.append("email_update_on_all_complete", 1);
    } else {
        form.append("email_update_on_all_complete", 0);
    }

    const skill_tests = [];
    const test_types = [];
    let burnout = false;
    campaign.test_types.map(tt => {
        if (String(tt).indexOf("mcqt_") != -1) {
            skill_tests.push(tt.replace("mcqt_", ""));
        } else {
            if (tt == "burnout") {
                burnout = true;
            } else {
                test_types.push(tt);
            }
        }
    });

    if (test_types && test_types.length) {
        test_types.map(tt => {
            form.append("test_types[]", tt);
        });
    }

    if (skill_tests && skill_tests.length) {
        skill_tests.map(st => {
            form.append("skill_tests[]", st);
        });
    }

    if (burnout) {
        form.append("burnout_test", 1);
    }

    api.setHeader("Content-Type", "multipart/form-data");

    if (campaignId) {
        form.append("copy_of", campaignId);
    }

    if (campaign.profile) {
        let profile = String(campaign.profile).split(":");

        if (profile[0] == "custom") {
            form.append("custom_campaign_profile_id", Number(profile[1]));
        } else if (profile[0] == "alt") {
            form.append("alt_campaign_profile_id", Number(profile[1]));
        } else {
            form.append("campaign_profile_id", Number(profile[0]));
        }
    }

    if (campaign.deadline) {
        form.append("deadline", campaign.deadline);
    }

    if (campaign.sender) {
        const sender = campaign.sender.split("|");
        form.append("invite_sender_name", sender[0]);
        form.append("invite_sender_email", sender[1]);
    }

    if (campaign.number_of_reminders) {
        form.append(
            "number_of_reminders",
            Number(campaign.number_of_reminders)
        );
    }

    if (campaign.interview_name) {
        form.append("interview_name", campaign.interview_name);
    }

    if (campaign.interview_questions && campaign.interview_questions.length) {
        campaign.interview_questions.map(q => {
            form.append("interview_questions[]", q.question);
        });
    }

    if (hasManual) {
        const newManualSubjects = manualSubjects.map(manualSubject => {
            return {
                first_name: titleCase(manualSubject.first_name),
                last_name: titleCase(manualSubject.last_name),
                email: manualSubject.email
            };
        });
        form.append("subjects", JSON.stringify(newManualSubjects));
    } else if (campaign.delimiter) {
        form.append(
            "email_list",
            campaign.email_list ? campaign.email_list : ""
        );
        form.append("delimiter", campaign.delimiter ? campaign.delimiter : "");
    }

    return api.post("/api/admin/campaigns", form);
}

function addToCampaign({ campaign, campaignId }) {
    const form = new FormData();

    const manualSubjects = campaign.subjects
        ? campaign.subjects.filter(
              subject =>
                  subject.first_name || subject.last_name || subject.email
          )
        : false;
    const hasManual = manualSubjects && manualSubjects.length;

    if (campaignId) {
        form.append("campaign_id", campaignId);
    }

    if (hasManual) {
        const newManualSubjects = manualSubjects.map(manualSubject => {
            return {
                first_name: titleCase(manualSubject.first_name),
                last_name: titleCase(manualSubject.last_name),
                email: manualSubject.email
            };
        });
        form.append("subjects", JSON.stringify(newManualSubjects));
    }

    return api.post("/api/admin/campaigns/active/add-subjects", form);
}

function activateCampaign({ campaignId }) {
    return api.post("/api/admin/campaigns/activate/" + campaignId);
}

function getCampaign({ campaignId }) {
    return api.get("/api/admin/campaigns/" + campaignId);
}

function getEmailText({ campaignId }) {
    return api.get("/api/admin/campaigns/" + campaignId + "/email-text");
}

function getEmailReminderText({ campaignId }) {
    return api.get("/api/admin/campaigns/" + campaignId + "/reminder-text");
}

function deleteCampaign({ id }) {
    return api.delete("/api/admin/campaigns/" + id);
}

function askPermission({ subjectId }) {
    return api.get("/api/admin/permission/subject/" + subjectId);
}

function getTabularResults({ campaignId, testName }) {
    let url = "";
    if (testName === "personality-test") {
        url = `/api/admin/campaigns/${campaignId}/subjects/personality-test/results`;
    }
    if (testName === "culture-test") {
        url = `/api/admin/campaigns/${campaignId}/subjects/culture-test/results`;
    }
    if (testName === "iq-test") {
        url = `/api/admin/campaigns/${campaignId}/subjects/iq-test/results`;
    }
    return api.get(url);
}

function getFilteredCampaigns({
    page,
    name,
    perPage,
    order = "",
    createdAt = ""
}) {
    let url = `/api/admin/campaigns/filter`;
    let first = true;

    if (order) {
        url = url + (first ? "?" : "&") + `order=${order}`;
        first = false;
    }

    if (perPage) {
        url = url + (first ? "?" : "&") + `perPage=${perPage}`;
        first = false;
    }

    if (page) {
        url = url + (first ? "?" : "&") + `page=${page}`;
        first = false;
    }

    if (name) {
        url = url + (first ? "?" : "&") + `name=${name}`;
        first = false;
    }

    if (createdAt) {
        url = url + (first ? "?" : "&") + `createdAt=${createdAt}`;
    }

    return api.get(
        // `/api/admin/test-type/${testType}/subjects/status/${status}/filter?page=${page}`
        url
    );
}

function getCampaignProfiles() {
    return api.get(`/api/campaigns/profiles`);
}

function getCustomCampaignProfiles() {
    return api.get(`/api/admin/campaigns/custom-profiles`);
}

function getAltCampaignProfiles() {
    return api.get(`/api/admin/campaigns/alternative-profiles`);
}

function getGroupedCampaignProfiles() {
    return api.get(`/api/admin/campaigns/grouped-profiles`);
}

function getCampaignQRCode({ campaignId }) {
    return api.get(`/api/admin/campaigns/${campaignId}/qr-code`, {
        responseType: "blob"
    });
}
