import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_JOB_PROFILE_GROUPS_REQUESTED: "GET_JOB_PROFILE_GROUPS_REQUESTED",
    GET_JOB_PROFILE_GROUPS_SUCCEEDED: "GET_JOB_PROFILE_GROUPS_SUCCEEDED",
    GET_JOB_PROFILE_GROUPS_FAILED: "GET_JOB_PROFILE_GROUPS_FAILED",
    CREATE_JOB_PROFILE_REQUESTED: "CREATE_JOB_PROFILE_REQUESTED",
    CREATE_JOB_PROFILE_SUCCEEDED: "CREATE_JOB_PROFILE_SUCCEEDED",
    CREATE_JOB_PROFILE_FAILED: "CREATE_JOB_PROFILE_FAILED",
    GET_JOB_PROFILE_REQUESTED: "GET_JOB_PROFILE_REQUESTED",
    GET_JOB_PROFILE_SUCCEEDED: "GET_JOB_PROFILE_SUCCEEDED",
    GET_JOB_PROFILE_FAILED: "GET_JOB_PROFILE_FAILED",
    EDIT_JOB_PROFILE_REQUESTED: "EDIT_JOB_PROFILE_REQUESTED",
    EDIT_JOB_PROFILE_SUCCEEDED: "EDIT_JOB_PROFILE_SUCCEEDED",
    EDIT_JOB_PROFILE_FAILED: "EDIT_JOB_PROFILE_FAILED"
};

// Action Creators
export const actions = {
    getJobProfileGroups: callback => {
        return {
            type: types.GET_JOB_PROFILE_GROUPS_REQUESTED,
            callback
        };
    },
    create: (data, callback) => {
        return {
            type: types.CREATE_JOB_PROFILE_REQUESTED,
            data,
            callback
        };
    },
    get: (id, callback) => {
        return {
            type: types.GET_JOB_PROFILE_REQUESTED,
            id,
            callback
        };
    },
    edit: (id, data, callback) => {
        return {
            type: types.EDIT_JOB_PROFILE_REQUESTED,
            id,
            data,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    profile: {},
    profileLoading: false,
    groups: [],
    groupsLoading: false,
    error: "",
    creating: false,
    created: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_JOB_PROFILE_GROUPS_REQUESTED:
            return {
                ...state,
                groups: [],
                error: "",
                groupsLoading: true
            };
        case types.GET_JOB_PROFILE_GROUPS_SUCCEEDED:
            return {
                ...state,
                groups: action.payload,
                error: "",
                groupsLoading: false
            };
        case types.GET_JOB_PROFILE_GROUPS_FAILED:
            return {
                ...state,
                groups: [],
                error: action.payload,
                groupsLoading: false
            };
        case types.CREATE_JOB_PROFILE_REQUESTED:
            return {
                ...state,
                creating: true
            };
        case types.CREATE_JOB_PROFILE_SUCCEEDED:
            return {
                ...state,
                creating: false,
                created: true
            };
        case types.CREATE_JOB_PROFILE_FAILED:
            return {
                ...state,
                creating: false,
                created: false,
                error: action.payload
            };
        case types.GET_JOB_PROFILE_REQUESTED:
            return {
                ...state,
                profileLoading: true
            };
        case types.GET_JOB_PROFILE_SUCCEEDED:
            return {
                ...state,
                profileLoading: false,
                profile: action.payload
            };
        case types.GET_JOB_PROFILE_FAILED:
            return {
                ...state,
                profileLoading: false,
                profile: {},
                error: action.payload
            };
        case types.EDIT_JOB_PROFILE_REQUESTED:
            return {
                ...state,
                creating: true
            };
        case types.EDIT_JOB_PROFILE_SUCCEEDED:
            return {
                ...state,
                creating: false
            };
        case types.EDIT_JOB_PROFILE_FAILED:
            return {
                ...state,
                creating: false,
                error: action.payload
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.GET_JOB_PROFILE_GROUPS_REQUESTED,
        getJobProfileGroupsWorker
    );
    yield takeLatest(
        types.CREATE_JOB_PROFILE_REQUESTED,
        createJobProfileWorker
    );
    yield takeLatest(types.GET_JOB_PROFILE_REQUESTED, getJobProfileWorker);
    yield takeLatest(types.EDIT_JOB_PROFILE_REQUESTED, editJobProfileWorker);
}

// Saga callback
function* getJobProfileGroupsWorker({ callback }) {
    try {
        const response = yield call(getJobProfileGroups);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_JOB_PROFILE_GROUPS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_JOB_PROFILE_GROUPS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* createJobProfileWorker({ data, callback }) {
    try {
        const response = yield call(createJobProfile, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.CREATE_JOB_PROFILE_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.CREATE_JOB_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getJobProfileWorker({ id, callback }) {
    try {
        const response = yield call(getJobProfile, { id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_JOB_PROFILE_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_JOB_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* editJobProfileWorker({ id, data, callback }) {
    try {
        const response = yield call(editJobProfile, { id, data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EDIT_JOB_PROFILE_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.EDIT_JOB_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function getJobProfileGroups() {
    return api.get(`/api/super-admin/job-profiles/groups`);
}

function createJobProfile({ data }) {
    return api.post(`/api/super-admin/job-profiles/general`, data);
}

function getJobProfile({ id }) {
    return api.get(`/api/super-admin/job-profiles/general/${id}`);
}

function editJobProfile({ id, data }) {
    return api.put(`/api/super-admin/job-profiles/general/${id}`, data);
}
