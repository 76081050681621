import { takeLatest, call, put } from "redux-saga/effects";
import { stopSubmit } from "redux-form";
import extractErrorForForm from "Util/extractErrorForForm";
import { callSuccess, callFail } from "Util/callback";

import api from "Util/api";

// Actions
const types = {
    REGISTER_USER_REQUESTED: "REGISTER_USER_REQUESTED",
    REGISTER_USER_SUCCEEDED: "REGISTER_USER_SUCCEEDED",
    REGISTER_USER_FAILED: "REGISTER_USER_FAILED",
    LOGIN_USER_REQUESTED: "LOGIN_USER_REQUESTED",
    LOGIN_USER_SUCCEEDED: "LOGIN_USER_SUCCEEDED",
    LOGIN_USER_FAILED: "LOGIN_USER_FAILED",
    LOGOUT_USER_REQUESTED: "LOGOUT_USER_REQUESTED",
    LOGOUT_USER_SUCCEEDED: "LOGOUT_USER_SUCCEEDED",
    LOGOUT_USER_FAILED: "LOGOUT_USER_FAILED",
    SUBMIT_PASSWORD_REQUESTED: "SUBMIT_PASSWORD_REQUESTED",
    SUBMIT_PASSWORD_SUCCEEDED: "SUBMIT_PASSWORD_SUCCEEDED",
    SUBMIT_PASSWORD_FAILED: "SUBMIT_PASSWORD_FAILED",
    CHECK_MAGIC_LINK_REQUESTED: "CHECK_MAGIC_LINK_REQUESTED",
    CHECK_MAGIC_LINK_SUCCEEDED: "CHECK_MAGIC_LINK_SUCCEEDED",
    CHECK_MAGIC_LINK_FAILED: "CHECK_MAGIC_LINK_FAILED",
    GIVE_PERMISSION_REQUESTED: "GIVE_PERMISSION_REQUESTED",
    GIVE_PERMISSION_SUCCEEDED: "GIVE_PERMISSION_SUCCEEDED",
    GIVE_PERMISSION_FAILED: "GIVE_PERMISSION_FAILED",
    GIVE_PERMISSION_LOGGED_IN_REQUESTED: "GIVE_PERMISSION_LOGGED_IN_REQUESTED",
    GIVE_PERMISSION_LOGGED_IN_SUCCEEDED: "GIVE_PERMISSION_LOGGED_IN_SUCCEEDED",
    GIVE_PERMISSION_LOGGED_IN_FAILED: "GIVE_PERMISSION_LOGGED_IN_FAILED",
    UNSUBSCRIBE_REQUESTED: "UNSUBSCRIBE_REQUESTED",
    UNSUBSCRIBE_SUCCEEDED: "UNSUBSCRIBE_SUCCEEDED",
    UNSUBSCRIBE_FAILED: "UNSUBSCRIBE_FAILED",
    FORGOT_PASSWORD_REQUESTED: "FORGOT_PASSWORD_REQUESTED",
    FORGOT_PASSWORD_SUCCEEDED: "FORGOT_PASSWORD_SUCCEEDED",
    FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
    RESET_PASSWORD_REQUESTED: "RESET_PASSWORD_REQUESTED",
    RESET_PASSWORD_SUCCEEDED: "RESET_PASSWORD_SUCCEEDED",
    RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
    PASSWORDLESS_LOGIN_REQUESTED: "PASSWORDLESS_LOGIN_REQUESTED",
    PASSWORDLESS_LOGIN_SUCCEEDED: "PASSWORDLESS_LOGIN_SUCCEEDED",
    PASSWORDLESS_LOGIN_FAILED: "PASSWORDLESS_LOGIN_FAILED",
    UPDATE_USER_SESSION_REQUESTED: "UPDATE_USER_SESSION_REQUESTED",
    UPDATE_USER_SESSION_SUCCEEDED: "UPDATE_USER_SESSION_SUCCEEDED",
    UPDATE_USER_SESSION_FAILED: "UPDATE_USER_SESSION_FAILED",
    INVITE_LINK_LOGIN_REQUESTED: "INVITE_LINK_LOGIN_REQUESTED",
    INVITE_LINK_LOGIN_SUCCEEDED: "INVITE_LINK_LOGIN_SUCCEEDED",
    INVITE_LINK_LOGIN_FAILED: "INVITE_LINK_LOGIN_FAILED",
    INVITE_LINK_CAMPAIGN_REQUESTED: "INVITE_LINK_CAMPAIGN_REQUESTED",
    INVITE_LINK_CAMPAIGN_SUCCEEDED: "INVITE_LINK_CAMPAIGN_SUCCEEDED",
    INVITE_LINK_CAMPAIGN_FAILED: "INVITE_LINK_CAMPAIGN_FAILED",
    CONFIRM_USER_REQUESTED: "CONFIRM_USER_REQUESTED",
    CONFIRM_USER_SUCCEEDED: "CONFIRM_USER_SUCCEEDED",
    CONFIRM_USER_FAILED: "CONFIRM_USER_FAILED"
};

// Action Creators
export const actions = {
    updateSession: (key, value, callback) => {
        return {
            type: types.UPDATE_USER_SESSION_REQUESTED,
            key,
            value,
            callback
        };
    },
    loginUser: (userData, callback) => {
        return {
            type: types.LOGIN_USER_REQUESTED,
            userData,
            callback
        };
    },
    registerUser: (userData, callback) => {
        return {
            type: types.REGISTER_USER_REQUESTED,
            userData,
            callback
        };
    },
    confirmUser: (token, callback) => {
        return {
            type: types.CONFIRM_USER_REQUESTED,
            token,
            callback
        };
    },
    logoutUser: callback => {
        return {
            type: types.LOGOUT_USER_REQUESTED,
            callback
        };
    },
    clearAuthDataUser: callback => {
        return {
            type: types.LOGOUT_USER_SUCCEEDED,
            callback
        };
    },
    submitPassword: (password, hash, organizationId, callback) => {
        return {
            type: types.SUBMIT_PASSWORD_REQUESTED,
            password,
            hash,
            organizationId,
            callback
        };
    },
    checkMagicLink: (magicLink, callback) => {
        return {
            type: types.CHECK_MAGIC_LINK_REQUESTED,
            magicLink,
            callback
        };
    },
    givePermission: (organizationId, hash, callback) => {
        return {
            type: types.GIVE_PERMISSION_REQUESTED,
            organizationId,
            hash,
            callback
        };
    },
    givePermissionLoggedIn: (organizationId, callback) => {
        return {
            type: types.GIVE_PERMISSION_LOGGED_IN_REQUESTED,
            organizationId,
            callback
        };
    },
    unsubscribe: (hash, callback) => {
        return {
            type: types.UNSUBSCRIBE_REQUESTED,
            hash,
            callback
        };
    },
    forgotPassword: (email, callback) => {
        return {
            type: types.FORGOT_PASSWORD_REQUESTED,
            email,
            callback
        };
    },
    resetPassword: (values, token, callback) => {
        return {
            type: types.RESET_PASSWORD_REQUESTED,
            values,
            token,
            callback
        };
    },
    passwordlessLogin: (token, organizationId, callback) => {
        return {
            type: types.PASSWORDLESS_LOGIN_REQUESTED,
            token,
            organizationId,
            callback
        };
    },
    inviteLinkCampaign: (token, callback) => {
        return {
            type: types.INVITE_LINK_CAMPAIGN_REQUESTED,
            token,
            callback
        };
    },
    inviteLinkLogin: (token, firstName, lastName, email, callback) => {
        return {
            type: types.INVITE_LINK_LOGIN_REQUESTED,
            token,
            firstName,
            lastName,
            email,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    data: {},
    roles: [],
    error: "",
    message: "",
    loading: false,
    auth: false,
    logoutValue: "",
    campaign: {},
    campaignLoading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.FORGOT_PASSWORD_REQUESTED:
        case types.PASSWORDLESS_LOGIN_REQUESTED:
        case types.INVITE_LINK_LOGIN_REQUESTED:
        case types.RESET_PASSWORD_REQUESTED:
        case types.GIVE_PERMISSION_REQUESTED:
        case types.GIVE_PERMISSION_LOGGED_IN_REQUESTED:
        case types.UNSUBSCRIBE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.INVITE_LINK_CAMPAIGN_REQUESTED:
            return {
                ...state,
                campaignLoading: true,
                campaign: {}
            };
        case types.INVITE_LINK_CAMPAIGN_SUCCEEDED:
            return {
                ...state,
                campaignLoading: false,
                campaign: action.payload.data
            };
        case types.INVITE_LINK_CAMPAIGN_FAILED:
            return {
                ...state,
                campaignLoading: false,
                campaign: {}
            };
        case types.GIVE_PERMISSION_SUCCEEDED:
        case types.GIVE_PERMISSION_LOGGED_IN_SUCCEEDED:
        case types.UNSUBSCRIBE_SUCCEEDED:
            return {
                ...state,
                message: action.message,
                loading: false
            };
        case types.GIVE_PERMISSION_FAILED:
        case types.GIVE_PERMISSION_LOGGED_IN_FAILED:
        case types.UNSUBSCRIBE_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case types.LOGIN_USER_REQUESTED:
        case types.SUBMIT_PASSWORD_REQUESTED: {
            return {
                ...state,
                loading: true
            };
        }
        case types.REGISTER_USER_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.REGISTER_USER_SUCCEEDED:
            return {
                ...state,
                data: action.data.tokens,
                error: "",
                loading: false
            };
        case types.REGISTER_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.LOGIN_USER_SUCCEEDED:
            return {
                ...state,
                data: action.data.tokens,
                roles: action.data.roles,
                auth: true,
                error: "",
                loading: false
            };
        case types.LOGIN_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.LOGOUT_USER_SUCCEEDED:
            return {
                ...state,
                data: {},
                auth: false,
                error: "",
                loading: false,
                logoutValue: action.logoutValue
            };
        case types.LOGOUT_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.LOGOUT_USER_REQUESTED:
            return {
                ...state,
                logoutValue: "logout"
            };
        case types.FORGOT_PASSWORD_SUCCEEDED:
        case types.FORGOT_PASSWORD_FAILED:
        case types.RESET_PASSWORD_SUCCEEDED:
        case types.RESET_PASSWORD_FAILED:
            return {
                ...state,
                loading: false
            };
        case types.UPDATE_USER_SESSION_REQUESTED:
            return {
                ...state,
                data: {
                    ...state.data,
                    session: {
                        ...state.data.session,
                        [action.key]: action.value
                    }
                }
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.REGISTER_USER_REQUESTED, startUserWorker);
    yield takeLatest(types.CONFIRM_USER_REQUESTED, startConfirmUserWorker);
    yield takeLatest(types.LOGIN_USER_REQUESTED, startLoginWorker);
    yield takeLatest(types.LOGOUT_USER_REQUESTED, startLogoutWorker);
    yield takeLatest(
        types.CHECK_MAGIC_LINK_REQUESTED,
        startCheckMagicLinkWorker
    );
    yield takeLatest(
        types.SUBMIT_PASSWORD_REQUESTED,
        startSubmitPasswordWorker
    );
    yield takeLatest(
        types.GIVE_PERMISSION_REQUESTED,
        startGivePermissionWorker
    );
    yield takeLatest(
        types.GIVE_PERMISSION_LOGGED_IN_REQUESTED,
        startGivePermissionLoggedInWorker
    );
    yield takeLatest(types.UNSUBSCRIBE_REQUESTED, startUnsubscribeWorker);
    yield takeLatest(
        types.FORGOT_PASSWORD_REQUESTED,
        startForgotPasswordWorker
    );
    yield takeLatest(types.RESET_PASSWORD_REQUESTED, startResetPasswordWorker);
    yield takeLatest(
        types.PASSWORDLESS_LOGIN_REQUESTED,
        startPasswordlessLoginWorker
    );
    yield takeLatest(types.INVITE_LINK_LOGIN_REQUESTED, inviteLinkLoginWorker);
    yield takeLatest(
        types.INVITE_LINK_CAMPAIGN_REQUESTED,
        inviteLinkCampaignWorker
    );
}

// Saga callback
function* startUserWorker({ userData, callback }) {
    try {
        const response = yield call(registerUser, { userData });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REGISTER_USER_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.REGISTER_USER_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function* startConfirmUserWorker({ token, callback }) {
    try {
        const response = yield call(confirmUser, { token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.CONFIRM_USER_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.CONFIRM_USER_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function* startCheckMagicLinkWorker({ magicLink, callback }) {
    try {
        const response = yield call(checkMagicLink, { magicLink });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.CHECK_MAGIC_LINK_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(response.data.data);
    } catch (e) {
        yield put({ type: types.CHECK_MAGIC_LINK_FAILED, payload: e.error });
    }
}

function* startLoginWorker({ userData, callback }) {
    try {
        const response = yield call(loginUser, { userData });

        if (!response.ok) {
            throw response.data;
        }

        // const userCredentials = response.data.tokens;
        // api.setHeader(
        //     "Authorization",
        //     `${userCredentials.token_type} ${userCredentials.access_token}`
        // );

        yield put({
            type: types.LOGIN_USER_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.LOGIN_USER_FAILED, payload: e.error });
        const formError = extractErrorForForm(e.errors);
        yield put(stopSubmit("login", formError));
    }
}

function* startLogoutWorker({ callback }) {
    try {
        const response = yield call(logoutUser);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LOGOUT_USER_SUCCEEDED,
            logoutValue: "logout"
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.LOGIN_USER_FAILED, payload: e.error });
    }
}

function* startSubmitPasswordWorker({
    password,
    hash,
    organizationId,
    callback
}) {
    try {
        const response = yield call(submitPassword, {
            password,
            hash,
            organizationId
        });

        if (!response.ok) {
            throw response.data;
        }

        // const userCredentials = response.data.tokens;
        // api.setHeader(
        //     "Authorization",
        //     `${userCredentials.token_type} ${userCredentials.access_token}`
        // );

        yield put({
            type: types.LOGIN_USER_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.LOGIN_USER_FAILED, payload: e.error });
        const formError = extractErrorForForm(e.errors);
        yield put(stopSubmit("choosePassword", formError));
    }
}

function* startGivePermissionWorker({ organizationId, hash, callback }) {
    try {
        const response = yield call(givePermission, { organizationId, hash });

        if (!response.ok) {
            throw response.data;
        }

        // const userCredentials = response.data.tokens;
        // api.setHeader(
        //     "Authorization",
        //     `${userCredentials.token_type} ${userCredentials.access_token}`
        // );

        yield put({
            type: types.LOGIN_USER_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GIVE_PERMISSION_FAILED, error: e.error });
    }
}

function* startGivePermissionLoggedInWorker({ organizationId, callback }) {
    try {
        const response = yield call(givePermissionLoggedIn, { organizationId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GIVE_PERMISSION_LOGGED_IN_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GIVE_PERMISSION_LOGGED_IN_FAILED,
            error: e.error
        });
    }
}

function* startUnsubscribeWorker({ hash, callback }) {
    try {
        const response = yield call(unsubscribe, { hash });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UNSUBSCRIBE_SUCCEEDED,
            message: response.data.message
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e.error);
        yield put({ type: types.UNSUBSCRIBE_FAILED, error: e.error });
    }
}

function* startForgotPasswordWorker({ email, callback }) {
    try {
        const response = yield call(forgotPassword, { email });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.FORGOT_PASSWORD_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(email);
    } catch (e) {
        yield put({ type: types.FORGOT_PASSWORD_FAILED, payload: e.errors });
        const formError = extractErrorForForm(e.errors);
        yield put(stopSubmit("forgot-password", formError));
    }
}

function* startResetPasswordWorker({ values, token, callback }) {
    try {
        const response = yield call(resetPassword, { values, token });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LOGIN_USER_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback();
    } catch (e) {
        yield put({ type: types.RESET_PASSWORD_FAILED, payload: e.errors });
        const formError = extractErrorForForm(e.errors);
        yield put(stopSubmit("reset-password", formError));
    }
}

function* startPasswordlessLoginWorker({ token, organizationId, callback }) {
    try {
        const response = yield call(passwordlessLogin, {
            token,
            organizationId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LOGIN_USER_SUCCEEDED,
            data: {
                tokens: {
                    access_token: response.data.accessToken,
                    token_type: "Bearer"
                },
                roles: response.data.rolse
            }
        });

        typeof callback == "function" &&
            callback({
                tokens: {
                    access_token: response.data.accessToken,
                    token_type: "Bearer"
                },
                roles: response.data.rolse
            });
    } catch (e) {
        yield put({ type: types.LOGIN_USER_FAILED, payload: e.errors });
    }
}

function* inviteLinkLoginWorker({
    firstName,
    lastName,
    email,
    token,
    callback
}) {
    try {
        const response = yield call(inviteLinkLogin, {
            token,
            firstName,
            lastName,
            email
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.INVITE_LINK_LOGIN_SUCCEEDED,
            data: response.data.message
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.INVITE_LINK_LOGIN_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function* inviteLinkCampaignWorker({ token, callback }) {
    try {
        const response = yield call(inviteLinkCampaign, {
            token
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.INVITE_LINK_CAMPAIGN_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.INVITE_LINK_CAMPAIGN_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function registerUser({ userData }) {
    return api.post("/api/register-subject", { ...userData });
}

function confirmUser({ token }) {
    return api.post(`/api/email/verify`, { token });
}

function loginUser({ userData }) {
    return api.post("/api/login", {
        email: userData.email,
        password: userData.password
    });
}

function logoutUser() {
    return api.post("/api/user/logout");
}

function submitPassword({ password, hash, organizationId }) {
    return api.post("/api/user/confirm-registration", {
        password,
        users_encrypted_email: hash,
        organization_id: organizationId
    });
}

function checkMagicLink({ magicLink }) {
    return api.get(`/api/check-magic-link/${magicLink}`);
}

function givePermission({ organizationId, hash }) {
    return api.post(`/api/user/give-permission-to-organization`, {
        organizationId,
        token: hash
    });
}

function givePermissionLoggedIn({ organizationId }) {
    return api.post(`/api/user/give-permission-to-organization-logged-in`, {
        organizationId
    });
}

function unsubscribe({ hash }) {
    return api.post(`/api/user/unsubscribe`, {
        users_encrypted_email: hash
    });
}

function forgotPassword({ email }) {
    return api.post("/api/user/password/send-reset-email", {
        email
    });
}

function resetPassword({ values, token }) {
    return api.post("/api/user/password/reset", {
        ...values,
        reset_token: token
    });
}

function passwordlessLogin({ token, organizationId }) {
    return api.post("/api/user/confirm-passwordless", {
        organization_id: organizationId,
        token
    });
}

function inviteLinkLogin({ token, firstName, lastName, email }) {
    return api.post(`/api/invite-token/${token}/add-subject`, {
        first_name: firstName,
        last_name: lastName,
        email
    });
}

function inviteLinkCampaign({ token }) {
    return api.get(`/api/invite-token/${token}/campaign-details`);
}
