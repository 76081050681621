import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_SUBJECT_REVIEW_CATEGORIES_REQUESTED:
        "GET_SUBJECT_REVIEW_CATEGORIES_REQUESTED",
    GET_SUBJECT_REVIEW_CATEGORIES_SUCCEEDED:
        "GET_SUBJECT_REVIEW_CATEGORIES_SUCCEEDED",
    GET_SUBJECT_REVIEW_CATEGORIES_FAILED:
        "GET_SUBJECT_REVIEW_CATEGORIES_FAILED",
    SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_REQUESTED:
        "SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_REQUESTED",
    SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_SUCCEEDED:
        "SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_SUCCEEDED",
    SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_FAILED:
        "SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_FAILED",
    GET_SUBJECT_REVIEW_RESULTS_REQUESTED:
        "GET_SUBJECT_REVIEW_RESULTS_REQUESTED",
    GET_SUBJECT_REVIEW_RESULTS_SUCCEEDED:
        "GET_SUBJECT_REVIEW_RESULTS_SUCCEEDED",
    GET_SUBJECT_REVIEW_RESULTS_FAILED: "GET_SUBJECT_REVIEW_RESULTS_FAILED",
    NEW_SUBJECT_REVIEW_REQUESTED: "NEW_SUBJECT_REVIEW_REQUESTED",
    NEW_SUBJECT_REVIEW_SUCCEEDED: "NEW_SUBJECT_REVIEW_SUCCEEDED",
    NEW_SUBJECT_REVIEW_FAILED: "NEW_SUBJECT_REVIEW_FAILED",
    GET_PERFORMANCE_OVER_TIME_REQUESTED: "GET_PERFORMANCE_OVER_TIME_REQUESTED",
    GET_PERFORMANCE_OVER_TIME_SUCCEEDED: "GET_PERFORMANCE_OVER_TIME_SUCCEEDED",
    GET_PERFORMANCE_OVER_TIME_FAILED: "GET_PERFORMANCE_OVER_TIME_FAILED",
    GET_ENGAGEMENT_OVER_TIME_REQUESTED: "GET_ENGAGEMENT_OVER_TIME_REQUESTED",
    GET_ENGAGEMENT_OVER_TIME_SUCCEEDED: "GET_ENGAGEMENT_OVER_TIME_SUCCEEDED",
    GET_ENGAGEMENT_OVER_TIME_FAILED: "GET_ENGAGEMENT_OVER_TIME_FAILED"
};

// Action Creators
export const actions = {
    getCategories: (subjectId, callback) => {
        return {
            type: types.GET_SUBJECT_REVIEW_CATEGORIES_REQUESTED,
            subjectId,
            callback
        };
    },
    sendAnswers: (
        successProfileId,
        subjectId,
        answers,
        categoryId,
        callback
    ) => {
        return {
            type: types.SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_REQUESTED,
            successProfileId,
            subjectId,
            answers,
            categoryId,
            callback
        };
    },
    getResults: (successProfileId, subjectId, callback) => {
        return {
            type: types.GET_SUBJECT_REVIEW_RESULTS_REQUESTED,
            successProfileId,
            subjectId,
            callback
        };
    },
    newReview: (successProfileId, subjectId, callback) => {
        return {
            type: types.NEW_SUBJECT_REVIEW_REQUESTED,
            successProfileId,
            subjectId,
            callback
        };
    },
    performanceOverTime: (subjectId, categoryId, callback) => {
        return {
            type: types.GET_PERFORMANCE_OVER_TIME_REQUESTED,
            subjectId,
            categoryId,
            callback
        };
    },
    engagementOverTime: (subjectId, categoryId, callback) => {
        return {
            type: types.GET_ENGAGEMENT_OVER_TIME_REQUESTED,
            subjectId,
            categoryId,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    categories: [],
    results: null,
    performanceOverTime: null,
    performanceOverTimeLoading: false,
    engagementOverTime: null,
    engagementOverTimeLoading: false,
    error: "",
    loading: true,
    newReviewLoading: false,
    resultsLoading: true,
    lastCategoryAnswer: 0
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_SUBJECT_REVIEW_CATEGORIES_REQUESTED:
            return {
                ...state,
                lastCategoryAnswer: 0,
                loading: true
            };
        case types.SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_SUBJECT_REVIEW_RESULTS_REQUESTED:
            return {
                ...state,
                results: null,
                resultsLoading: true
            };
        case types.GET_SUBJECT_REVIEW_CATEGORIES_SUCCEEDED:
            return {
                ...state,
                categories: action.data,
                error: "",
                loading: false
            };
        case types.SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_SUCCEEDED:
            return {
                ...state,
                results: action.data,
                lastCategoryAnswer: action.categoryId,
                loading: false
            };
        case types.GET_SUBJECT_REVIEW_RESULTS_SUCCEEDED:
            return {
                ...state,
                results: action.data,
                resultsLoading: false
            };
        case types.GET_SUBJECT_REVIEW_CATEGORIES_FAILED:
        case types.SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_SUBJECT_REVIEW_RESULTS_FAILED:
            return {
                ...state,
                results: null,
                error: action.payload,
                resultsLoading: false
            };
        case types.NEW_SUBJECT_REVIEW_REQUESTED:
            return {
                ...state,
                newReviewLoading: true
            };
        case types.NEW_SUBJECT_REVIEW_SUCCEEDED:
        case types.NEW_SUBJECT_REVIEW_FAILED:
            return {
                ...state,
                newReviewLoading: false
            };
        case types.GET_ENGAGEMENT_OVER_TIME_REQUESTED:
            return {
                ...state,
                engagementOverTime: null,
                engagementOverTimeLoading: true
            };
        case types.GET_ENGAGEMENT_OVER_TIME_SUCCEEDED:
            return {
                ...state,
                engagementOverTime: action.payload.data,
                engagementOverTimeLoading: false
            };
        case types.GET_ENGAGEMENT_OVER_TIME_FAILED:
            return {
                ...state,
                engagementOverTime: null,
                engagementOverTimeLoading: false
            };
        case types.GET_PERFORMANCE_OVER_TIME_REQUESTED:
            return {
                ...state,
                performanceOverTime: null,
                performanceOverTimeLoading: true
            };
        case types.GET_PERFORMANCE_OVER_TIME_SUCCEEDED:
            return {
                ...state,
                performanceOverTime: action.payload.data,
                performanceOverTimeLoading: false
            };
        case types.GET_PERFORMANCE_OVER_TIME_FAILED:
            return {
                ...state,
                performanceOverTime: null,
                performanceOverTimeLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.GET_SUBJECT_REVIEW_CATEGORIES_REQUESTED,
        getCategoriesWorker
    );
    yield takeLatest(
        types.SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_REQUESTED,
        sendCategoryAnswersWorker
    );
    yield takeLatest(
        types.GET_SUBJECT_REVIEW_RESULTS_REQUESTED,
        getResultsWorker
    );
    yield takeLatest(types.NEW_SUBJECT_REVIEW_REQUESTED, newReviewWorker);
    yield takeLatest(
        types.GET_PERFORMANCE_OVER_TIME_REQUESTED,
        performanceOverTimeWorker
    );
    yield takeLatest(
        types.GET_ENGAGEMENT_OVER_TIME_REQUESTED,
        engagementOverTimeWorker
    );
}

// Saga callback
function* getCategoriesWorker({ subjectId, callback }) {
    try {
        const response = yield call(getCategories, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_REVIEW_CATEGORIES_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_SUBJECT_REVIEW_CATEGORIES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* sendCategoryAnswersWorker({
    successProfileId,
    categoryId,
    answers,
    subjectId,
    callback
}) {
    try {
        const response = yield call(sendCategoryAnswers, {
            successProfileId,
            categoryId,
            answers,
            subjectId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_SUCCEEDED,
            data: response.data.data,
            categoryId: categoryId
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.SEND_SUBJECT_REVIEW_CATEGORY_ANSWERS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getResultsWorker({ successProfileId, subjectId, callback }) {
    try {
        const response = yield call(getResults, {
            successProfileId,
            subjectId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_REVIEW_RESULTS_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_SUBJECT_REVIEW_RESULTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* newReviewWorker({ successProfileId, subjectId, callback }) {
    try {
        const response = yield call(newReview, {
            successProfileId,
            subjectId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.NEW_SUBJECT_REVIEW_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.NEW_SUBJECT_REVIEW_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* engagementOverTimeWorker({ subjectId, categoryId, callback }) {
    try {
        const response = yield call(engagementOverTime, {
            subjectId,
            categoryId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ENGAGEMENT_OVER_TIME_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_ENGAGEMENT_OVER_TIME_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* performanceOverTimeWorker({ subjectId, categoryId, callback }) {
    try {
        const response = yield call(performanceOverTime, {
            subjectId,
            categoryId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_PERFORMANCE_OVER_TIME_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_PERFORMANCE_OVER_TIME_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function getCategories({ subjectId }) {
    return api.get(`/api/admin/reviews/categories/${subjectId}`);
}

function sendCategoryAnswers({
    successProfileId,
    categoryId,
    answers,
    subjectId
}) {
    return api.post(`/api/admin/reviews/answers`, {
        success_profile_report_id: successProfileId,
        subject_id: subjectId,
        category_id: categoryId,
        answers
    });
}

function newReview({ successProfileId, subjectId }) {
    return api.post(`/api/admin/reviews/new`, {
        success_profile_report_id: successProfileId,
        subject_id: subjectId
    });
}

function getResults({ successProfileId, subjectId }) {
    return api.get(
        `/api/admin/reviews/results/${subjectId}/${successProfileId}`
    );
}

function engagementOverTime({ subjectId, categoryId }) {
    return api.get(
        `/api/admin/reviews/engagement-over-time/${subjectId}/${categoryId}`
    );
}

function performanceOverTime({ subjectId, categoryId }) {
    return api.get(
        `/api/admin/reviews/performance-over-time/${subjectId}/${categoryId}`
    );
}
