import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { ThemeProvider as MuiThemeProvider } from "@emotion/react";
import CssBaseline from "@material-ui/core/CssBaseline";

import {
    createMuiTheme,
    ThemeProvider,
    StylesProvider
} from "@material-ui/core/styles";

import "./semantic-theme/semantic.less";
import "./index.scss";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/700.css";

import store from "./redux/store";
import Pages from "Pages/Pages";

const history = createBrowserHistory();

Sentry.init({
    dsn:
        "https://4647e4ebd4634aa988858ea9de3e581f@o645800.ingest.sentry.io/5759024",
    integrations: [
        new Integrations.BrowserTracing({
            // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
    normalizeDepth: 10,
    ignoreErrors: ["ChunkLoadError", /Loading chunk [\d]+ failed/],
    beforeSend: event => {
        const localhost = /localhost/;
        const staging = /staging/;
        if (
            localhost.test(window.location.hostname) ||
            staging.test(window.location.hostname)
        ) {
            console.log("Dont report to sentry");
            return null;
        }
        if (process.env.NODE_ENV === "production") {
            return event;
        }

        return null;
    }
});

if (process.env.ENVIRONMENT !== "prod") {
    console.log({ ENV: process.env.ENVIRONMENT });
}

const theme = createMuiTheme({
    typography: {
        fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
        htmlFontSize: 16,
        fontSize: 14,
        body2: {
            fontSize: "0.875rem"
        },
        h1: {
            fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
            fontSize: "1.8rem",
            fontWeight: "500",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.8",
            letterSpacing: "normal",
            color: "#1f2d44",
            paddingBottom: "1.5rem"
        },
        h2: {
            fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
            fontSize: "2rem",
            fontWeight: "600",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.5",
            letterSpacing: "normal",
            color: "#0A0029",
            paddingBottom: "1.5rem"
        },
        h3: {
            fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
            fontWeight: 400,
            fontSize: "1.4rem",
            lineHeight: "1.4rem",
            paddingBottom: "1.5rem",
            color: "#0A0029"
        },
        h4: {
            fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
            fontWeight: 500,
            fontSize: "1.2rem",
            lineHeight: "1.2rem",
            paddingBottom: "1.2rem"
        },
        h5: {
            fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
            fontWeight: 600,
            fontSize: "1.2rem",
            lineHeight: "1.1rem",
            paddingBottom: "1.1rem",
            color: "#050076"
        },
        h6: {
            fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
            fontSize: "1rem",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1rem",
            letterSpacing: "normal",
            paddingBottom: "0.5rem"
        }
    },
    shadows: [
        "none",
        "0 8px 12px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.02)",
        "0 4px 10px 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.02), -2px -2px 4px 0 rgba(255, 255, 255, 0.41)",
        "0 4px 10px 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.02), -2px -2px 4px 0 rgba(255, 255, 255, 0.41)",
        "0 4px 10px 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.02), -2px -2px 4px 0 rgba(255, 255, 255, 0.41)",
        "0 4px 10px 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.02), -2px -2px 4px 0 rgba(255, 255, 255, 0.41)",
        "0 4px 10px 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.02), -2px -2px 4px 0 rgba(255, 255, 255, 0.41)",
        "0 4px 10px 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.02), -2px -2px 4px 0 rgba(255, 255, 255, 0.41)",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none",
        "none"
    ],
    palette: {
        primary: {
            main: "#2000ff"
        },
        secondary: {
            main: "#00CFA9"
        },
        red: "#f44336",
        darkBlue: "#050076",
        background: {
            default: "#fbfbfb"
        }
    }
});

ReactDOM.render(
    <Router>
        <Provider store={store} history={history}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Pages />
                    </ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </Provider>
    </Router>,
    document.getElementById("app")
);
