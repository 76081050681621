import map from "lodash/map";

const isErrorObjectArray = error => {
    return error.includes(".");
};

const extractErrorForForm = errors => {
    let errorObj = {};
    let errorsArray = null;

    if (typeof errors == "string") {
        errorObj = { _error: errors };
    } else if (typeof errors == "object") {
        for (let error in errors) {
            if (!isErrorObjectArray(error)) {
                errorObj = {
                    ...errorObj,
                    [error]: errors[error]
                };
            } else {
                let parts = error.split(".");
                if (errorsArray) {
                    if (typeof errorsArray[parts[0]] === "object") {
                        if (
                            typeof errorsArray[parts[0]][Number(parts[1])] ===
                            "object"
                        ) {
                            errorsArray[parts[0]][Number(parts[1])].push(
                                parts[2]
                            );
                        } else {
                            errorsArray[parts[0]][Number(parts[1])] = [
                                parts[2]
                            ];
                        }
                    } else {
                        errorsArray[parts[0]] = [];
                        errorsArray[parts[0]][Number(parts[1])] = [parts[2]];
                    }
                } else {
                    errorsArray = {};
                    errorsArray[parts[0]] = [];
                    errorsArray[parts[0]][Number(parts[1])] = [parts[2]];
                }
            }
        }

        if (errorsArray) {
            map(errorsArray, (value, key) => {
                errorObj[key] = value;
            });
        }
    }
    errorObj.custom = ["no"];
    return errorObj;
};

export default extractErrorForForm;
