import React, { lazy } from "react";

import AuthRoute from "AdminUtil/AuthRoute";

const NewCampaign = lazy(() => import("./NewCampaign"));
const EditCampaign = lazy(() => import("./EditCampaign"));
const CopyCampaign = lazy(() => import("./CopyCampaign"));
const Campaign = lazy(() => import("./Campaign"));
const Campaigns = lazy(() => import("./Campaigns"));
const TabularResults = lazy(() => import("./TabularResults"));

import routes from "AdminPages/routes";

const definitions = [
    <AuthRoute
        key={routes.TABULAR_RESULTS}
        path={routes.TABULAR_RESULTS}
        component={TabularResults}
    />,
    <AuthRoute
        key={routes.NEW_CAMPAIGN}
        path={routes.NEW_CAMPAIGN}
        component={NewCampaign}
    />,
    <AuthRoute
        key={routes.COPY_CAMPAIGN}
        path={routes.COPY_CAMPAIGN}
        component={CopyCampaign}
    />,
    <AuthRoute
        key={routes.EDIT_CAMPAIGN}
        path={routes.EDIT_CAMPAIGN}
        component={EditCampaign}
    />,
    <AuthRoute
        key={routes.CAMPAIGN}
        path={routes.CAMPAIGN}
        component={Campaign}
    />,
    <AuthRoute
        key={routes.CAMPAIGNS}
        path={routes.CAMPAIGNS}
        component={Campaigns}
    />
];

export default definitions;

// <AuthRoute
// key={routes.CAMPAIGNS}
// path={routes.CAMPAIGNS}
// component={props => (
//     <Campaigns {...props} key={window.location.pathname} />
// )}
// />
