import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { stopSubmit } from "redux-form";
import extractErrorForForm from "Util/extractErrorForForm";

// Actions
const types = {
    GET_MANAGER_REQUESTED: "GET_MANAGER_REQUESTED",
    GET_MANAGER_SUCCEEDED: "GET_MANAGER_SUCCEEDED",
    GET_MANAGER_FAILED: "GET_MANAGER_FAILED",
    GET_MANAGER_LOGIN_REQUESTED: "GET_MANAGER_LOGIN_REQUESTED",
    GET_MANAGER_LOGIN_SUCCEEDED: "GET_MANAGER_LOGIN_SUCCEEDED",
    GET_MANAGER_LOGIN_FAILED: "GET_MANAGER_LOGIN_FAILED",
    EDIT_MANAGER_REQUESTED: "EDIT_MANAGER_REQUESTED",
    EDIT_MANAGER_SUCCEEDED: "EDIT_MANAGER_SUCCEEDED",
    EDIT_MANAGER_FAILED: "EDIT_MANAGER_FAILED",
    MANAGER_DETAIL_REQUESTED: "MANAGER_DETAIL_REQUESTED",
    MANAGER_DETAIL_SUCCEEDED: "MANAGER_DETAIL_SUCCEEDED",
    MANAGER_DETAIL_FAILED: "MANAGER_DETAIL_FAILED",
    MANAGER_ORGANIZATION_EDIT_REQUESTED: "MANAGER_ORGANIZATION_EDIT_REQUESTED",
    MANAGER_ORGANIZATION_EDIT_SUCCEEDED: "MANAGER_ORGANIZATION_EDIT_SUCCEEDED",
    MANAGER_ORGANIZATION_EDIT_FAILED: "MANAGER_ORGANIZATION_EDIT_FAILED"
};

// Action Creators
export const actions = {
    getManager: callback => {
        return {
            type: types.GET_MANAGER_REQUESTED,
            callback
        };
    },
    getManagerLogin: callback => {
        return {
            type: types.GET_MANAGER_LOGIN_REQUESTED,
            callback
        };
    },
    getManagerDetail: (id, callback) => {
        return {
            type: types.MANAGER_DETAIL_REQUESTED,
            id,
            callback
        };
    },
    editManager: (manager, managerId, callback) => {
        return {
            type: types.EDIT_MANAGER_REQUESTED,
            manager,
            managerId,
            callback
        };
    },
    editManagerOrganization: (data, callback) => {
        return {
            type: types.MANAGER_ORGANIZATION_EDIT_REQUESTED,
            data,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    data: {},
    login: {},
    manager: {},
    error: "",
    loading: false,
    message: ""
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_MANAGER_SUCCEEDED:
            return {
                ...state,
                data: action.data,
                error: "",
                loading: false
            };
        case types.GET_MANAGER_LOGIN_SUCCEEDED:
            return {
                ...state,
                login: action.data,
                error: "",
                loading: false
            };
        case types.MANAGER_DETAIL_SUCCEEDED:
            return {
                ...state,
                manager: action.manager,
                error: "",
                loading: false
            };
        case types.GET_MANAGER_REQUESTED:
        case types.GET_MANAGER_LOGIN_REQUESTED:
        case types.MANAGER_DETAIL_REQUESTED:
        case types.EDIT_MANAGER_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_MANAGER_FAILED:
        case types.GET_MANAGER_LOGIN_FAILED:
        case types.MANAGER_DETAIL_FAILED:
        case types.EDIT_MANAGER_FAILED:
        case types.MANAGER_ORGANIZATION_EDIT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.MANAGER_ORGANIZATION_EDIT_SUCCEEDED:
            return {
                ...state,
                message: action.data
            };
        case types.EDIT_MANAGER_SUCCEEDED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_MANAGER_REQUESTED, getManagerWorker);
    yield takeLatest(types.GET_MANAGER_LOGIN_REQUESTED, getManagerLoginWorker);
    yield takeLatest(types.MANAGER_DETAIL_REQUESTED, getManagerDetailWorker);
    yield takeLatest(types.EDIT_MANAGER_REQUESTED, startEditManagerWorker);
    yield takeLatest(
        types.MANAGER_ORGANIZATION_EDIT_REQUESTED,
        startEditManagerOrganization
    );
}

// Saga callback
function* getManagerWorker({ callback }) {
    try {
        const response = yield call(getManager);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_MANAGER_SUCCEEDED,
            data: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({ type: types.GET_MANAGER_FAILED, payload: e.errors });
    }
}

function* getManagerLoginWorker({ callback }) {
    try {
        const response = yield call(getManagerLogin);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_MANAGER_LOGIN_SUCCEEDED,
            data: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({ type: types.GET_MANAGER_LOGIN_FAILED, payload: e.errors });
    }
}

function* startEditManagerOrganization({ data, callback }) {
    try {
        const response = yield call(saveManagerOrganization, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.MANAGER_ORGANIZATION_EDIT_SUCCEEDED,
            data: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.MANAGER_ORGANIZATION_EDIT_FAILED,
            payload: e.errors
        });
    }
}

function* getManagerDetailWorker({ id, callback }) {
    try {
        const response = yield call(getManagerDetail, id);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.MANAGER_DETAIL_SUCCEEDED,
            manager: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({ type: types.MANAGER_DETAIL_FAILED, payload: e.errors });
    }
}

function* startEditManagerWorker({ manager, managerId, callback }) {
    try {
        const response = yield call(editManager, { manager, managerId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EDIT_MANAGER_SUCCEEDED
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.EDIT_MANAGER_FAILED, payload: e.error });
        const formError = extractErrorForForm(e.errors);
        yield put(stopSubmit("manager", formError));
        window.scrollTo(0, 0);
    }
}

// API call
function getManager() {
    return api.get(`/api/admin/manager/profile`);
}

function getManagerLogin() {
    return api.get(`/api/admin/manager/login/profile`);
}

function getManagerDetail(id) {
    return api.get(`/api/super-admin/managers/${id}`);
}

function editManager({ manager }) {
    const sendData = { ...manager };
    return api.put(`/api/admin/manager/profile`, { ...sendData });
}

function saveManagerOrganization({ data }) {
    api.setHeader("Content-Type", "multipart/form-data");

    let form = new FormData();

    if (data.logo) {
        form.append("logo", data.logo);
    }

    form.append("_method", "PUT");

    return api.post("/api/admin/manager/organization", form);
}
