import isArray from "lodash/isArray";

function hasRole(admin, role) {
    if (!admin.roles) {
        return false;
    }

    if (isArray(role)) {
        const filteredArray = admin.roles.filter(value => role.includes(value));

        return filteredArray.length;
    }

    return admin.roles.indexOf(role) !== -1;
}

export default hasRole;
