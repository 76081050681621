import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";

// Actions
const types = {
    GET_SCALES_REQUESTED: "GET_SCALES_REQUESTED",
    GET_SCALES_SUCCEEDED: "GET_SCALES_SUCCEEDED",
    GET_SCALES_FAILED: "GET_SCALES_FAILED"
};

// Action Creators
export const actions = {
    getScales: callback => {
        return {
            type: types.GET_SCALES_REQUESTED,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    scales: [],
    loading: false,
    error: ""
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_SCALES_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_SCALES_SUCCEEDED:
            const { scales } = action.payload;
            return {
                ...state,
                scales,
                loading: false,
                error: ""
            };
        case types.GET_SCALES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_SCALES_REQUESTED, getScalesWorker);
}

// Saga callback
function* getScalesWorker({ callback }) {
    try {
        const { data } = yield call(getScales);

        yield put({
            type: types.GET_SCALES_SUCCEEDED,
            payload: {
                scales: data ? data : []
            }
        });

        typeof callback == "function" && callback(data);
    } catch (e) {
        console.log(e);
        yield put({ type: types.GET_SCALES_FAILED, payload: e.message });
    }
}

// API call
function getScales() {
    return api.get("/api/personality-test/scales");
}
