const STATE_NAME = "tdl_assessments_state";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(STATE_NAME);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(STATE_NAME, serializedState);
    } catch (err) {
        // Ignore write errors
    }
};

export const clearState = () => {
    try {
        localStorage.removeItem(STATE_NAME);
    } catch (err) {
        // Ignore write errors
    }
};
