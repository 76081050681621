import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

const types = {
    GET_ROLES_REQUESTED: "GET_ROLES_REQUESTED",
    GET_ROLES_SUCCEEDED: "GET_ROLES_SUCCEEDED",
    GET_ROLES_FAILED: "GET_ROLES_FAILED",
    ASSIGN_ROLE_REQUESTED: "ASSIGN_ROLE_REQUESTED",
    ASSIGN_ROLE_SUCCEEDED: "ASSIGN_ROLE_SUCCEEDED",
    ASSIGN_ROLE_FAILED: "ASSIGN_ROLE_FAILED",
    REMOVE_ROLE_REQUESTED: "REMOVE_ROLE_REQUESTED",
    REMOVE_ROLE_SUCCEEDED: "REMOVE_ROLE_SUCCEEDED",
    REMOVE_ROLE_FAILED: "REMOVE_ROLE_FAILED"
};

export const actions = {
    getAll: callback => {
        return {
            type: types.GET_ROLES_REQUESTED,
            callback
        };
    },
    assign: (managerId, roleName, callback) => {
        return {
            type: types.ASSIGN_ROLE_REQUESTED,
            managerId,
            roleName,
            callback
        };
    },
    remove: (managerId, roleName, callback) => {
        return {
            type: types.REMOVE_ROLE_REQUESTED,
            managerId,
            roleName,
            callback
        };
    }
};

const defaultState = {
    list: [],
    error: "",
    listLoading: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_ROLES_REQUESTED:
            return {
                ...state,
                listLoading: true
            };
        case types.GET_ROLES_SUCCEEDED:
            return {
                ...state,
                list: action.payload,
                listLoading: false
            };
        case types.GET_ROLES_FAILED:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(types.GET_ROLES_REQUESTED, startGetLabelsWorker);
    yield takeLatest(types.ASSIGN_ROLE_REQUESTED, startAssingLabelWorker);
    yield takeLatest(types.REMOVE_ROLE_REQUESTED, startRemoveLabelWorker);
}

// Saga callback
function* startGetLabelsWorker({ callback }) {
    try {
        const response = yield call(getLabels);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ROLES_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.GET_ROLES_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function* startAssingLabelWorker({ managerId, roleName, callback }) {
    try {
        const response = yield call(assignLabel, { managerId, roleName });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REMOVE_ROLE_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.REMOVE_ROLE_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function* startRemoveLabelWorker({ managerId, roleName, callback }) {
    try {
        const response = yield call(removeLabel, { managerId, roleName });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REMOVE_ROLE_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.REMOVE_ROLE_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function getLabels() {
    return api.get("/api/super-admin/roles");
}

function assignLabel({ managerId, roleName }) {
    return api.post("/api/super-admin/assign-role", {
        manager_id: Number(managerId),
        role: roleName
    });
}

function removeLabel({ managerId, roleName }) {
    return api.post("/api/super-admin/remove-role", {
        manager_id: Number(managerId),
        role: roleName
    });
}
