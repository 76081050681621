import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_RESULTS_REQUESTED: "GET_RESULTS_REQUESTED",
    GET_RESULTS_SUCCEEDED: "GET_RESULTS_SUCCEEDED",
    GET_RESULTS_FAILED: "GET_RESULTS_FAILED",
    GET_PROFILE_RESULTS_REQUESTED: "GET_PROFILE_RESULTS_REQUESTED",
    GET_PROFILE_RESULTS_SUCCEEDED: "GET_PROFILE_RESULTS_SUCCEEDED",
    GET_PROFILE_RESULTS_FAILED: "GET_PROFILE_RESULTS_FAILED",
    GET_CUSTOM_PROFILE_RESULTS_REQUESTED:
        "GET_CUSTOM_PROFILE_RESULTS_REQUESTED",
    GET_CUSTOM_PROFILE_RESULTS_SUCCEEDED:
        "GET_CUSTOM_PROFILE_RESULTS_SUCCEEDED",
    GET_CUSTOM_PROFILE_RESULTS_FAILED: "GET_CUSTOM_PROFILE_RESULTS_FAILED"
};

// Action Creators
export const actions = {
    getResults: callback => {
        return {
            type: types.GET_RESULTS_REQUESTED,
            callback
        };
    },
    getProfileResults: (profileId, callback) => {
        return {
            type: types.GET_PROFILE_RESULTS_REQUESTED,
            profileId,
            callback
        };
    },
    getCustomProfileResults: (profileId, callback) => {
        return {
            type: types.GET_CUSTOM_PROFILE_RESULTS_REQUESTED,
            profileId,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    results: [],
    name: "",
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_RESULTS_REQUESTED:
            return {
                results: action.results,
                loading: true
            };
        case types.GET_RESULTS_SUCCEEDED:
            return {
                results: action.data.results,
                name: action.data.name,
                error: "",
                loading: false
            };
        case types.GET_RESULTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_RESULTS_REQUESTED, getResultsWorker);
    yield takeLatest(
        types.GET_PROFILE_RESULTS_REQUESTED,
        getProfileResultsWorker
    );
    yield takeLatest(
        types.GET_CUSTOM_PROFILE_RESULTS_REQUESTED,
        getCustomProfileResultsWorker
    );
}

// Saga callback
function* getResultsWorker({ callback }) {
    try {
        const response = yield call(getResults);

        yield put({
            type: types.GET_RESULTS_SUCCEEDED,
            data: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e);
        yield put({ type: types.GET_RESULTS_FAILED, payload: e.message });
    }
}

function* getProfileResultsWorker({ profileId, callback }) {
    try {
        const response = yield call(getProfileResults, { profileId });

        yield put({
            type: types.GET_PROFILE_RESULTS_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e);
        yield put({ type: types.GET_RESULTS_FAILED, payload: e.message });
        callFail(callback, e.errors);
    }
}

function* getCustomProfileResultsWorker({ profileId, callback }) {
    try {
        const response = yield call(getCustomProfileResults, { profileId });

        yield put({
            type: types.GET_CUSTOM_PROFILE_RESULTS_SUCCEEDED,
            data: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e);
        yield put({
            type: types.GET_CUSTOM_PROFILE_RESULTS_FAILED,
            payload: e.message
        });
        callFail(callback, e.errors);
    }
}

// API call
function getResults() {
    return api.get("/api/personality-test/results");
}

function getProfileResults({ profileId }) {
    return api.get(`/api/personality-test/profile-population/${profileId}`);
}

function getCustomProfileResults({ profileId }) {
    return api.get(
        `/api/personality-test/custom-profile-population/${profileId}`
    );
}
