import { takeEvery, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_COMPARE_PERSONALITY_CAMPAIGNS_REQUESTED:
        "GET_COMPARE_PERSONALITY_CAMPAIGNS_REQUESTED",
    GET_COMPARE_PERSONALITY_CAMPAIGNS_SUCCEEDED:
        "GET_COMPARE_PERSONALITY_CAMPAIGNS_SUCCEEDED",
    GET_COMPARE_PERSONALITY_CAMPAIGNS_FAILED:
        "GET_COMPARE_PERSONALITY_CAMPAIGNS_FAILED",
    GET_COMPARE_CULTURE_CAMPAIGNS_REQUESTED:
        "GET_COMPARE_CULTURE_CAMPAIGNS_REQUESTED",
    GET_COMPARE_CULTURE_CAMPAIGNS_SUCCEEDED:
        "GET_COMPARE_CULTURE_CAMPAIGNS_SUCCEEDED",
    GET_COMPARE_CULTURE_CAMPAIGNS_FAILED:
        "GET_COMPARE_CULTURE_CAMPAIGNS_FAILED",
    GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_REQUESTED:
        "GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_REQUESTED",
    GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_SUCCEEDED:
        "GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_SUCCEEDED",
    GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_FAILED:
        "GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_FAILED",
    GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_REQUESTED:
        "GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_REQUESTED",
    GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_SUCCEEDED:
        "GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_SUCCEEDED",
    GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_FAILED:
        "GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_FAILED"
};

// Action Creators
export const actions = {
    getPersonalityCampaigns: callback => {
        return {
            type: types.GET_COMPARE_PERSONALITY_CAMPAIGNS_REQUESTED,
            callback
        };
    },
    getCultureCampaigns: callback => {
        return {
            type: types.GET_COMPARE_CULTURE_CAMPAIGNS_REQUESTED,
            callback
        };
    },
    getPersonalityCampaignAverage: (campaignId, callback) => {
        return {
            type: types.GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_REQUESTED,
            campaignId,
            callback
        };
    },
    getCultureCampaignAverage: (campaignId, callback) => {
        return {
            type: types.GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_REQUESTED,
            campaignId,
            callback
        };
    }
};

// DEFAULT state
const defaultState = {
    personalityCampaigns: [],
    personalityCampaignsLoading: true,
    cultureCampaigns: [],
    cultureCampaignsLoading: true,
    personalityCampaignAverage: null,
    personalityCampaignAverageLoading: true,
    cultureCampaignAverage: null,
    cultureCampaignAverageLoading: true,
    error: ""
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_COMPARE_PERSONALITY_CAMPAIGNS_SUCCEEDED:
            return {
                ...state,
                personalityCampaigns: action.campaigns,
                error: "",
                personalityCampaignsLoading: false
            };
        case types.GET_COMPARE_PERSONALITY_CAMPAIGNS_REQUESTED:
            return {
                ...state,
                personalityCampaignsLoading: true
            };
        case types.GET_COMPARE_PERSONALITY_CAMPAIGNS_FAILED:
            return {
                ...state,
                error: action.payload,
                personalityCampaignsLoading: false
            };
        case types.GET_COMPARE_CULTURE_CAMPAIGNS_SUCCEEDED:
            return {
                ...state,
                cultureCampaigns: action.campaigns,
                error: "",
                cultureCampaignsLoading: false
            };
        case types.GET_COMPARE_CULTURE_CAMPAIGNS_REQUESTED:
            return {
                ...state,
                cultureCampaignsLoading: true
            };
        case types.GET_COMPARE_CULTURE_CAMPAIGNS_FAILED:
            return {
                ...state,
                error: action.payload,
                cultureCampaignsLoading: false
            };
        case types.GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_SUCCEEDED:
            return {
                ...state,
                personalityCampaignAverage: action.campaigns,
                personalityCampaignAverageLoading: false,
                error: ""
            };
        case types.GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_REQUESTED:
            return {
                ...state,
                personalityCampaignAverageLoading: true
            };
        case types.GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_FAILED:
            return {
                ...state,
                error: action.payload,
                personalityCampaignAverageLoading: false
            };
        case types.GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_SUCCEEDED:
            return {
                ...state,
                cultureCampaignAverage: action.campaigns,
                cultureCampaignAverageLoading: false,
                error: ""
            };
        case types.GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_REQUESTED:
            return {
                ...state,
                cultureCampaignAverageLoading: true
            };
        case types.GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_FAILED:
            return {
                ...state,
                error: action.payload,
                cultureCampaignAverageLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeEvery(
        types.GET_COMPARE_PERSONALITY_CAMPAIGNS_REQUESTED,
        startGetPersonalityCampaignsWorker
    );
    yield takeEvery(
        types.GET_COMPARE_CULTURE_CAMPAIGNS_REQUESTED,
        startGetCultureCampaignsWorker
    );
    yield takeEvery(
        types.GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_REQUESTED,
        startGetPersonalityCampaignAverageWorker
    );
    yield takeEvery(
        types.GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_REQUESTED,
        startGetCultureCampaignAverageWorker
    );
}

// Saga callback
function* startGetPersonalityCampaignsWorker({ callback }) {
    try {
        const response = yield call(getCampaignsForPersonalityCompare);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPARE_PERSONALITY_CAMPAIGNS_SUCCEEDED,
            campaigns: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPARE_PERSONALITY_CAMPAIGNS_FAILED,
            payload: e.error
        });
        callFail(callback, e.errors);
    }
}

function* startGetCultureCampaignsWorker({ callback }) {
    try {
        const response = yield call(getCampaignsForCultureCompare);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPARE_CULTURE_CAMPAIGNS_SUCCEEDED,
            campaigns: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPARE_CULTURE_CAMPAIGNS_FAILED,
            payload: e.error
        });
        callFail(callback, e.errors);
    }
}

function* startGetPersonalityCampaignAverageWorker({ campaignId, callback }) {
    try {
        const response = yield call(getPersonalityCampaignAverage, {
            campaignId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_SUCCEEDED,
            campaigns: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPARE_PERSONALITY_CAMPAIGN_AVERAGE_FAILED,
            payload: e.error
        });
        callFail(callback, e.errors);
    }
}

function* startGetCultureCampaignAverageWorker({ campaignId, callback }) {
    try {
        const response = yield call(getCultureCampaignAverage, { campaignId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_SUCCEEDED,
            campaigns: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_COMPARE_CULTURE_CAMPAIGN_AVERAGE_FAILED,
            payload: e.error
        });
        callFail(callback, e.errors);
    }
}

// API call
function getCampaignsForPersonalityCompare() {
    return api.get("/api/admin/compare/personality/campaigns");
}

function getCampaignsForCultureCompare() {
    return api.get("/api/admin/compare/culture/campaigns");
}

function getPersonalityCampaignAverage({ campaignId }) {
    return api.get(
        `/api/admin/campaigns/${campaignId}/personality-test/average-results`
    );
}

function getCultureCampaignAverage({ campaignId }) {
    return api.get(
        `/api/admin/campaigns/${campaignId}/culture-test/average-results`
    );
}
