import { takeEvery, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_ORGANIZATION_CULTURE_QUESTIONS_REQUESTED:
        "GET_ORGANIZATION_CULTURE_QUESTIONS_REQUESTED",
    GET_ORGANIZATION_CULTURE_QUESTIONS_SUCCEEDED:
        "GET_ORGANIZATION_CULTURE_QUESTIONS_SUCCEEDED",
    GET_ORGANIZATION_CULTURE_QUESTIONS_FAILED:
        "GET_ORGANIZATION_CULTURE_QUESTIONS_FAILED"
};

// Action Creators
export const actions = {
    get: (categoryId, callback) => {
        return {
            type: types.GET_ORGANIZATION_CULTURE_QUESTIONS_REQUESTED,
            categoryId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_ORGANIZATION_CULTURE_QUESTIONS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_ORGANIZATION_CULTURE_QUESTIONS_SUCCEEDED:
            return {
                data: {
                    ...state.data,
                    [action.payload.categoryId]: action.payload.data.data
                },
                error: "",
                loading: false
            };
        case types.GET_ORGANIZATION_CULTURE_QUESTIONS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeEvery(
        types.GET_ORGANIZATION_CULTURE_QUESTIONS_REQUESTED,
        getWorker
    );
}

// Saga callback
function* getWorker({ categoryId, callback }) {
    try {
        const response = yield call(get, { categoryId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATION_CULTURE_QUESTIONS_SUCCEEDED,
            payload: { categoryId, data: response.data }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_ORGANIZATION_CULTURE_QUESTIONS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get({ categoryId }) {
    return api.get(`/api/admin/culture-test/questions/category/${categoryId}`);
}
