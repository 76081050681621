import { takeLatest, call, put } from "redux-saga/effects";
import { callSuccess, callFail } from "Util/callback";

import api from "Util/api";

// Actions
const types = {
    PERSONALITY_GET_TRANSLATIONS_REQUESTED:
        "PERSONALITY_GET_TRANSLATIONS_REQUESTED",
    PERSONALITY_GET_TRANSLATIONS_SUCCEEDED:
        "PERSONALITY_GET_TRANSLATIONS_SUCCEEDED",
    PERSONALITY_GET_TRANSLATIONS_FAILED: "PERSONALITY_GET_TRANSLATIONS_FAILED",
    PERSONALITY_SET_TRANSLATION_LANGUAGE_REQUESTED:
        "PERSONALITY_SET_TRANSLATION_LANGUAGE_REQUESTED",
    PERSONALITY_SET_TRANSLATION_LANGUAGE_SUCCEEDED:
        "PERSONALITY_SET_TRANSLATION_LANGUAGE_SUCCEEDED",
    PERSONALITY_SET_TRANSLATION_LANGUAGE_FAILED:
        "PERSONALITY_SET_TRANSLATION_LANGUAGE_FAILED"
};

// Action Creators
export const actions = {
    getTranslations: callback => {
        return {
            type: types.PERSONALITY_GET_TRANSLATIONS_REQUESTED,
            callback
        };
    },
    setTranslationLanguage: (language, callback) => {
        return {
            type: types.PERSONALITY_SET_TRANSLATION_LANGUAGE_REQUESTED,
            language,
            callback
        };
    }
};

// Default state
const defaultState = {
    translations: null,
    translationLanguage: "English",
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.PERSONALITY_GET_TRANSLATIONS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.PERSONALITY_GET_TRANSLATIONS_SUCCEEDED: {
            return {
                ...state,
                translations: action.payload,
                loading: false
            };
        }
        case types.PERSONALITY_GET_TRANSLATIONS_FAILED: {
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        }
        case types.PERSONALITY_SET_TRANSLATION_LANGUAGE_REQUESTED:
            return {
                ...state,
                translationLanguage: action.language
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.PERSONALITY_GET_TRANSLATIONS_REQUESTED,
        getTranslationsWorker
    );
}

// Saga callback
function* getTranslationsWorker({ callback }) {
    try {
        const response = yield call(getTranslations);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.PERSONALITY_GET_TRANSLATIONS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.PERSONALITY_GET_TRANSLATIONS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function getTranslations() {
    return api.get("/api/personality-test/translations");
}
