const routes = {
    ACTION_LOGS: "/admin/action-logs",
    DASHBOARD: "/admin/dashboard",
    NEW_CAMPAIGN: "/admin/assessments/new",
    EDIT_CAMPAIGN: "/admin/assessments/edit/:id",
    COPY_CAMPAIGN: "/admin/assessments/copy/:id",
    CAMPAIGN: "/admin/assessments/view/:id",
    TABULAR_RESULTS: "/admin/assessments/:id/:test/tabular",
    CAMPAIGNS: "/admin/assessments",
    ASSESSMENTS: "/admin/compare",
    CANDIDATES: "/admin/talent",
    CHOOSE_PASSWORD: "/admin/create-account-manager/:hash",
    LOGIN: "/admin/login",
    NEW_MANAGER: "/admin/organizations/:id/managers/new",
    MANAGER_PROFILE: "/admin/manager/profile/details",
    MANAGER_ORGANIZATION: "/admin/manager/organization/view",
    SUBJECT_LABEL: "/admin/manager/labels/details",
    NEW_SUBJECT_LABEL: "/admin/manager/labels/new",
    EDIT_MANAGER_ORGANIZATION: "/admin/manager/organization/edit",
    ORGANIZATION_NEW_CULTURE_TEST: "/admin/manager/organization/culture/new",
    ORGANIZATION_CULTURE_TEST: "/admin/manager/organization/culture/:id",
    ORGANIZATION_CULTURE_RESULTS:
        "/admin/manager/organization/culture/results/:id",
    ORGANIZATION_CULTURE_ADMIN_RESULTS:
        "/admin/organizations/culture/results/:id/:cultureId",
    ORGANIZATION_SUBSCRIPTION: "/admin/manager/organization/subscription",
    ORGANIZATION_SUBSCRIPTION_SUCCESS: "/after-payment/success",
    EDIT_MANAGER: "/admin/manager/profile/edit/:id",
    NEW_ORGANIZATION: "/admin/organizations/new",
    EDIT_ORGANIZATION: "/admin/organizations/edit/:id",
    ORGANIZATION: "/admin/organizations/view/:id",
    ORGANIZATIONS: "/admin/organizations",
    INACTIVE_ORGANIZATION: "/inactive-organization",
    CANDIDATE_PROFILE: "/admin/subject-profile/:id",
    PERSONALITY_TEST_RESULT: "/admin/report/personality/:subjectId",
    MCQ_TEST_RESULT: "/admin/report/mcqt/:mcqTestId/:subjectId",
    LEGACY_PERSONALITY_TEST_RESULT: "/admin/test/result/:subjectId",
    IQ_TEST_RESULT: "/admin/report/logical/:subjectId",
    LEGACY_IQ_TEST_RESULT: "/admin/iq/result/:subjectId",
    CULTURE_RESULT: "/admin/report/culture/:subjectId",
    LEGACY_CULTURE_RESULT: "/admin/culture/result/:subjectId",
    INTERVIEW_RESULT: "/admin/interview/result/:interviewId/:subjectId",
    CULTURE_COMPARE_RESULTS:
        "/admin/report/culture-test/compare/:first/:second",
    PERSONALITY_COMPARE_RESULTS:
        "/admin/report/personality-test/compare/:first/:second",
    PERSONALITY_TEAM_COMPARE_RESULTS:
        "/admin/report/personality-test/team-compare",
    ADMIN: "/admin",
    SUCCESS_PROFILE_SUBJECT_REVIEW:
        "/admin/manager/organization/success-profile/:successProfileId/subject/:subjectId/category/:categoryId",
    SUCCESS_PROFILE_VIEW:
        "/admin/manager/organization/success-profile/:successProfileId",
    SUCCESS_PROFILE_NEW: "/admin/manager/organization/success-profile",
    SUCCESS_PROFILE_EDIT:
        "/admin/manager/organization/success-profile/:successProfileId/edit",
    SUCCESS_PROFILE_SEARCH_SUBJECTS:
        "/admin/manager/organization/success-profile/:successProfileId/search-subjects",
    SUCCESS_PROFILE_REPORT:
        "/admin/manager/organization/success-profile/:successProfileId/report",
    LABEL_NEW: "/admin/manager/organization/labels",
    LABEL_EDIT: "/admin/manager/organization/labels/:labelId",
    NEW_JOB_PROFILE: "/admin/job-profile/new",
    LIST_JOB_PROFILES: "/admin/job-profile/list",
    EDIT_JOB_PROFILE: "/admin/job-profile/edit/:id",
    WFH_INDEX_REPORT: "/admin/report/working-from-home-index/:subjectId",
    BURNOUT_REPORT: "/admin/report/burnout-index/:subjectId",
    MCQ_TEST_LIST: "/admin/multiple-choice-questions/list",
    MCQ_TEST_NEW: "/admin/multiple-choice-questions/new",
    MCQ_TEST_EDIT: "/admin/multiple-choice-questions/edit/:mcqTestId",
    MCQ_TEST_VIEW: "/admin/multiple-choice-questions/view/:mcqTestId",
    MCQ_TEST_NEW_QUESTION:
        "/admin/multiple-choice-questions/question/new/:mcqTestId",
    MCQ_TEST_EDIT_QUESTION:
        "/admin/multiple-choice-questions/question/edit/:mcqTestId/:mcqTestQuestionId",
    MCQ_TEST_VIEW_QUESTION:
        "/admin/multiple-choice-questions/question/view/:mcqTestId/:mcqTestQuestionId",
    MCQ_TEST_NEW_QUESTION_OPTION:
        "/admin/multiple-choice-questions/option/new/:mcqTestId/:mcqTestQuestionId",
    MCQ_TEST_EDIT_QUESTION_OPTION:
        "/admin/multiple-choice-questions/option/edit/:mcqTestId/:mcqTestQuestionId/:mcqTestQuestionOptionId",
    MCQ_TEST_VIEW_QUESTION_OPTION:
        "/admin/multiple-choice-questions/option/view/:mcqTestId/:mcqTestQuestionId/:mcqTestQuestionOptionId"
};

export function getIdRoute(route, id) {
    return route.replace(":id", id);
}

export function getCultureIdRoute(route, id) {
    return route.replace(":cultureId", id);
}

export function getSubjectIdRoute(route, id) {
    return route.replace(":subjectId", id);
}

export function getSuccessProfileIdRoute(route, id) {
    return route.replace(":successProfileId", id);
}

export function getMCQTestIdRoute(route, id) {
    return route.replace(":mcqTestId", id);
}

export function getMCQTestQuestionIdRoute(route, id) {
    return route.replace(":mcqTestQuestionId", id);
}

export function getMCQTestQuestionOptionIdRoute(route, id) {
    return route.replace(":mcqTestQuestionOptionId", id);
}

export function getSubjectReviewRoute(successProfileId, subjectId, categoryId) {
    return routes.SUCCESS_PROFILE_SUBJECT_REVIEW.replace(
        ":successProfileId",
        successProfileId
    )
        .replace(":subjectId", subjectId)
        .replace(":categoryId", categoryId);
}

export function getLabelIdRoute(route, id) {
    return route.replace(":labelId", id);
}

export function getCampaignSubjectRoute(route, campaignId, subjectId) {
    return route
        .replace(":campaignId", campaignId)
        .replace(":subjectId", subjectId);
}

export function getInterviewSubjectRoute(route, interviewId, subjectId) {
    return route
        .replace(":interviewId", interviewId)
        .replace(":subjectId", subjectId);
}

export function getPageRoute(route, page) {
    return route.replace(":page", page);
}

export function getTabularResultsRoute(route, id, test) {
    return route.replace(":id", id).replace(":test", test);
}

export default routes;
