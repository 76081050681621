// import { create } from 'apisauce';
import axios from "axios";
import store from "../redux/store";
import userStore from "../redux/User";
import routes from "AdminPages/routes";

let url = "";

if (process.env.NODE_ENV === "production") {
    url = "";
} else {
    url = "http://personality-test-client.tdl.localhost";
}

const api = axios.create({
    baseURL: url,
    headers: { Accept: "application/json" }
});

api.setHeader = function(key, value) {
    this.defaults.headers.common[key] = value;
};

// const api = create({
//     baseURL: url,
//     headers: { Accept: 'application/json' }
// });

api.interceptors.request.use(
    function(config) {
        const userCredentials = store.getState().user.data;

        const headers = config.headers;

        if (userCredentials) {
            headers.Authorization = `${userCredentials.token_type} ${userCredentials.access_token}`;
        }

        return {
            ...config,
            headers
        };
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    function(response) {
        // Do something with response data
        return {
            ...response,
            ok: true
        };
    },
    function(error) {
        if (error.response.status === 403 && error.response.data.inactive) {
            window.location.href = routes.INACTIVE_ORGANIZATION;
            return {
                ok: false
            };
        }
        if (error.response.status === 401) {
            store.dispatch(userStore.actions.clearAuthDataUser());
        }
        // eslint-disable-next-line no-undef
        //return Promise.reject(error);
        return {
            ...error.response,
            ok: false
        };
    }
);

export default api;
