import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

const types = {
    GET_ALL_FEATURES_REQUESTED: "GET_ALL_FEATURES_REQUESTED",
    GET_ALL_FEATURES_SUCCEEDED: "GET_ALL_FEATURES_SUCCEEDED",
    GET_ALL_FEATURES_FAILED: "GET_ALL_FEATURES_FAILED",
    GET_OGRANIZATION_FEATURES_REQUESTED: "GET_OGRANIZATION_FEATURES_REQUESTED",
    GET_OGRANIZATION_FEATURES_SUCCEEDED: "GET_OGRANIZATION_FEATURES_SUCCEEDED",
    GET_OGRANIZATION_FEATURES_FAILED: "GET_OGRANIZATION_FEATURES_FAILED",
    ASSIGN_ORGANIZATION_FEATURES_REQUESTED:
        "ASSIGN_ORGANIZATION_FEATURES_REQUESTED",
    ASSIGN_ORGANIZATION_FEATURES_SUCCEEDED:
        "ASSIGN_ORGANIZATION_FEATURES_SUCCEEDED",
    ASSIGN_ORGANIZATION_FEATURES_FAILED: "ASSIGN_ORGANIZATION_FEATURES_FAILED"
};

export const actions = {
    getAll: callback => {
        return {
            type: types.GET_ALL_FEATURES_REQUESTED,
            callback
        };
    },
    getForOrganization: (organizationId, callback) => {
        return {
            type: types.GET_OGRANIZATION_FEATURES_REQUESTED,
            organizationId,
            callback
        };
    },
    assign: (organizationId, features, callback) => {
        return {
            type: types.ASSIGN_ORGANIZATION_FEATURES_REQUESTED,
            organizationId,
            features,
            callback
        };
    }
};

const defaultState = {
    list: [],
    organization: [],
    organizationLoading: false,
    error: "",
    listLoading: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_ALL_FEATURES_REQUESTED:
            return {
                ...state,
                listLoading: true
            };
        case types.GET_ALL_FEATURES_SUCCEEDED:
            return {
                ...state,
                list: action.payload,
                listLoading: false
            };
        case types.GET_ALL_FEATURES_FAILED:
            return {
                ...state,
                listLoading: false,
                error: action.error
            };
        case types.GET_OGRANIZATION_FEATURES_REQUESTED:
            return {
                ...state,
                organizationLoading: true
            };
        case types.GET_OGRANIZATION_FEATURES_SUCCEEDED:
            return {
                ...state,
                organization: action.payload,
                organizationLoading: false
            };
        case types.GET_OGRANIZATION_FEATURES_FAILED:
            return {
                ...state,
                organizationLoading: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(
        types.GET_ALL_FEATURES_REQUESTED,
        startGetAllFeaturesWorker
    );
    yield takeLatest(
        types.GET_OGRANIZATION_FEATURES_REQUESTED,
        startGetOrganizationFeaturesWorker
    );
    yield takeLatest(
        types.ASSIGN_ORGANIZATION_FEATURES_REQUESTED,
        startAssignOrganizationFeaturesWorker
    );
}

// Saga callback
function* startGetAllFeaturesWorker({ callback }) {
    try {
        const response = yield call(getAll);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ALL_FEATURES_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.GET_ALL_FEATURES_FAILED, payload: e.errors });
        callFail(callback, e.errors);
    }
}

function* startGetOrganizationFeaturesWorker({ organizationId, callback }) {
    try {
        const response = yield call(getForOrganization, { organizationId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_OGRANIZATION_FEATURES_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_OGRANIZATION_FEATURES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* startAssignOrganizationFeaturesWorker({
    organizationId,
    features,
    callback
}) {
    try {
        const response = yield call(assign, { organizationId, features });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ASSIGN_ORGANIZATION_FEATURES_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.ASSIGN_ORGANIZATION_FEATURES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function getAll() {
    return api.get("/api/super-admin/features");
}

function getForOrganization({ organizationId }) {
    return api.get(`/api/super-admin/features/organization/${organizationId}`);
}

function assign({ organizationId, features }) {
    return api.put("/api/super-admin/features/organization", {
        organization_id: Number(organizationId),
        feature_ids: features
    });
}
