import { useEffect } from "react";
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import CompareArrowsOutlinedIcon from "@material-ui/icons/CompareArrowsOutlined";

import managerStore from "AdminStore/Manager";
import hasRole from "AdminUtil/hasRole";
import routes from "AdminPages/routes";
import userRoutes from "Pages/routes";
import useUser from "Util/user";
import NavLink from "Components/Nav/NavLink";
import ProfileMenu from "Components/Nav/ProfileMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

const Nav = () => {
    const { logout, isAuth } = useUser();

    const dispatch = useDispatch();
    const matchAdminHome = useRouteMatch({
        path: "/admin",
        exact: true
    });
    const matchDashboard = useRouteMatch("/admin/dashboard");
    const matchCampaigns = useRouteMatch(routes.CAMPAIGNS);
    const matchCandidates = useRouteMatch(routes.CANDIDATES);
    const matchTalent = useRouteMatch("/admin/subject-profile");
    const matchAssessments = useRouteMatch(routes.ASSESSMENTS);

    const matchLogin = useRouteMatch("/admin/login");

    // const matchSubjectLabel = useRouteMatch("/admin/manager/labels/details");

    const admin = useSelector(state => state.user);
    const manager = useSelector(state => state.admin.manager.login);
    const managerLoading = useSelector(state => state.admin.manager.loading);

    useEffect(() => {
        if (!manager.id && !managerLoading) {
            getManager();
        }
    }, [dispatch]);

    const getManager = () => {
        dispatch(managerStore.actions.getManagerLogin());
    };

    let superAdminLinks = null;
    let tdlAdminLinks = null;
    let adminLinks = null;

    if (isAuth()) {
        adminLinks = [
            <NavLink
                key="dashboard"
                active={!!matchDashboard || !!matchAdminHome}
                component={Link}
                to={routes.DASHBOARD}
                startIcon={<DashboardOutlinedIcon />}
            >
                Dashboard
            </NavLink>,
            <NavLink
                key="campaigns"
                name="campaigns"
                // icon="bullhorn"
                active={!!matchCampaigns}
                component={Link}
                to={routes.CAMPAIGNS}
                startIcon={<AssessmentOutlinedIcon />}
            >
                Assessments
            </NavLink>,
            <NavLink
                key="candidates"
                name="candidates"
                // icon="users"
                active={!!matchCandidates || !!matchTalent}
                component={Link}
                to={routes.CANDIDATES}
                startIcon={<PeopleAltOutlinedIcon />}
            >
                Talent
            </NavLink>,
            <NavLink
                key="assessments"
                name="assessments"
                active={!!matchAssessments}
                component={Link}
                to={routes.ASSESSMENTS}
                startIcon={<CompareArrowsOutlinedIcon />}
            >
                Compare
            </NavLink>
        ];
    }
    if (hasRole(admin, ["super_admin", "tdl_admin"])) {
        tdlAdminLinks = [
            <Divider light key="divider1" />,
            <MenuItem
                css={theme => css`
                    &:hover {
                        color: ${theme.palette.primary.main};
                    }
                `}
                key="organizations"
                component={Link}
                to={routes.ORGANIZATIONS}
            >
                Organizations
            </MenuItem>
        ];
    }
    if (hasRole(admin, "super_admin")) {
        superAdminLinks = [
            <Divider light key="divider2" />,
            <MenuItem
                css={theme => css`
                    &:hover {
                        color: ${theme.palette.primary.main};
                    }
                `}
                key="mcq-test"
                component={Link}
                to={routes.MCQ_TEST_LIST}
            >
                Multi Choice Question Tests
            </MenuItem>,
            <MenuItem
                css={theme => css`
                    &:hover {
                        color: ${theme.palette.primary.main};
                    }
                `}
                key="job-profiles"
                component={Link}
                to={routes.LIST_JOB_PROFILES}
            >
                Job Profiles
            </MenuItem>,
            <MenuItem
                css={theme => css`
                    &:hover {
                        color: ${theme.palette.primary.main};
                    }
                `}
                key="action-logs"
                component={Link}
                to={routes.ACTION_LOGS}
            >
                Action Logs
            </MenuItem>
        ];
    }

    return (
        <AppBar
            position="fixed"
            elevation={1}
            css={css`
                background-color: #fff;
            `}
        >
            <Toolbar
                variant="dense"
                css={css`
                    padding: 0;
                `}
            >
                <div
                    css={css`
                        width: 100%;
                        display: flex;
                        padding: 0 0.5rem;
                    `}
                >
                    <div
                        css={css`
                            white-space: nowrap;
                        `}
                    >
                        <NavLink
                            css={css`
                                border: none;
                            `}
                            component={Link}
                            to={routes.DASHBOARD}
                        >
                            <img
                                src="/apple-icon-180x180.png"
                                alt="Talent Data Labs"
                                css={css`
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin: -0.5em 0;
                                    width: 2.5em;
                                `}
                            />
                            &nbsp;
                            <b>
                                {manager.organization_name
                                    ? manager.organization_name
                                    : `Talent Data Labs`}
                            </b>
                        </NavLink>
                    </div>
                    <div
                        css={css`
                            display: flex;
                            width: 100%;
                            justify-content: center;
                        `}
                    >
                        {adminLinks}
                    </div>
                    <div
                        css={css`
                            width: 180px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        `}
                    >
                        {isAuth() ? (
                            <ProfileMenu>
                                <MenuItem
                                    css={theme => css`
                                        color: ${theme.palette.darkBlue};
                                        &:hover {
                                            color: ${theme.palette.primary
                                                .main};
                                        }
                                    `}
                                    component={Link}
                                    to={userRoutes.HOME}
                                >
                                    Switch to Talent View
                                </MenuItem>
                                <Divider light />
                                <MenuItem
                                    css={theme => css`
                                        &:hover {
                                            color: ${theme.palette.primary
                                                .main};
                                        }
                                    `}
                                    key="profile"
                                    component={Link}
                                    to={routes.MANAGER_PROFILE}
                                >
                                    Edit Manager Profile
                                </MenuItem>
                                <MenuItem
                                    css={theme => css`
                                        &:hover {
                                            color: ${theme.palette.primary
                                                .main};
                                        }
                                    `}
                                    key="organization"
                                    component={Link}
                                    to={routes.MANAGER_ORGANIZATION}
                                >
                                    {manager.organization_name}
                                </MenuItem>
                                {tdlAdminLinks}
                                {superAdminLinks}
                                <Divider light />
                                <MenuItem
                                    css={theme => css`
                                        &:hover {
                                            color: ${theme.palette.red};
                                        }
                                    `}
                                    onClick={() => logout()}
                                >
                                    Logout
                                </MenuItem>
                            </ProfileMenu>
                        ) : (
                            <NavLink
                                active={!!matchLogin}
                                component={Link}
                                to={routes.LOGIN}
                            >
                                Login
                            </NavLink>
                        )}
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Nav;
