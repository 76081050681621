import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

const types = {
    GET_BURNOUT_CATEGORIES_AND_QUESTIONS_REQUESTED:
        "GET_BURNOUT_CATEGORIES_AND_QUESTIONS_REQUESTED",
    GET_BURNOUT_CATEGORIES_AND_QUESTIONS_SUCCEEDED:
        "GET_BURNOUT_CATEGORIES_AND_QUESTIONS_SUCCEEDED",
    GET_BURNOUT_CATEGORIES_AND_QUESTIONS_FAILED:
        "GET_BURNOUT_CATEGORIES_AND_QUESTIONS_FAILED",
    GET_BURNOUT_ANSWERS_REQUESTED: "GET_BURNOUT_ANSWERS_REQUESTED",
    GET_BURNOUT_ANSWERS_SUCCEEDED: "GET_BURNOUT_ANSWERS_SUCCEEDED",
    GET_BURNOUT_ANSWERS_FAILED: "GET_BURNOUT_ANSWERS_FAILED",
    CREATE_BURNOUT_REQUESTED: "CREATE_BURNOUT_REQUESTED",
    CREATE_BURNOUT_SUCCEEDED: "CREATE_BURNOUT_SUCCEEDED",
    CREATE_BURNOUT_FAILED: "CREATE_BURNOUT_FAILED",
    GET_BURNOUT_LIST_REQUESTED: "GET_BURNOUT_LIST_REQUESTED",
    GET_BURNOUT_LIST_SUCCEEDED: "GET_BURNOUT_LIST_SUCCEEDED",
    GET_BURNOUT_LIST_FAILED: "GET_BURNOUT_LIST_FAILED",
    SEND_BURNOUT_ANSWERS_REQUESTED: "SEND_BURNOUT_ANSWERS_REQUESTED",
    SEND_BURNOUT_ANSWERS_SUCCEEDED: "SEND_BURNOUT_ANSWERS_SUCCEEDED",
    SEND_BURNOUT_ANSWERS_FAILED: "SEND_BURNOUT_ANSWERS_FAILED"
};

export const actions = {
    getCategoriesAndQuestions: callback => {
        return {
            type: types.GET_BURNOUT_CATEGORIES_AND_QUESTIONS_REQUESTED,
            callback
        };
    },
    getAnswers: (burnoutId, callback) => {
        return {
            type: types.GET_BURNOUT_ANSWERS_REQUESTED,
            burnoutId,
            callback
        };
    },
    sendAnswers: (burnoutId, categoryId, answers, callback) => {
        return {
            type: types.SEND_BURNOUT_ANSWERS_REQUESTED,
            burnoutId,
            categoryId,
            answers,
            callback
        };
    },
    create: callback => {
        return {
            type: types.CREATE_BURNOUT_REQUESTED,
            callback
        };
    },
    getAll: callback => {
        return {
            type: types.GET_BURNOUT_LIST_REQUESTED,
            callback
        };
    }
};

const defaultState = {
    categoriesAndQuestions: [],
    categoriesAndQuestionsLoading: false,
    answers: [],
    answersLoading: false,
    sendingAnswers: false,
    createLoading: false,
    list: [],
    listLoading: false,
    error: ""
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SEND_BURNOUT_ANSWERS_REQUESTED:
            return {
                ...state,
                sendingAnswers: true
            };
        case types.SEND_BURNOUT_ANSWERS_SUCCEEDED:
            return {
                ...state,
                sendingAnswers: false
            };
        case types.SEND_BURNOUT_ANSWERS_FAILED:
            return {
                ...state,
                sendingAnswers: false
            };
        case types.GET_BURNOUT_LIST_REQUESTED:
            return {
                ...state,
                list: [],
                listLoading: true
            };
        case types.GET_BURNOUT_LIST_SUCCEEDED:
            return {
                ...state,
                list: action.payload.data,
                listLoading: false
            };
        case types.GET_BURNOUT_LIST_FAILED:
            return {
                ...state,
                listLoading: false
            };
        case types.CREATE_BURNOUT_REQUESTED:
            return {
                ...state,
                createLoading: true
            };
        case types.CREATE_BURNOUT_SUCCEEDED:
            return {
                ...state,
                list: [action.payload.data, ...state.list],
                createLoading: false
            };
        case types.CREATE_BURNOUT_FAILED:
            return {
                ...state,
                error: action.payload,
                createLoading: false
            };
        case types.GET_BURNOUT_CATEGORIES_AND_QUESTIONS_REQUESTED:
            return {
                ...state,
                error: "",
                categoriesAndQuestionsLoading: true
            };
        case types.GET_BURNOUT_CATEGORIES_AND_QUESTIONS_SUCCEEDED:
            return {
                ...state,
                categoriesAndQuestions: action.payload.data,
                categoriesAndQuestionsLoading: false
            };
        case types.GET_BURNOUT_CATEGORIES_AND_QUESTIONS_FAILED:
            return {
                ...state,
                error: action.payload,
                categoriesAndQuestionsLoading: false
            };
        case types.GET_BURNOUT_ANSWERS_REQUESTED:
            return {
                ...state,
                answersLoading: true
            };
        case types.GET_BURNOUT_ANSWERS_SUCCEEDED:
            return {
                ...state,
                answers: action.payload.data,
                answersLoading: false
            };
        case types.GET_BURNOUT_ANSWERS_FAILED:
            return {
                ...state,
                error: action.payload,
                answersLoading: false
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(
        types.GET_BURNOUT_CATEGORIES_AND_QUESTIONS_REQUESTED,
        getCategoriesAndQuestionsWorker
    );
    yield takeLatest(types.GET_BURNOUT_ANSWERS_REQUESTED, getAnswersWorker);
    yield takeLatest(types.CREATE_BURNOUT_REQUESTED, createWorker);
    yield takeLatest(types.GET_BURNOUT_LIST_REQUESTED, getListWorker);
    yield takeLatest(types.SEND_BURNOUT_ANSWERS_REQUESTED, sendAnswersWorker);
}

// Saga callback

function* getCategoriesAndQuestionsWorker({ callback }) {
    try {
        const response = yield call(getCategoriesAndQuestions);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_BURNOUT_CATEGORIES_AND_QUESTIONS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_BURNOUT_CATEGORIES_AND_QUESTIONS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getAnswersWorker({ burnoutId, callback }) {
    try {
        const response = yield call(getAnswers, { burnoutId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_BURNOUT_ANSWERS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_BURNOUT_ANSWERS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* createWorker({ callback }) {
    try {
        const response = yield call(create);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.CREATE_BURNOUT_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.CREATE_BURNOUT_FAILED,
            payload: "Error"
        });
        callFail(callback, e.errors);
    }
}

function* getListWorker({ callback }) {
    try {
        const response = yield call(getList);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_BURNOUT_LIST_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_BURNOUT_LIST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* sendAnswersWorker({ burnoutId, categoryId, answers, callback }) {
    try {
        const response = yield call(sendAnswers, {
            burnoutId,
            categoryId,
            answers
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEND_BURNOUT_ANSWERS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SEND_BURNOUT_ANSWERS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function getCategoriesAndQuestions() {
    return api.get("/api/burnout/questions");
}

function getAnswers({ burnoutId }) {
    return api.get(`/api/burnout/answers/${burnoutId}`);
}

function create() {
    return api.post(`/api/burnout/create`);
}

function getList() {
    return api.get(`/api/burnout/list`);
}

function sendAnswers({ burnoutId, categoryId, answers }) {
    return api.put(`/api/burnout/update`, {
        burnout_test_id: burnoutId,
        category_id: categoryId,
        answers: answers
    });
}
