import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";

// Actions
const types = {
    GET_INTERVIEWS_REQUESTED: "GET_INTERVIEWS_REQUESTED",
    GET_INTERVIEWS_SUCCEEDED: "GET_INTERVIEWS_SUCCEEDED",
    GET_INTERVIEWS_FAILED: "GET_INTERVIEWS_FAILED",
    COMPLETE_INTERVIEWS_REQUESTED: "COMPLETE_INTERVIEWS_REQUESTED",
    COMPLETE_INTERVIEWS_SUCCEEDED: "COMPLETE_INTERVIEWS_SUCCEEDED",
    COMPLETE_INTERVIEWS_FAILED: "COMPLETE_INTERVIEWS_FAILED",
    START_INTERVIEWS_REQUESTED: "START_INTERVIEWS_REQUESTED",
    START_INTERVIEWS_SUCCEEDED: "START_INTERVIEWS_SUCCEEDED",
    START_INTERVIEWS_FAILED: "START_INTERVIEWS_FAILED"
};

// Action Creators
export const actions = {
    getInterviews: () => {
        return {
            type: types.GET_INTERVIEWS_REQUESTED
        };
    },
    completeInterview: (interviewCode, callback) => {
        return {
            type: types.COMPLETE_INTERVIEWS_REQUESTED,
            interviewCode,
            callback
        };
    },
    startInterview: interviewCode => {
        return {
            type: types.START_INTERVIEWS_REQUESTED,
            interviewCode
        };
    }
};

// Dedault state
const defaultState = {
    data: [],
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_INTERVIEWS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_INTERVIEWS_SUCCEEDED:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        case types.GET_INTERVIEWS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_INTERVIEWS_REQUESTED, getInterviewsWorker);
    yield takeLatest(
        types.COMPLETE_INTERVIEWS_REQUESTED,
        completeInterviewsWorker
    );
    yield takeLatest(types.START_INTERVIEWS_REQUESTED, startInterviewsWorker);
}

// Saga callback
function* getInterviewsWorker({ callback }) {
    try {
        const response = yield call(getInterviews);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_INTERVIEWS_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_INTERVIEWS_FAILED, payload: e.error });
    }
}

function* completeInterviewsWorker({ interviewCode, callback }) {
    try {
        const response = yield call(completeInterview, { interviewCode });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.COMPLETE_INTERVIEWS_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.COMPLETE_INTERVIEWS_FAILED, payload: e.error });
    }
}

function* startInterviewsWorker({ interviewCode, callback }) {
    try {
        const response = yield call(startInterview, { interviewCode });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.START_INTERVIEWS_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.START_INTERVIEWS_FAILED, payload: e.error });
    }
}

function getInterviews() {
    return api.get(`/api/subject/interviews`);
}

function completeInterview({ interviewCode }) {
    return api.get(`/api/interviews/complete/${interviewCode}`);
}

function startInterview({ interviewCode }) {
    return api.get(`/api/interviews/start/${interviewCode}`);
}
