import { useSelector, useDispatch } from "react-redux";

import userStore from "Store/User";
import hasRole from "AdminUtil/hasRole";

const useUser = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const loading = useSelector(state => state.user.loading);

    const isAdmin = () => {
        return hasRole(user, ["super_admin", "tdl_admin", "admin"]);
    };

    const isSuperAdmin = () => {
        return hasRole(user, ["super_admin", "tdl_admin"]);
    };

    const isNotAdmin = () => {
        return !hasRole(user, ["super_admin", "tdl_admin", "admin"]);
    };

    const isAuth = () => {
        return !!user.auth;
    };

    const logout = callback => {
        dispatch(userStore.actions.logoutUser(callback));
    };

    const userLoading = () => {
        return !!loading;
    };

    const getSession = key => {
        if (user.data && user.data.session) {
            return user.data.session[key];
        }

        return null;
    };

    const setSession = (key, value) => {
        dispatch(userStore.actions.updateSession(key, value));
    };

    return {
        userLoading,
        isAdmin,
        isSuperAdmin,
        isNotAdmin,
        isAuth,
        getSession,
        setSession,
        logout
    };
};

export default useUser;
