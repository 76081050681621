import React from "react";
import { Container, Segment } from "semantic-ui-react";
import { useRouteMatch } from "react-router-dom";

import PlaceholderContent from "../PlaceholderContent/PlaceholderContent";
import Nav from "Components/Nav/Nav";
import AdminNav from "AdminComponents/Nav/Nav";
import useIframe from "Util/iframe";

const PlaceholderPage = () => {
    const { isIframe } = useIframe();
    let match = useRouteMatch("/admin");
    let nav = <Nav />;
    if (match) {
        nav = <AdminNav />;
    }

    return (
        <>
            {isIframe() ? null : nav}
            <Container className="main-container">
                <Segment padded>
                    <PlaceholderContent />
                </Segment>
            </Container>
        </>
    );
};

export default PlaceholderPage;
