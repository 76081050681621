import { takeLatest, call, put } from "redux-saga/effects";
import { callSuccess, callFail } from "Util/callback";

import api from "Util/api";

// Actions
const types = {
    IQ_SET_ANALOGY_ANSWER: "IQ_SET_ANALOGY_ANSWER",
    IQ_CONFIRM_ANALOGY_ANSWER_REQUESTED: "IQ_CONFIRM_ANALOGY_ANSWER_REQUESTED",
    IQ_CONFIRM_ANALOGY_ANSWER_SUCCEEDED: "IQ_CONFIRM_ANALOGY_ANSWER_SUCCEEDED",
    IQ_CONFIRM_ANALOGY_ANSWER_FAILED: "IQ_CONFIRM_ANALOGY_ANSWER_FAILED",
    IQ_CLEAR_ANALOGY_ANSWERS: "IQ_CLEAR_ANALOGY_ANSWERS",
    IQ_COMPLETE_ANALOGY: "IQ_COMPLETE_ANALOGY"
};

// Action Creators
export const actions = {
    setAnswer: (optionId, questionId, questionType) => {
        return {
            type: types.IQ_SET_ANALOGY_ANSWER,
            optionId,
            questionId,
            questionType
        };
    },
    confirmAnswer: (answer, callback) => {
        return {
            type: types.IQ_CONFIRM_ANALOGY_ANSWER_REQUESTED,
            answer,
            callback
        };
    },
    clearAnswers: () => {
        return {
            type: types.IQ_CLEAR_ANALOGY_ANSWERS
        };
    },
    complete: callback => {
        return {
            type: types.IQ_COMPLETE_ANALOGY,
            callback
        };
    }
};

// Default state
const defaultState = {
    answers: {},
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.IQ_SET_ANALOGY_ANSWER:
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [action.questionId]: {
                        questionId: action.questionId,
                        optionId: action.optionId,
                        type: action.questionType,
                        confirmed: false
                    }
                }
            };
        case types.IQ_CONFIRM_ANALOGY_ANSWER_REQUESTED: {
            return {
                ...state,
                loading: true
            };
        }
        case types.IQ_CONFIRM_ANALOGY_ANSWER_SUCCEEDED: {
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [action.answer.questionId]: action.answer
                },
                loading: false
            };
        }
        case types.IQ_CONFIRM_ANALOGY_ANSWER_FAILED: {
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        }
        case types.IQ_CLEAR_ANALOGY_ANSWERS: {
            return {
                ...state,
                answers: {}
            };
        }
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.IQ_CONFIRM_ANALOGY_ANSWER_REQUESTED,
        confirmAnswerWorker
    );
    yield takeLatest(types.IQ_COMPLETE_ANALOGY, completeWorker);
}

// Saga callback
function* confirmAnswerWorker({ answer, callback }) {
    try {
        answer = { ...answer, confirmed: true };
        const response = yield call(confirmAnswer, { answer });

        if (!response.ok) {
            throw response.data;
        }

        let data = response.data;

        yield put({
            type: types.IQ_CONFIRM_ANALOGY_ANSWER_SUCCEEDED,
            answer
        });

        callSuccess(callback, data);
    } catch (e) {
        yield put({
            type: types.IQ_CONFIRM_ANALOGY_ANSWER_FAILED,
            payload: e.message
        });
        callFail(callback, e.errors);
    }
}

function* completeWorker({ callback }) {
    try {
        const response = yield call(complete);

        if (!response.ok) {
            throw response.data;
        }

        callSuccess(callback);
    } catch (e) {
        console.log(e);
        callFail(callback, e.errors);
    }
}

// API call
function confirmAnswer({ answer }) {
    return api.post("/api/iq-test/answers/analogy", { ...answer });
}

function complete() {
    return api.put("/api/iq-test/complete/analogy");
}
