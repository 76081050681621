import { fork, all } from "redux-saga/effects";
import map from "lodash/map";

// User
import user from "./User";

// Personality Test
import personalityQuestions from "./personality/Questions";
import personalityScales from "./personality/Scales";
import personalityAnswers from "./personality/Answers";
import personalityResults from "./personality/Results";
import personalityTranslations from "./personality/Translations";

// Culture Test
import cultureQuestion from "./culture/Question";
import cultureAnswer from "./culture/Answer";
import cultureCategory from "./culture/Category";
import cultureResult from "./culture/Result";

// IQ Test
import iqtAnalogyAnswers from "./iq-test/analogy/Answers";
import iqtAnalogyQuestions from "./iq-test/analogy/Questions";
import iqtSequenceAnswers from "./iq-test/sequence/Answers";
import iqtSequenceQuestions from "./iq-test/sequence/Questions";
import iqtResult from "./iq-test/Result";

// Burnout
import burnout from "./Burnout";

// MCQTest
import mcqTest from "./MCQTest";

// Test
import test from "./Test";

//Profile
import profile from "./Profile";

// Resources
import countries from "./resources/Countries";
import degrees from "./resources/Degrees";
import majors from "./resources/Majors";

// Interview
import interview from "./Interview";

// Subject
import subject from "./Subject";

// Admin
import adminSagas from "./admin/sagas";

const combinedSagas = [
    user.saga,
    // Personality Test
    personalityQuestions.saga,
    personalityScales.saga,
    personalityAnswers.saga,
    personalityResults.saga,
    personalityTranslations.saga,
    // Culture Test
    cultureQuestion.saga,
    cultureAnswer.saga,
    cultureResult.saga,
    cultureCategory.saga,
    // IQ Test
    iqtAnalogyAnswers.saga,
    iqtAnalogyQuestions.saga,
    iqtSequenceAnswers.saga,
    iqtSequenceQuestions.saga,
    iqtResult.saga,
    // Burnout
    burnout.saga,
    // MCQTest
    mcqTest.saga,
    // Test
    test.saga,
    // Profile
    profile.saga,
    // Resources
    countries.saga,
    degrees.saga,
    majors.saga,
    // Interview
    interview.saga,
    // Subject
    subject.saga,
    ...adminSagas
];

export default function* root() {
    yield all(map(combinedSagas, fork));
}
