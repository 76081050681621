import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";

// Actions
const types = {
    GET_QUESTIONS_REQUESTED: "GET_QUESTIONS_REQUESTED",
    GET_QUESTIONS_SUCCEEDED: "GET_QUESTIONS_SUCCEEDED",
    GET_QUESTIONS_FAILED: "GET_QUESTIONS_FAILED",
    SET_CURRENT_QUESTION_INDEX: "SET_CURRENT_QUESTION_INDEX",
    GET_PERSONALITY_LANGUAGES_REQUESTED: "GET_PERSONALITY_LANGUAGES_REQUESTED",
    GET_PERSONALITY_LANGUAGES_SUCCEEDED: "GET_PERSONALITY_LANGUAGES_SUCCEEDED",
    GET_PERSONALITY_LANGUAGES_FAILED: "GET_PERSONALITY_LANGUAGES_FAILED"
};

// Action Creators
export const actions = {
    getQuestions: callback => {
        return {
            type: types.GET_QUESTIONS_REQUESTED,
            callback
        };
    },
    setCurrentQuestionIndex: index => {
        return {
            type: types.SET_CURRENT_QUESTION_INDEX,
            index
        };
    },
    getLanguages: callback => {
        return {
            type: types.GET_PERSONALITY_LANGUAGES_REQUESTED,
            callback
        };
    }
};

// Default state
const defaultState = {
    currentQuestionIndex: null,
    questions: [],
    languages: [],
    questionsCountTotal: 0,
    loading: false,
    requested: false,
    error: ""
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_QUESTIONS_REQUESTED:
            return {
                ...state,
                loading: true,
                requested: true
            };
        case types.GET_QUESTIONS_SUCCEEDED:
            const { questions, questionsCountTotal } = action.payload;
            return {
                ...state,
                questions,
                questionsCountTotal,
                loading: false,
                error: ""
            };
        case types.GET_QUESTIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case types.SET_CURRENT_QUESTION_INDEX:
            return {
                ...state,
                currentQuestionIndex: action.index
            };
        case types.GET_PERSONALITY_LANGUAGES_SUCCEEDED:
            return {
                ...state,
                languages: action.payload
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_QUESTIONS_REQUESTED, getQuestionsWorker);
    yield takeLatest(
        types.GET_PERSONALITY_LANGUAGES_REQUESTED,
        getLanguagesWorker
    );
}

// Saga callback
function* getQuestionsWorker({ callback }) {
    try {
        const { data } = yield call(getQuestions);
        let questionsCountTotal = 0;
        let questions = [];
        if (data) {
            let index = 0;
            questionsCountTotal =
                data.questionsCountTotal + data.tendencyQuestionsCountTotal;
            let typedQuestions = data.questions.map(question => {
                let preparedQuestion = {
                    ...question,
                    index,
                    type: "5-factor"
                };
                index++;

                return preparedQuestion;
            });
            let typedTendencyQuestions = data.tendencyQuestions.map(
                question => {
                    let preparedQuestion = {
                        ...question,
                        index,
                        type: "tendency"
                    };
                    index++;

                    return preparedQuestion;
                }
            );
            questions = typedQuestions.concat(typedTendencyQuestions);
        }

        yield put({
            type: types.GET_QUESTIONS_SUCCEEDED,
            payload: {
                questions,
                questionsCountTotal
            }
        });

        typeof callback == "function" && callback(questions.length, questions);
    } catch (e) {
        console.log(e);
        yield put({ type: types.GET_QUESTIONS_FAILED, payload: e.message });
    }
}

function* getLanguagesWorker({ callback }) {
    try {
        const response = yield call(getLanguages);

        if (!response.ok) {
            throw response.data;
        }

        let data = response.data;

        yield put({
            type: types.GET_PERSONALITY_LANGUAGES_SUCCEEDED,
            payload: data
        });

        typeof callback == "function" && callback(data);
    } catch (e) {
        yield put({
            type: types.GET_PERSONALITY_LANGUAGES_FAILED,
            payload: e.message
        });
    }
}

// API call
function getQuestions() {
    return api.get("/api/personality-test/questions");
}

function getLanguages() {
    return api.get("/api/personality-test/languages");
}
