import { takeLatest, call, put } from "redux-saga/effects";

import { callSuccess, callFail } from "Util/callback";
import api from "Util/api";

// Actions
const types = {
    GET_IQ_TEST_RESULTS_REQUESTED: "GET_IQ_TEST_RESULTS_REQUESTED",
    GET_IQ_TEST_RESULTS_SUCCEEDED: "GET_IQ_TEST_RESULTS_SUCCEEDED",
    GET_IQ_TEST_RESULTS_FAILED: "GET_IQ_TEST_RESULTS_FAILED"
};

// Action Creators
export const actions = {
    get: callback => {
        return {
            type: types.GET_IQ_TEST_RESULTS_REQUESTED,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_IQ_TEST_RESULTS_REQUESTED:
            return {
                data: action.results,
                loading: true
            };
        case types.GET_IQ_TEST_RESULTS_SUCCEEDED:
            return {
                data: action.data,
                error: "",
                loading: false
            };
        case types.GET_IQ_TEST_RESULTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_IQ_TEST_RESULTS_REQUESTED, getResultsWorker);
}

// Saga callback
function* getResultsWorker({ callback }) {
    try {
        const response = yield call(getResults);

        yield put({
            type: types.GET_IQ_TEST_RESULTS_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_IQ_TEST_RESULTS_FAILED,
            payload: e.message
        });
        callFail(callback, e.errors);
    }
}

// API call
function getResults() {
    return api.get("/api/iq-test/results");
}
