import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";

const types = {
    GET_DASHBOARD_DETAILS_REQUESTED: "GET_DASHBOARD_DETAILS_REQUESTED",
    GET_DASHBOARD_DETAILS_SUCCEEDED: "GET_DASHBOARD_DETAILS_SUCCEEDED",
    GET_DASHBOARD_DETAILS_FAILED: "GET_DASHBOARD_DETAILS_FAILED"
};

export const actions = {
    getDasboardDetails: (label, createdAt, callback) => {
        return {
            type: types.GET_DASHBOARD_DETAILS_REQUESTED,
            label,
            createdAt,
            callback
        };
    }
};

const defaultState = {
    dashboardDetails: {},
    loading: true,
    error: "",
    inactive: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_DASHBOARD_DETAILS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_DASHBOARD_DETAILS_SUCCEEDED:
            return {
                ...state,
                dashboardDetails: action.dashboardDetails,
                loading: false
            };
        case types.GET_DASHBOARD_DETAILS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
                inactive: action.payload
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(
        types.GET_DASHBOARD_DETAILS_REQUESTED,
        startGetDashboardDetailsWorker
    );
}

// Saga callback
function* startGetDashboardDetailsWorker({ label, createdAt, callback }) {
    try {
        const response = yield call(getDashboard, { label, createdAt });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_DASHBOARD_DETAILS_SUCCEEDED,
            dashboardDetails: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_DASHBOARD_DETAILS_FAILED,
            payload: e.inactive
        });
    }
}

function getDashboard({ label, createdAt }) {
    let url = "/api/admin/campaigns/dashboard";
    let first = true;

    if (label && parseInt(label)) {
        url = url + (first ? "?" : "&") + `label=${label}`;
        first = false;
    }

    if (createdAt && createdAt != "null,null") {
        url = url + (first ? "?" : "&") + `createdAt=${createdAt}`;
        first = false;
    }

    return api.get(url);
}
