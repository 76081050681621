import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "./routes";

const Home = lazy(() => import("./Home"));
const Login = lazy(() => import("./Login"));
const Register = lazy(() => import("./Register"));
const ConfirmRegister = lazy(() => import("./ConfirmRegister"));
const ChoosePassword = lazy(() => import("./ChoosePassword"));
const PasswordlessLogin = lazy(() => import("./PasswordlessLogin"));
const PersonalityTest = lazy(() => import("./personality/Test"));
const PersonalityResults = lazy(() => import("./personality/Results"));
const CultureTest = lazy(() => import("./culture/Test"));
const CultureResults = lazy(() => import("./culture/Results"));
const MCQTestTest = lazy(() => import("./mcqTest/Test"));
const MCQTestResults = lazy(() => import("./mcqTest/Results"));
const MCQTestList = lazy(() => import("./mcqTest/List"));
const BurnoutTest = lazy(() => import("./burnout/Test"));
const BurnoutIntro = lazy(() => import("./burnout/Intro"));
const BurnoutResults = lazy(() => import("./burnout/Results"));
const GivePermission = lazy(() => import("./GivePermission"));
const Unsubscribe = lazy(() => import("./Unsubscribe"));
const NotFound = lazy(() => import("./NotFound"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const VideoInterview = lazy(() => import("./video/Interview"));
const VideoInterviewList = lazy(() => import("./video/List"));
const VideoInterviewDone = lazy(() => import("./video/Done"));
const VideoInterviewFAQ = lazy(() => import("./video/FAQ"));
const AssessmentsFaq = lazy(() => import("./AssessmentsFaq"));
const Profile = lazy(() => import("./profile"));
const NewProfile = lazy(() => import("./profile/NewProfile"));
const EditProfile = lazy(() => import("./profile/EditProfile"));
import * as Sentry from "@sentry/react";

import adminPages from "AdminPages/Pages";
import iqTestPages from "./iq-test/Pages";
import AuthRoute from "Util/AuthRoute";
import ErrorBoundary from "Util/ErrorBoundary";
import PlaceholderPage from "Components/PlaceholderPage/PlaceholderPage";

const Pages = () => (
    <ErrorBoundary>
        <Suspense fallback={<PlaceholderPage />}>
            <Switch>
                <Route path={routes.UNSUBSCRIBE} component={Unsubscribe} />
                <Route
                    path={routes.FORGOT_PASSWORD}
                    component={ForgotPassword}
                />
                <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
                <Route
                    path={routes.GIVE_PERMISSION}
                    component={GivePermission}
                />
                <Route
                    path={routes.PASSWORDLESS_LOGIN}
                    component={PasswordlessLogin}
                />
                <Route
                    path={routes.PASSWORDLESS_LOGIN_2}
                    component={PasswordlessLogin}
                />
                <Route path={routes.LOGIN} component={Login} />
                <Route path={routes.REGISTER} component={Register} />
                <Route
                    path={routes.CONFIRM_REGISTER}
                    component={ConfirmRegister}
                />
                <Route
                    path={routes.CHOOSE_PASSWORD}
                    component={ChoosePassword}
                />
                <AuthRoute path={routes.PROFILE} component={Profile} />
                <AuthRoute path={routes.NEW_PROFILE} component={NewProfile} />
                <AuthRoute path={routes.EDIT_PROFILE} component={EditProfile} />
                <AuthRoute
                    path={routes.VIDEO_INTERVIEW_START}
                    component={VideoInterview}
                />
                <AuthRoute
                    path={routes.VIDEO_INTERVIEW_DONE}
                    component={VideoInterviewDone}
                />
                <AuthRoute
                    path={routes.VIDEO_INTERVIEW_LIST}
                    component={VideoInterviewList}
                />
                <AuthRoute
                    path={routes.VIDEO_INTERVIEW_FAQ}
                    component={VideoInterviewFAQ}
                />
                <AuthRoute
                    path={routes.ASSESSMENTS_FAQ}
                    component={AssessmentsFaq}
                />
                <AuthRoute
                    path={routes.PERSONALITY_TEST}
                    component={PersonalityTest}
                />
                <AuthRoute path={routes.CULTURE_TEST} component={CultureTest} />
                <AuthRoute
                    path={routes.CULTURE_RESULTS}
                    component={CultureResults}
                />
                <AuthRoute path={routes.BURNOUT_TEST} component={BurnoutTest} />
                <AuthRoute
                    path={routes.BURNOUT_INTRO}
                    component={BurnoutIntro}
                />
                <AuthRoute
                    path={routes.BURNOUT_RESULTS}
                    component={BurnoutResults}
                />
                <AuthRoute path={routes.MCQTEST_TEST} component={MCQTestTest} />
                <AuthRoute
                    path={routes.MCQTEST_TEST_RESULTS}
                    component={MCQTestResults}
                />
                <AuthRoute
                    path={routes.MCQTEST_TEST_LIST}
                    component={MCQTestList}
                />
                <AuthRoute
                    path={routes.PERSONALITY_RESULTS}
                    component={PersonalityResults}
                />
                {iqTestPages}
                {adminPages}
                <Route path={routes.OPEN_INVITE} component={Home} />
                <Route exact path={routes.HOME} component={Home} />
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    </ErrorBoundary>
);

export default Sentry.withProfiler(Pages);
