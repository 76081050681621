import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";

// Actions
const types = {
    GET_LOGS_REQUESTED: "GET_LOGS_REQUESTED",
    GET_LOGS_SUCCEEDED: "GET_LOGS_SUCCEEDED",
    GET_LOGS_FAILED: "GET_LOGS_FAILED"
};

// Action Creators
export const actions = {
    getLogs: (page = 1, callback) => {
        return {
            type: types.GET_LOGS_REQUESTED,
            page,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_LOGS_SUCCEEDED:
            return {
                error: "",
                loading: false
            };
        case types.GET_LOGS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_LOGS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_LOGS_REQUESTED, getLogsWorker);
}

// Saga callback
function* getLogsWorker({ page, callback }) {
    try {
        const response = yield call(getLogs, { page });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_LOGS_SUCCEEDED
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({ type: types.GET_LOGS_FAILED, payload: e.errors });
    }
}

// API call
function getLogs({ page }) {
    return api.get(`/api/super-admin/action-logs?page=${page}`);
}
