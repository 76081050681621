import { takeLatest, call, put } from "redux-saga/effects";
import findIndex from "lodash/findIndex";
import { reset, stopSubmit } from "redux-form";

import extractErrorForForm from "Util/extractErrorForForm";
import api from "Util/api";

// Actions
const types = {
    GET_NOTE_REQUESTED: "GET_NOTE_REQUESTED",
    GET_NOTE_SUCCEEDED: "GET_NOTE_SUCCEEDED",
    GET_NOTE_FAILED: "GET_NOTE_FAILED",
    SAVE_NOTE_REQUESTED: "SAVE_NOTE_REQUESTED",
    SAVE_NOTE_SUCCEEDED: "SAVE_NOTE_SUCCEEDED",
    SAVE_NOTE_FAILED: "SAVE_NOTE_FAILED",
    DELETE_NOTE_REQUESTED: "DELETE_NOTE_REQUESTED",
    DELETE_NOTE_SUCCEEDED: "DELETE_NOTE_SUCCEEDED",
    DELETE_NOTE_FAILED: "DELETE_NOTE_FAILED"
};

// Action Creators
export const actions = {
    getNotes: (subjectId, callback) => {
        return {
            type: types.GET_NOTE_REQUESTED,
            subjectId,
            callback
        };
    },
    saveNote: (subjectId, values, callback) => {
        return {
            type: types.SAVE_NOTE_REQUESTED,
            subjectId,
            values,
            callback
        };
    },
    deleteNote: (noteId, callback) => {
        return {
            type: types.DELETE_NOTE_REQUESTED,
            noteId,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    data: [],
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_NOTE_SUCCEEDED:
            return {
                data: action.data,
                error: "",
                loading: false
            };
        case types.SAVE_NOTE_SUCCEEDED:
            return {
                data: [action.data, ...state.data],
                error: "",
                loading: false
            };
        case types.DELETE_NOTE_SUCCEEDED:
            const notes = state.data;
            const noteId = action.data;
            const index = findIndex(notes, {
                id: noteId
            });
            let newNotes = [...notes];
            if (index != -1) {
                if (newNotes.length > 1) {
                    newNotes.splice(index, 1);
                } else {
                    newNotes = [];
                }
            }

            return {
                data: newNotes,
                error: "",
                loading: false
            };
        case types.GET_NOTE_REQUESTED:
        case types.SAVE_NOTE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_NOTE_FAILED:
        case types.SAVE_NOTE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_NOTE_REQUESTED, getNoteWorker);
    yield takeLatest(types.SAVE_NOTE_REQUESTED, saveNoteWorker);
    yield takeLatest(types.DELETE_NOTE_REQUESTED, deleteNoteWorker);
}

// Saga callback
function* getNoteWorker({ subjectId, callback }) {
    try {
        const response = yield call(getNotes, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_NOTE_SUCCEEDED,
            data: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({ type: types.GET_NOTE_FAILED, payload: e.errors });
    }
}

function* saveNoteWorker({ subjectId, values, callback }) {
    try {
        const response = yield call(saveNote, { subjectId, values });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_NOTE_SUCCEEDED,
            data: response.data.data
        });

        yield put(reset("note"));

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.SAVE_NOTE_FAILED, payload: e.errors });
        const formError = extractErrorForForm(e.errors);
        yield put(stopSubmit("note", formError));
    }
}

function* deleteNoteWorker({ noteId, callback }) {
    try {
        const response = yield call(deleteNote, { noteId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_NOTE_SUCCEEDED,
            data: noteId
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e);
        yield put({ type: types.DELETE_NOTE_FAILED, payload: e.errors });
    }
}
// API call
function getNotes({ subjectId }) {
    return api.get(`/api/admin/tests/notes/${subjectId}`);
}

function saveNote({ subjectId, values }) {
    return api.post(`/api/admin/tests/notes/${subjectId}`, values);
}

function deleteNote({ noteId }) {
    return api.delete(`/api/admin/tests/notes/${noteId}`);
}
