import React from "react";
import * as Sentry from "@sentry/react";

import { Header } from "semantic-ui-react";
import styled from "styled-components";

const Page = styled.div`
    padding: 160px 20px 0;
    text-align: center;
`;

function setWithExpiry(key, value, ttl) {
    const item = {
        value: value,
        expiry: new Date().getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

const FallbackComponent = () => {
    return (
        <Page>
            <Header as="h1">It looks like we are having issues.</Header>
            <div>
                You have encountered an error. Our team has been notified.{" "}
            </div>
            Go to <a href="/">Homepage</a>
        </Page>
    );
};

const ErrorBoundary = ({ children }) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error }) => {
                // Handle failed lazy loading of a JS/CSS chunk.
                const chunkFailedMessage = /Loading chunk [\d]+ failed/;
                if (
                    error &&
                    error.message &&
                    chunkFailedMessage.test(error.message)
                ) {
                    if (!getWithExpiry("chunk_failed")) {
                        setWithExpiry("chunk_failed", "true", 10000);
                        console.log("Chunk load failed. Refreshing...");
                        window.location.reload();
                    } else {
                        return <FallbackComponent />;
                    }
                } else {
                    return <FallbackComponent />;
                }
            }}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};

export default ErrorBoundary;
