import React, { lazy } from "react";

import AuthRoute from "Util/AuthRoute";

const Welcome = lazy(() => import("./Welcome"));
const Results = lazy(() => import("./Results"));
const AnalogyInstructions = lazy(() => import("./analogy/Instructions"));
const AnalogyTest = lazy(() => import("./analogy/Test"));
const AnalogyTimer = lazy(() => import("./analogy/Timer"));
const AnalogyExpired = lazy(() => import("./analogy/Expired"));
const SequenceInstructions = lazy(() => import("./sequence/Instructions"));
const SequenceTest = lazy(() => import("./sequence/Test"));
const SequenceTimer = lazy(() => import("./sequence/Timer"));
const SequenceExpired = lazy(() => import("./sequence/Expired"));

import routes from "Pages/routes";

const iqTest = [
    <AuthRoute
        key={routes.IQ_TEST_ANALOGY_TEST}
        path={routes.IQ_TEST_ANALOGY_TEST}
        role=""
        component={AnalogyTest}
    />,
    <AuthRoute
        key={routes.IQ_TEST_ANALOGY_INSTRUCTIONS}
        path={routes.IQ_TEST_ANALOGY_INSTRUCTIONS}
        role=""
        component={AnalogyInstructions}
    />,
    <AuthRoute
        key={routes.IQ_TEST_SEQUENCE_TEST}
        path={routes.IQ_TEST_SEQUENCE_TEST}
        role=""
        component={SequenceTest}
    />,
    <AuthRoute
        key={routes.IQ_TEST_SEQUENCE_INSTRUCTIONS}
        path={routes.IQ_TEST_SEQUENCE_INSTRUCTIONS}
        role=""
        component={SequenceInstructions}
    />,
    <AuthRoute
        key={routes.IQ_TEST_SEQUENCE_TIMER}
        path={routes.IQ_TEST_SEQUENCE_TIMER}
        role=""
        component={SequenceTimer}
    />,
    <AuthRoute
        key={routes.IQ_TEST_ANALOGY_TIMER}
        path={routes.IQ_TEST_ANALOGY_TIMER}
        role=""
        component={AnalogyTimer}
    />,
    <AuthRoute
        key={routes.IQ_TEST_SEQUENCE_EXPIRED}
        path={routes.IQ_TEST_SEQUENCE_EXPIRED}
        role=""
        component={SequenceExpired}
    />,
    <AuthRoute
        key={routes.IQ_TEST_ANALOGY_EXPIRED}
        path={routes.IQ_TEST_ANALOGY_EXPIRED}
        role=""
        component={AnalogyExpired}
    />,
    <AuthRoute
        key={routes.IQ_TEST_RESULTS}
        path={routes.IQ_TEST_RESULTS}
        role=""
        component={Results}
    />,
    <AuthRoute
        key={routes.IQ_TEST_WELCOME}
        path={routes.IQ_TEST_WELCOME}
        role=""
        component={Welcome}
    />
];

export default iqTest;
