import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_ORGANIZATION_CULTURE_RESULTS_REQUESTED:
        "GET_ORGANIZATION_CULTURE_RESULTS_REQUESTED",
    GET_ORGANIZATION_CULTURE_RESULTS_SUCCEEDED:
        "GET_ORGANIZATION_CULTURE_RESULTS_SUCCEEDED",
    GET_ORGANIZATION_CULTURE_RESULTS_FAILED:
        "GET_ORGANIZATION_CULTURE_RESULTS_FAILED",
    GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_REQUESTED:
        "GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_REQUESTED",
    GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_SUCCEEDED:
        "GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_SUCCEEDED",
    GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_FAILED:
        "GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_FAILED"
};

// Action Creators
export const actions = {
    get: (testId, callback) => {
        return {
            type: types.GET_ORGANIZATION_CULTURE_RESULTS_REQUESTED,
            testId,
            callback
        };
    },
    getForAdmin: (organizationId, testId, callback) => {
        return {
            type: types.GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_REQUESTED,
            organizationId,
            testId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: [],
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_ORGANIZATION_CULTURE_RESULTS_REQUESTED:
        case types.GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_ORGANIZATION_CULTURE_RESULTS_SUCCEEDED:
        case types.GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_SUCCEEDED:
            return {
                data: action.payload.data,
                error: "",
                loading: false
            };
        case types.GET_ORGANIZATION_CULTURE_RESULTS_FAILED:
        case types.GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.GET_ORGANIZATION_CULTURE_RESULTS_REQUESTED,
        getWorker
    );
    yield takeLatest(
        types.GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_REQUESTED,
        getForAdminWorker
    );
}

// Saga callback
function* getWorker({ testId, callback }) {
    try {
        const response = yield call(get, { testId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATION_CULTURE_RESULTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_ORGANIZATION_CULTURE_RESULTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getForAdminWorker({ testId, organizationId, callback }) {
    try {
        const response = yield call(getForAdmin, { testId, organizationId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_ORGANIZATION_CULTURE_ADMIN_RESULTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get({ testId }) {
    return api.get(`/api/admin/culture-test/${testId}/results`);
}

function getForAdmin({ organizationId, testId }) {
    return api.get(
        `/api/super-admin/organizations/results/${organizationId}/${testId}`
    );
}
