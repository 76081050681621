import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

// User
import user from "./User";

// Personality Test
import personalityQuestions from "./personality/Questions";
import personalityScales from "./personality/Scales";
import personalityAnswers from "./personality/Answers";
import personalityResults from "./personality/Results";
import personalityTranslations from "./personality/Translations";

// CLuture Test
import cultureQuestion from "./culture/Question";
import cultureAnswer from "./culture/Answer";
import cultureCategory from "./culture/Category";
import cultureResult from "./culture/Result";

// IQ Test
import iqtAnalogyAnswers from "./iq-test/analogy/Answers";
import iqtAnalogyQuestions from "./iq-test/analogy/Questions";
import iqtSequenceAnswers from "./iq-test/sequence/Answers";
import iqtSequenceQuestions from "./iq-test/sequence/Questions";
import iqtResult from "./iq-test/Result";

// Burnout
import burnout from "./Burnout";

// MCQTest
import mcqTest from "./MCQTest";

// Test
import test from "./Test";

// Interview
import interview from "./Interview";

// Admin
import adminReducers from "./admin/reducers";

// Profile
import profile from "./Profile";

// Resources
import countries from "./resources/Countries";
import degrees from "./resources/Degrees";
import majors from "./resources/Majors";

// Settings
import settings from "./Settings";

// Subject
import subject from "./Subject";

export default combineReducers({
    form: formReducer,
    test: test.reducer,
    profile: profile.reducer,
    interview: interview.reducer,
    burnout: burnout.reducer,
    mcqTest: mcqTest.reducer,
    personality: combineReducers({
        questions: personalityQuestions.reducer,
        scales: personalityScales.reducer,
        answers: personalityAnswers.reducer,
        results: personalityResults.reducer,
        translations: personalityTranslations.reducer
    }),
    culture: combineReducers({
        question: cultureQuestion.reducer,
        answer: cultureAnswer.reducer,
        result: cultureResult.reducer,
        category: cultureCategory.reducer
    }),
    iqTest: combineReducers({
        analogyAnswers: iqtAnalogyAnswers.reducer,
        analogyQuestions: iqtAnalogyQuestions.reducer,
        sequenceAnswers: iqtSequenceAnswers.reducer,
        sequenceQuestions: iqtSequenceQuestions.reducer,
        result: iqtResult.reducer
    }),
    resources: combineReducers({
        majors: majors.reducer,
        countries: countries.reducer,
        degrees: degrees.reducer
    }),
    settings: settings.reducer,
    user: user.reducer,
    subject: subject.reducer,
    admin: adminReducers
});
