import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_SUCCESS_PROFILES_REQUESTED: "GET_SUCCESS_PROFILES_REQUESTED",
    GET_SUCCESS_PROFILES_SUCCEEDED: "GET_SUCCESS_PROFILES_SUCCEEDED",
    GET_SUCCESS_PROFILES_FAILED: "GET_SUCCESS_PROFILES_FAILED",
    CREATE_SUCCESS_PROFILE_REQUESTED: "CREATE_SUCCESS_PROFILE_REQUESTED",
    CREATE_SUCCESS_PROFILE_SUCCEEDED: "CREATE_SUCCESS_PROFILE_SUCCEEDED",
    CREATE_SUCCESS_PROFILE_FAILED: "CREATE_SUCCESS_PROFILE_FAILED",
    GET_SUCCESS_PROFILE_REQUESTED: "GET_SUCCESS_PROFILE_REQUESTED",
    GET_SUCCESS_PROFILE_SUCCEEDED: "GET_SUCCESS_PROFILE_SUCCEEDED",
    GET_SUCCESS_PROFILE_FAILED: "GET_SUCCESS_PROFILE_FAILED",
    REMOVE_SUCCESS_PROFILE_REVIEW_REQUESTED:
        "REMOVE_SUCCESS_PROFILE_REVIEW_REQUESTED",
    REMOVE_SUCCESS_PROFILE_REVIEW_SUCCEEDED:
        "REMOVE_SUCCESS_PROFILE_REVIEW_SUCCEEDED",
    REMOVE_SUCCESS_PROFILE_REVIEW_FAILED: "GET_SUCCESS_PROFILE_REVIEW_FAILED",
    REMOVE_SUCCESS_PROFILE_REQUESTED: "REMOVE_SUCCESS_PROFILE_REQUESTED",
    REMOVE_SUCCESS_PROFILE_SUCCEEDED: "REMOVE_SUCCESS_PROFILE_SUCCEEDED",
    REMOVE_SUCCESS_PROFILE_FAILED: "REMOVE_SUCCESS_PROFILE_FAILED",
    EDIT_SUCCESS_PROFILE_REQUESTED: "EDIT_SUCCESS_PROFILE_REQUESTED",
    EDIT_SUCCESS_PROFILE_SUCCEEDED: "EDIT_SUCCESS_PROFILE_SUCCEEDED",
    EDIT_SUCCESS_PROFILE_FAILED: "EDIT_SUCCESS_PROFILE_FAILED",
    GET_SUCCESS_PROFILE_REPORT_REQUESTED:
        "GET_SUCCESS_PROFILE_REPORT_REQUESTED",
    GET_SUCCESS_PROFILE_REPORT_SUCCEEDED:
        "GET_SUCCESS_PROFILE_REPORT_SUCCEEDED",
    GET_SUCCESS_PROFILE_REPORT_FAILED: "GET_SUCCESS_PROFILE_REPORT_FAILED",
    SAVE_SUCCESS_PROFILE_REQUESTED: "SAVE_SUCCESS_PROFILE_REQUESTED",
    SAVE_SUCCESS_PROFILE_SUCCEEDED: "SAVE_SUCCESS_PROFILE_SUCCEEDED",
    SAVE_SUCCESS_PROFILE_FAILED: "SAVE_SUCCESS_PROFILE_FAILED"
};

// Action Creators
export const actions = {
    getAll: callback => {
        return {
            type: types.GET_SUCCESS_PROFILES_REQUESTED,
            callback
        };
    },
    create: (data, callback) => {
        return {
            type: types.CREATE_SUCCESS_PROFILE_REQUESTED,
            data,
            callback
        };
    },
    get: (successProfileId, callback) => {
        return {
            type: types.GET_SUCCESS_PROFILE_REQUESTED,
            successProfileId,
            callback
        };
    },
    removeReview: (reviewId, callback) => {
        return {
            type: types.REMOVE_SUCCESS_PROFILE_REVIEW_REQUESTED,
            reviewId,
            callback
        };
    },
    remove: (successProfileId, callback) => {
        return {
            type: types.REMOVE_SUCCESS_PROFILE_REQUESTED,
            successProfileId,
            callback
        };
    },
    edit: (successProfileId, data, callback) => {
        return {
            type: types.EDIT_SUCCESS_PROFILE_REQUESTED,
            successProfileId,
            data,
            callback
        };
    },
    getReport: (successProfileId, callback) => {
        return {
            type: types.GET_SUCCESS_PROFILE_REPORT_REQUESTED,
            successProfileId,
            callback
        };
    },
    save: (successProfileId, callback) => {
        return {
            type: types.SAVE_SUCCESS_PROFILE_REQUESTED,
            successProfileId,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    single: {},
    singleLoading: false,
    list: [],
    listLoading: false,
    report: {},
    reportLoading: false,
    error: "",
    reportError: "",
    saving: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SAVE_SUCCESS_PROFILE_REQUESTED:
            return {
                ...state,
                saving: true
            };
        case types.SAVE_SUCCESS_PROFILE_SUCCEEDED:
        case types.SAVE_SUCCESS_PROFILE_FAILED:
            return {
                ...state,
                saving: false
            };
        case types.GET_SUCCESS_PROFILES_REQUESTED:
            return {
                ...state,
                list: [],
                error: "",
                listLoading: true
            };
        case types.GET_SUCCESS_PROFILES_SUCCEEDED:
            return {
                ...state,
                list: action.data,
                error: "",
                listLoading: false
            };
        case types.GET_SUCCESS_PROFILES_FAILED:
            return {
                ...state,
                list: [],
                error: action.payload,
                listLoading: false
            };
        case types.GET_SUCCESS_PROFILE_REQUESTED:
            return {
                ...state,
                single: {},
                error: "",
                singleLoading: true
            };
        case types.GET_SUCCESS_PROFILE_SUCCEEDED:
            return {
                ...state,
                single: action.data,
                error: "",
                singleLoading: false
            };
        case types.GET_SUCCESS_PROFILE_FAILED:
            return {
                ...state,
                single: {},
                error: action.payload,
                singleLoading: false
            };
        case types.GET_SUCCESS_PROFILE_REPORT_REQUESTED:
            return {
                ...state,
                report: {},
                reportError: "",
                reportLoading: true
            };
        case types.GET_SUCCESS_PROFILE_REPORT_SUCCEEDED:
            return {
                ...state,
                report: action.data,
                reportError: "",
                reportLoading: false
            };
        case types.GET_SUCCESS_PROFILE_REPORT_FAILED:
            return {
                ...state,
                report: {},
                reportError: action.payload,
                reportLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.GET_SUCCESS_PROFILES_REQUESTED,
        getSuccessProfilesWorker
    );
    yield takeLatest(types.CREATE_SUCCESS_PROFILE_REQUESTED, createWorker);
    yield takeLatest(types.GET_SUCCESS_PROFILE_REQUESTED, getWorker);
    yield takeLatest(
        types.REMOVE_SUCCESS_PROFILE_REVIEW_REQUESTED,
        removeReviewWorker
    );
    yield takeLatest(types.REMOVE_SUCCESS_PROFILE_REQUESTED, removeWorker);
    yield takeLatest(types.EDIT_SUCCESS_PROFILE_REQUESTED, editWorker);
    yield takeLatest(
        types.GET_SUCCESS_PROFILE_REPORT_REQUESTED,
        getReportWorker
    );
    yield takeLatest(types.SAVE_SUCCESS_PROFILE_REQUESTED, saveWorker);
}

// Saga callback
function* getSuccessProfilesWorker({ callback }) {
    try {
        const response = yield call(getAll);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUCCESS_PROFILES_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_SUCCESS_PROFILES_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* createWorker({ data, callback }) {
    try {
        const response = yield call(create, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.CREATE_SUCCESS_PROFILE_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.CREATE_SUCCESS_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ successProfileId, callback }) {
    try {
        const response = yield call(get, { successProfileId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUCCESS_PROFILE_SUCCEEDED,
            data: {
                ...response.data.data.report,
                reviews: response.data.data.reviews
            }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_SUCCESS_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* removeReviewWorker({ reviewId, callback }) {
    try {
        const response = yield call(removeReview, {
            reviewId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REMOVE_SUCCESS_PROFILE_REVIEW_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.REMOVE_SUCCESS_PROFILE_REVIEW_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* removeWorker({ successProfileId, callback }) {
    try {
        const response = yield call(remove, {
            successProfileId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.REMOVE_SUCCESS_PROFILE_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.REMOVE_SUCCESS_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* editWorker({ successProfileId, data, callback }) {
    try {
        const response = yield call(edit, {
            successProfileId,
            data
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EDIT_SUCCESS_PROFILE_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.EDIT_SUCCESS_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getReportWorker({ successProfileId, callback }) {
    try {
        const response = yield call(getReport, {
            successProfileId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUCCESS_PROFILE_REPORT_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_SUCCESS_PROFILE_REPORT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* saveWorker({ successProfileId, callback }) {
    try {
        const response = yield call(save, {
            successProfileId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_SUCCESS_PROFILE_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.SAVE_SUCCESS_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function getAll() {
    return api.get(`/api/admin/success-profiles/reports`);
}

function create({ data }) {
    let category = String(data.category).split(":");
    let transportData = {
        name: data.name,
        alt_category_id: 0,
        category_id: 0
    };

    if (category[0] == "alt") {
        transportData.alt_category_id = Number(category[1]);
    } else {
        transportData.category_id = Number(category[0]);
    }

    return api.post(`/api/admin/success-profiles/report`, transportData);
}

function get({ successProfileId }) {
    return api.get(`/api/admin/success-profiles/report/${successProfileId}`);
}

function removeReview({ reviewId }) {
    return api.delete(`/api/admin/reviews/delete/${reviewId}`);
}

function remove({ successProfileId }) {
    return api.delete(`/api/admin/success-profiles/delete/${successProfileId}`);
}

function edit({ successProfileId, data }) {
    let category = String(data.category).split(":");
    let transportData = {
        name: data.name,
        alt_category_id: 0,
        category_id: 0
    };

    if (category[0] == "alt") {
        transportData.alt_category_id = Number(category[1]);
    } else {
        transportData.category_id = Number(category[0]);
    }

    return api.put(
        `/api/admin/success-profiles/report/edit/${successProfileId}`,
        transportData
    );
}

function getReport({ successProfileId }) {
    return api.get(
        `/api/admin/success-profiles/report/correlation/${successProfileId}`
    );
}

function save({ successProfileId }) {
    return api.post(`/api/admin/success-profiles/report/save`, {
        report_id: successProfileId
    });
}
