import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class AuthRoute extends React.PureComponent {
    render() {
        const { component: Component, user, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={props => {
                    if (user.auth) {
                        return <Component {...props} />;
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location }
                                }}
                            />
                        );
                    }
                }}
            />
        );
    }
}

function mapStateToProps({ user }) {
    return {
        user
    };
}

export default connect(mapStateToProps)(AuthRoute);
