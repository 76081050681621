import { takeEvery, call, put } from "redux-saga/effects";

import api from "Util/api";

// Actions
const types = {
    GET_CULTURE_RESULT_REQUESTED: "GET_CULTURE_RESULT_REQUESTED",
    GET_CULTURE_RESULT_SUCCEEDED: "GET_CULTURE_RESULT_SUCCEEDED",
    GET_CULTURE_RESULT_FAILED: "GET_CULTURE_RESULT_FAILED"
};

// Action Creators
export const actions = {
    get: (subjectId, callback) => {
        return {
            type: types.GET_CULTURE_RESULT_REQUESTED,
            subjectId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_CULTURE_RESULT_REQUESTED:
            return {
                ...state,
                data: {},
                loading: true
            };
        case types.GET_CULTURE_RESULT_SUCCEEDED:
            return {
                ...state,
                data: action.result.data,
                loading: false
            };
        case types.GET_CULTURE_RESULT_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeEvery(
        types.GET_CULTURE_RESULT_REQUESTED,
        startGetTestResultWorker
    );
}

// Saga callback
function* startGetTestResultWorker({ subjectId, callback }) {
    try {
        const response = yield call(getTestResult, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CULTURE_RESULT_SUCCEEDED,
            result: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.error({ e });
        yield put({ type: types.GET_CULTURE_RESULT_FAILED, error: e.error });
    }
}

// API call
function getTestResult({ subjectId }) {
    return api.get(`/api/admin/results/culture-test/subject/${subjectId}`);
}
