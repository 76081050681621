import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import settingsStore from "Store/Settings";

const useIframe = () => {
    const location = useLocation();

    const q = new URLSearchParams(location.search);
    const iframeParam = q.get("iframe");

    const settings = useSelector(state => state.settings.data);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isIframe()) {
            dispatch(settingsStore.actions.set("iframe", true));
        }
    }, []);

    const isIframe = () => {
        return iframeParam != null || settings.iframe;
    };

    return {
        isIframe
    };
};

export default useIframe;
