const routes = {
    HOME: "/",
    LOGIN: "/login",
    REGISTER: "/register",
    CONFIRM_REGISTER: "/confirm-email/:hash",
    UNSUBSCRIBE: "/unsubscribe/:hash",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password/:hash",
    GIVE_PERMISSION: "/give-permission/:organizationId/:hash",
    CHOOSE_PASSWORD: "/create-account-test-subject/:hash/:organizationId",
    PASSWORDLESS_LOGIN: "/create-account-passwordless/:hash/:organizationId",
    PASSWORDLESS_LOGIN_2: "/login/passwordless/:hash/:organizationId",
    PROFILE: "/profile/display",
    NEW_PROFILE: "/profile/new/:test",
    EDIT_PROFILE: "/profile/edit",
    VIDEO_INTERVIEW_START: "/video/interview/start/:interviewCode",
    VIDEO_INTERVIEW_DONE: "/video/interview/done/:interviewCode",
    VIDEO_INTERVIEW_LIST: "/video/interview/list",
    VIDEO_INTERVIEW_FAQ: "/video/interview/faq",
    PERSONALITY_TEST: "/personality/test",
    PERSONALITY_RESULTS: "/personality/results",
    CULTURE_TEST: "/culture/test",
    CULTURE_RESULTS: "/culture/results",
    BURNOUT_TEST: "/happines-energy/test/:id",
    BURNOUT_RESULTS: "/happines-energy/results",
    BURNOUT_INTRO: "/happines-energy/intro",
    MCQTEST_TEST: "/skill/test/:id",
    MCQTEST_TEST_LIST: "/skill/tests",
    MCQTEST_TEST_RESULTS: "/skill/results/:mcqTestId",
    IQ_TEST_ANALOGY_INSTRUCTIONS: "/iq-test/analogy/instructions",
    IQ_TEST_ANALOGY_TEST: "/iq-test/analogy/test",
    IQ_TEST_ANALOGY_TIMER: "/iq-test/analogy/timer",
    IQ_TEST_ANALOGY_EXPIRED: "/iq-test/analogy/expired",
    IQ_TEST_SEQUENCE_INSTRUCTIONS: "/iq-test/sequence/instructions",
    IQ_TEST_SEQUENCE_TEST: "/iq-test/sequence/test",
    IQ_TEST_SEQUENCE_TIMER: "/iq-test/sequence/timer",
    IQ_TEST_SEQUENCE_EXPIRED: "/iq-test/sequence/expired",
    IQ_TEST_RESULTS: "/iq-test/results",
    IQ_TEST_WELCOME: "/iq-test",
    ASSESSMENTS_FAQ: "/assessments/faq",
    OPEN_INVITE: "/open-invite/:token"
};

export function getInterviewRoute(route, interviewCode) {
    return route.replace(":interviewCode", interviewCode);
}

export function getIdRoute(route, id) {
    return route.replace(":id", id);
}

export function getMCQTestIdRoute(route, id) {
    return route.replace(":mcqTestId", id);
}

export function getSubjectIdRoute(route, id) {
    return route.replace(":subjectId", id);
}

export default routes;
