import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    UPDATE_SUBJECT_PROFILE_REQUESTED: "UPDATE_SUBJECT_PROFILE_REQUESTED",
    UPDATE_SUBJECT_PROFILE_SUCCEEDED: "UPDATE_SUBJECT_PROFILE_SUCCEEDED",
    UPDATE_SUBJECT_PROFILE_FAILED: "UPDATE_SUBJECT_PROFILE_FAILED",
    UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_REQUESTED:
        "UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_REQUESTED",
    UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_SUCCEEDED:
        "UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_SUCCEEDED",
    UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_FAILED:
        "UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_FAILED",
    WFH_INDEX_REPORT_REQUESTED: "WFH_INDEX_REPORT_REQUESTED",
    WFH_INDEX_REPORT_SUCCEEDED: "WFH_INDEX_REPORT_SUCCEEDED",
    WFH_INDEX_REPORT_FAILED: "WFH_INDEX_REPORT_FAILED",
    BURNOUT_INDEX_REPORT_REQUESTED: "BURNOUT_INDEX_REPORT_REQUESTED",
    BURNOUT_INDEX_REPORT_SUCCEEDED: "BURNOUT_INDEX_REPORT_SUCCEEDED",
    BURNOUT_INDEX_REPORT_FAILED: "BURNOUT_INDEX_REPORT_FAILED",
    SUBJECT_MULTI_CHOICE_TESTS_REQUESTED:
        "SUBJECT_MULTI_CHOICE_TESTS_REQUESTED",
    SUBJECT_MULTI_CHOICE_TESTS_SUCCEEDED:
        "SUBJECT_MULTI_CHOICE_TESTS_SUCCEEDED",
    SUBJECT_MULTI_CHOICE_TESTS_FAILED: "SUBJECT_MULTI_CHOICE_TESTS_FAILED"
};

// Action Creators
export const actions = {
    update: (data, subjectId, callback) => {
        return {
            type: types.UPDATE_SUBJECT_PROFILE_REQUESTED,
            data,
            subjectId,
            callback
        };
    },
    updateOrgGivenData: (data, subjectId, callback) => {
        return {
            type: types.UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_REQUESTED,
            data,
            subjectId,
            callback
        };
    },
    getWFHIndexReport: (subjectId, callback) => {
        return {
            type: types.WFH_INDEX_REPORT_REQUESTED,
            subjectId,
            callback
        };
    },
    getBurnoutIndexReport: (subjectId, callback) => {
        return {
            type: types.BURNOUT_INDEX_REPORT_REQUESTED,
            subjectId,
            callback
        };
    },
    getMultiChoiceTests: (subjectId, callback) => {
        return {
            type: types.SUBJECT_MULTI_CHOICE_TESTS_REQUESTED,
            subjectId,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    wfhIndexReportLoading: true,
    wfhIndexReportData: {},
    burnoutIndexReportLoading: true,
    burnoutIndexReportData: {},
    mcTests: null,
    mcTestsLoading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.UPDATE_SUBJECT_PROFILE_REQUESTED:
        case types.UPDATE_SUBJECT_PROFILE_SUCCEEDED:
        case types.UPDATE_SUBJECT_PROFILE_FAILED:
            return {
                ...state
            };
        case types.WFH_INDEX_REPORT_REQUESTED:
            return {
                ...state,
                wfhIndexReportLoading: true,
                wfhIndexReportData: {}
            };
        case types.WFH_INDEX_REPORT_SUCCEEDED:
            return {
                ...state,
                wfhIndexReportLoading: false,
                wfhIndexReportData: action.data
            };
        case types.WFH_INDEX_REPORT_FAILED:
            return {
                ...state,
                wfhIndexReportLoading: false,
                wfhIndexReportData: {}
            };
        case types.BURNOUT_INDEX_REPORT_REQUESTED:
            return {
                ...state,
                burnoutIndexReportLoading: true,
                burnoutIndexReportData: {}
            };
        case types.BURNOUT_INDEX_REPORT_SUCCEEDED:
            return {
                ...state,
                burnoutIndexReportLoading: false,
                burnoutIndexReportData: action.data
            };
        case types.BURNOUT_INDEX_REPORT_FAILED:
            return {
                ...state,
                burnoutIndexReportLoading: false,
                burnoutIndexReportData: {}
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.WFH_INDEX_REPORT_REQUESTED, wfhIndexReportWorker);
    yield takeLatest(
        types.BURNOUT_INDEX_REPORT_REQUESTED,
        burnoutIndexReportWorker
    );
    yield takeLatest(types.UPDATE_SUBJECT_PROFILE_REQUESTED, udapteWorker);

    yield takeLatest(
        types.UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_REQUESTED,
        udapteOrgGivenDataWorker
    );
    yield takeLatest(
        types.SUBJECT_MULTI_CHOICE_TESTS_REQUESTED,
        getMultiChoiceTestsWorker
    );
}

// Saga callback
function* udapteWorker({ data, subjectId, callback }) {
    try {
        const response = yield call(update, { data, subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_SUBJECT_PROFILE_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.UPDATE_SUBJECT_PROFILE_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* udapteOrgGivenDataWorker({ data, subjectId, callback }) {
    try {
        const response = yield call(udapteOrgGivenData, { data, subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.UPDATE_SUBJECT_PROFILE_ORG_GIVEN_DATA_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* wfhIndexReportWorker({ subjectId, callback }) {
    try {
        const response = yield call(getWFHIndexReport, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.WFH_INDEX_REPORT_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.WFH_INDEX_REPORT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* burnoutIndexReportWorker({ subjectId, callback }) {
    try {
        const response = yield call(getBurnoutIndexReport, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.BURNOUT_INDEX_REPORT_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.BURNOUT_INDEX_REPORT_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getMultiChoiceTestsWorker({ subjectId, callback }) {
    try {
        const response = yield call(getMultiChoiceTests, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SUBJECT_MULTI_CHOICE_TESTS_SUCCEEDED,
            data: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.SUBJECT_MULTI_CHOICE_TESTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function update({ data, subjectId }) {
    return api.put(`/api/super-admin/edit/subject/${subjectId}`, { ...data });
}

function udapteOrgGivenData({ data, subjectId }) {
    return api.put(`/api/admin/subject/${subjectId}/given-data`, {
        work_start_date_at_current_company:
            data.work_start_date_at_current_company,
        role_type: data.role_type,
        role_id: data.role_id
    });
}

function getWFHIndexReport({ subjectId }) {
    return api.get(`/api/admin/work-from-home-report/${subjectId}`);
}

function getBurnoutIndexReport({ subjectId }) {
    return api.get(`/api/admin/subject-profile/burnout/${subjectId}`);
}

function getMultiChoiceTests({ subjectId }) {
    return api.get(`/api/admin/organization/skill-tests/subject/${subjectId}`);
}
