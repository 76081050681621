import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_SUBJECT_REQUESTED: "GET_SUBJECT_REQUESTED",
    GET_SUBJECT_SUCCEEDED: "GET_SUBJECT_SUCCEEDED",
    GET_SUBJECT_FAILED: "GET_SUBJECT_FAILED",
    GET_SUBJECTS_REQUESTED: "GET_SUBJECTS_REQUESTED",
    GET_SUBJECTS_SUCCEEDED: "GET_SUBJECTS_SUCCEEDED",
    GET_SUBJECTS_FAILED: "GET_SUBJECTS_FAILED",
    GET_FILTERED_CAMPAIGN_SUBJECTS_REQUESTED:
        "GET_FILTERED_CAMPAIGN_SUBJECTS_REQUESTED",
    GET_FILTERED_CAMPAIGN_SUBJECTS_SUCCEEDED:
        "GET_FILTERED_CAMPAIGN_SUBJECTS_SUCCEEDED",
    GET_FILTERED_CAMPAIGN_SUBJECTS_FAILED:
        "GET_FILTERED_CAMPAIGN_SUBJECTS_FAILED",
    GET_ALL_CAMPAIGN_SUBJECTS_REQUESTED: "GET_ALL_CAMPAIGN_SUBJECTS_REQUESTED",
    GET_ALL_CAMPAIGN_SUBJECTS_SUCCEEDED: "GET_ALL_CAMPAIGN_SUBJECTS_SUCCEEDED",
    GET_ALL_CAMPAIGN_SUBJECTS_FAILED: "GET_ALL_CAMPAIGN_SUBJECTS_FAILED",
    GET_MAGIC_LINK_REQUESTED: "GET_MAGIC_LINK_REQUESTED",
    GET_MAGIC_LINK_SUCCEEDED: "GET_MAGIC_LINK_SUCCEEDED",
    GET_MAGIC_LINK_FAILED: "GET_MAGIC_LINK_FAILED",
    GET_SUBJECT_PROFILE_REQUESTED: "GET_SUBJECT_PROFILE_REQUESTED",
    GET_SUBJECT_PROFILE_SUCCEEDED: "GET_SUBJECT_PROFILE_SUCCEEDED",
    GET_SUBJECT_PROFILE_FAILED: "GET_SUBJECT_PROFILE_FAILED",
    CLEAR_PROFILE_ERROR: "CLEAR_PROFILE_ERROR",
    GET_CANDIDATES_BY_PAGE_REQUESTED: "GET_CANDIDATES_BY_PAGE_REQUESTED",
    GET_CANDIDATES_BY_PAGE_SUCCEEDED: "GET_CANDIDATES_BY_PAGE_SUCCEEDED",
    GET_CANDIDATES_BY_PAGE_FAILED: "GET_CANDIDATES_BY_PAGE_FAILED",
    CANDIDATE_PROFILE_NOTES_REQUESTED: "CANDIDATE_PROFILE_NOTES_REQUESTED",
    CANDIDATE_PROFILE_NOTES_SUCCEEDED: "CANDIDATE_PROFILE_NOTES_SUCCEEDED",
    CANDIDATE_PROFILE_NOTES_FAILED: "CANDIDATE_PROFILE_NOTES_FAILED",
    GET_TEST_NOTES_REQUESTED: "GET_TEST_NOTES_REQUESTED",
    GET_TEST_NOTES_SUCCEEDED: "GET_TEST_NOTES_SUCCEEDED",
    GET_TEST_NOTES_FAILED: "GET_TEST_NOTES_FAILED",
    SAVE_CANDIDATE_CV_REQUESTED: "SAVE_CANDIDATE_CV_REQUESTED",
    SAVE_CANDIDATE_CV_SUCCEEDED: "SAVE_CANDIDATE_CV_SUCCEEDED",
    SAVE_CANDIDATE_CV_FAILED: " SAVE_CANDIDATE_CV_FAILED",
    GET_CANDIDATE_CV_REQUESTED: "GET_CANDIDATE_CV_REQUESTED",
    GET_CANDIDATE_CV_SUCCEEDED: "GET_CANDIDATE_CV_SUCCEEDED",
    GET_CANDIDATE_CV_FAILED: "GET_CANDIDATE_CV_FAILED",
    CLEAR_CV_ERROR: "CLEAR_CV_ERROR",
    SAVE_CANDIDATE_CUSTOM_FIELD_REQUESTED:
        "SAVE_CANDIDATE_CUSTOM_FIELD_REQUESTED",
    SAVE_CANDIDATE_CUSTOM_FIELD_SUCCEEDED:
        "SAVE_CANDIDATE_CUSTOM_FIELD_SUCCEEDED",
    SAVE_CANDIDATE_CUSTOM_FIELD_FAILED: "SAVE_CANDIDATE_CUSTOM_FIELD_FAILED",
    GET_CANDIDATE_CUSTOM_FIELD_REQUESTED:
        "GET_CANDIDATE_CUSTOM_FIELD_REQUESTED",
    GET_CANDIDATE_CUSTOM_FIELD_SUCCEEDED:
        "GET_CANDIDATE_CUSTOM_FIELD_SUCCEEDED",
    GET_CANDIDATE_CUSTOM_FIELD_FAILED: "GET_CANDIDATE_CUSTOM_FIELD_FAILED",
    DELETE_CANDIDATE_CUSTOM_FIELD_REQUESTED:
        "DELETE_CANDIDATE_CUSTOM_FIELD_REQUESTED",
    DELETE_CANDIDATE_CUSTOM_FIELD_SUCCEEDED:
        "DELETE_CANDIDATE_CUSTOM_FIELD_SUCCEEDED",
    DELETE_CANDIDATE_CUSTOM_FIELD_FAILED:
        "DELETE_CANDIDATE_CUSTOM_FIELD_FAILED",
    EDIT_CANDIDATE_CUSTOM_FIELD_SUCCEEDED:
        "EDIT_CANDIDATE_CUSTOM_FIELD_SUCCEEDED",
    HANDLE_SELECT_CUSTOM_FIELD: "HANDLE_SELECT_CUSTOM_FIELD",
    REMOVE_SUBJECTS_REQUESTED: "REMOVE_SUBJECTS_REQUESTED",
    REMOVE_SUBJECTS_FAILED: "REMOVE_SUBJECTS_FAILED",
    GET_FILTERED_ASSESSMENTS_SUBJECTS_REQUESTED:
        "GET_FILTERED_ASSESSMENTS_SUBJECTS_REQUESTED",
    GET_FILTERED_ASSESSMENTS_SUBJECTS_SUCCEEDED:
        "GET_FILTERED_ASSESSMENTS_SUBJECTS_SUCCEEDED",
    GET_FILTERED_ASSESSMENTS_SUBJECTS_FAILED:
        "GET_FILTERED_ASSESSMENTS_SUBJECTS_FAILED",
    GET_FILTERED_CANDIDATES_SUBJECTS_REQUESTED:
        "GET_FILTERED_CANDIDATES_SUBJECTS_REQUESTED",
    GET_FILTERED_CANDIDATES_SUBJECTS_SUCCEEDED:
        "GET_FILTERED_CANDIDATES_SUBJECTS_SUCCEEDED",
    GET_FILTERED_CANDIDATES_SUBJECTS_FAILED:
        "GET_FILTERED_CANDIDATES_SUBJECTS_FAILED",
    DELETE_TESTS_REQUESTED: "DELETE_TESTS_REQUESTED",
    DELETE_TESTS_SUCCEEDED: "DELETE_TESTS_SUCCEEDED",
    DELETE_TESTS_FAILED: "DELETE_TESTS_FAILED",
    EXPORT_SUBJECTS_TO_CSV_REQUESTED: "EXPORT_SUBJECTS_TO_CSV_REQUESTED",
    EXPORT_SUBJECTS_TO_CSV_SUCCEEDED: "EXPORT_SUBJECTS_TO_CSV_SUCCEEDED",
    EXPORT_SUBJECTS_TO_CSV_FAILED: "EXPORT_SUBJECTS_TO_CSV_FAILED"
};

// Action Creators
export const actions = {
    getAll: callback => {
        return {
            type: types.GET_SUBJECTS_REQUESTED,
            callback
        };
    },
    getSubject: (subjectId, callback) => {
        return {
            type: types.GET_SUBJECT_REQUESTED,
            subjectId,
            callback
        };
    },
    getFilteredCampaignSubjects: (
        status,
        campaignId,
        page,
        testType,
        name,
        perPage,
        order,
        callback
    ) => {
        return {
            type: types.GET_FILTERED_CAMPAIGN_SUBJECTS_REQUESTED,
            status,
            campaignId,
            page,
            testType,
            name,
            perPage,
            order,
            callback
        };
    },
    getFilteredAssessmentsSubjects: (
        status,
        campaignId,
        page,
        testType,
        name,
        perPage,
        order,
        callback
    ) => {
        return {
            type: types.GET_FILTERED_ASSESSMENTS_SUBJECTS_REQUESTED,
            status,
            page,
            testType,
            name,
            perPage,
            order,
            callback
        };
    },
    getFilteredCandidatesSubjects: (
        page,
        name,
        label,
        perPage,
        testTypes,
        callback
    ) => {
        return {
            type: types.GET_FILTERED_CANDIDATES_SUBJECTS_REQUESTED,
            page,
            name,
            label,
            perPage,
            testTypes,
            callback
        };
    },
    getAllCampaignSubjects: (campaignId, callback) => {
        return {
            type: types.GET_ALL_CAMPAIGN_SUBJECTS_REQUESTED,
            campaignId,
            callback
        };
    },
    getMagicLink: (subjectId, callback) => {
        return {
            type: types.GET_MAGIC_LINK_REQUESTED,
            subjectId,
            callback
        };
    },
    getSubjectProfile: (subjectId, callback) => {
        return {
            type: types.GET_SUBJECT_PROFILE_REQUESTED,
            subjectId,
            callback
        };
    },
    clearError: () => {
        return {
            type: types.CLEAR_PROFILE_ERROR
        };
    },
    getCandidatesByPage: (page, callback) => {
        return {
            type: types.GET_CANDIDATES_BY_PAGE_REQUESTED,
            page,
            callback
        };
    },
    saveCandidateProfileNote: (data, subjectId, callback) => {
        return {
            type: types.CANDIDATE_PROFILE_NOTES_REQUESTED,
            data,
            subjectId,
            callback
        };
    },
    getTestNotes: (subjectId, callback) => {
        return {
            type: types.GET_TEST_NOTES_REQUESTED,
            subjectId,
            callback
        };
    },
    saveCandidateCV: (cvFile, subjectId, callback) => {
        return {
            type: types.SAVE_CANDIDATE_CV_REQUESTED,
            cvFile,
            subjectId,
            callback
        };
    },
    getDownloadCandidateCV: (subjectId, callback) => {
        return {
            type: types.GET_CANDIDATE_CV_REQUESTED,
            subjectId,
            callback
        };
    },
    clearCvError: () => {
        return {
            type: types.CLEAR_CV_ERROR
        };
    },
    saveCustomField: (subjectId, customFieldId, data, callback) => {
        return {
            type: types.SAVE_CANDIDATE_CUSTOM_FIELD_REQUESTED,
            subjectId,
            customFieldId,
            data,
            callback
        };
    },
    getCustomFields: (subjectId, callback) => {
        return {
            type: types.GET_CANDIDATE_CUSTOM_FIELD_REQUESTED,
            subjectId,
            callback
        };
    },
    deleteCustomField: (customFieldId, callback) => {
        return {
            type: types.DELETE_CANDIDATE_CUSTOM_FIELD_REQUESTED,
            customFieldId,
            callback
        };
    },
    handleSelectCustomField: (data, field) => {
        return {
            type: types.HANDLE_SELECT_CUSTOM_FIELD,
            data,
            field
        };
    },
    removeCandidates: (campaignId, subjectId, callback) => {
        return {
            type: types.REMOVE_SUBJECTS_REQUESTED,
            campaignId,
            subjectId,
            callback
        };
    },
    deleteTests: (subjectId, tests, callback) => {
        return {
            type: types.DELETE_TESTS_REQUESTED,
            subjectId,
            tests,
            callback
        };
    },
    exportSubjectsToCSV: (subjects, callback) => {
        return {
            type: types.EXPORT_SUBJECTS_TO_CSV_REQUESTED,
            subjects,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    candidates: [],
    error: "",
    loading: true,
    profile: {},
    profileError: "",
    testNotes: [],
    cvMessage: "",
    cvUrl: "",
    cvError: "",
    customField: []
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_SUBJECT_REQUESTED:
            return {
                ...state,
                data: {},
                loading: true
            };
        case types.GET_SUBJECT_SUCCEEDED:
            return {
                ...state,
                data: action.result,
                loading: false
            };
        case types.GET_SUBJECT_FAILED:
        case types.GET_CANDIDATES_BY_PAGE_FAILED:
        case types.GET_TEST_NOTES_FAILED:
        case types.CANDIDATE_PROFILE_NOTES_FAILED:
        case types.SAVE_CANDIDATE_CV_FAILED:
        case types.SAVE_CANDIDATE_CUSTOM_FIELD_FAILED:
        case types.GET_CANDIDATE_CUSTOM_FIELD_FAILED:
        case types.DELETE_CANDIDATE_CUSTOM_FIELD_FAILED:
        case types.REMOVE_SUBJECTS_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case types.GET_SUBJECT_PROFILE_REQUESTED:
            return {
                ...state,
                profile: {},
                loading: true
            };
        case types.GET_SUBJECT_PROFILE_SUCCEEDED:
            return {
                ...state,
                profile: action.payload,
                loading: false
            };
        case types.GET_SUBJECT_PROFILE_FAILED:
            return {
                ...state,
                profileError: action.error,

                loading: false
            };
        case types.CLEAR_PROFILE_ERROR:
            return {
                ...state,
                profileError: "",
                loading: false
            };
        case types.GET_CANDIDATES_BY_PAGE_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_CANDIDATES_BY_PAGE_SUCCEEDED:
            return {
                ...state,
                candidates: action.data,
                loading: false
            };
        case types.GET_TEST_NOTES_REQUESTED:
            return {
                ...state
            };
        case types.GET_TEST_NOTES_SUCCEEDED:
            return {
                ...state,
                testNotes: action.data
            };
        case types.SAVE_CANDIDATE_CV_SUCCEEDED:
            return {
                ...state,
                cvMessage: action.result
            };
        case types.GET_CANDIDATE_CV_SUCCEEDED:
            return {
                ...state,
                cvUrl: action.result
            };
        case types.GET_CANDIDATE_CV_FAILED:
            return {
                ...state,
                cvError: action.error
            };
        case types.CLEAR_CV_ERROR:
            return {
                ...state,
                cvError: ""
            };
        case types.SAVE_CANDIDATE_CUSTOM_FIELD_SUCCEEDED:
            // state.customField.splice(0,0,{
            //     id: action.result.id,
            //     title: action.result.title,
            //     body: action.result.body,
            //     isChecked: false
            // });
            return {
                ...state,
                customField: [
                    ...state.customField,
                    {
                        id: action.result.id,
                        title: action.result.title,
                        body: action.result.body,
                        isChecked: false
                    }
                ]
            };
        case types.EDIT_CANDIDATE_CUSTOM_FIELD_SUCCEEDED:
            const index = state.customField.findIndex(
                el => el.id === action.result.id
            );
            const checked = state.customField[index].isChecked;
            state.customField[index] = {
                id: action.result.id,
                title: action.result.title,
                body: action.result.body,
                isChecked: checked
            };
            return {
                ...state,
                customField: [...state.customField]
            };
        case types.GET_CANDIDATE_CUSTOM_FIELD_SUCCEEDED:
            const newarr = action.result.map(field => {
                return {
                    id: field.id,
                    title: field.title,
                    body: field.body,
                    isChecked: false
                };
            });
            return {
                ...state,
                customField: newarr
            };
        case types.DELETE_CANDIDATE_CUSTOM_FIELD_SUCCEEDED:
            return {
                ...state,
                customField: state.customField.filter(
                    el => el.id !== action.result
                )
            };
        case types.HANDLE_SELECT_CUSTOM_FIELD:
            const index1 = state.customField.findIndex(
                el => el.id === action.field.id
            );
            const checked1 = (state.customField[index1].isChecked =
                action.data.checked);
            state.customField[index1] = {
                id: action.field.id,
                title: action.field.title,
                body: action.field.body,
                isChecked: checked1
            };
            return {
                ...state
            };

        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_SUBJECT_REQUESTED, startGetSubjectWorker);
    yield takeLatest(types.GET_SUBJECTS_REQUESTED, startGetSubjectsWorker);
    yield takeLatest(
        types.GET_FILTERED_CAMPAIGN_SUBJECTS_REQUESTED,
        startGetFilteredCampaignSubjectsWorker
    );
    yield takeLatest(
        types.GET_FILTERED_ASSESSMENTS_SUBJECTS_REQUESTED,
        startGetFilteredAssessmentsSubjectsWorker
    );
    yield takeLatest(
        types.GET_FILTERED_CANDIDATES_SUBJECTS_REQUESTED,
        startGetFilteredCandidatesSubjectsWorker
    );
    yield takeLatest(
        types.GET_ALL_CAMPAIGN_SUBJECTS_REQUESTED,
        startGetAllCampaignSubjectsWorker
    );
    yield takeLatest(types.GET_MAGIC_LINK_REQUESTED, startGetMagicLinkWorker);
    yield takeLatest(
        types.GET_SUBJECT_PROFILE_REQUESTED,
        startSubjectProfileWorker
    );
    yield takeLatest(
        types.GET_CANDIDATES_BY_PAGE_REQUESTED,
        startGetCandidatesByPageWorker
    );
    yield takeLatest(
        types.CANDIDATE_PROFILE_NOTES_REQUESTED,
        startSaveCandidatesProfileWorker
    );
    yield takeLatest(types.GET_TEST_NOTES_REQUESTED, startGetTestNotesWorker);
    yield takeLatest(
        types.SAVE_CANDIDATE_CV_REQUESTED,
        startSaveCandidateCvWorker
    );
    yield takeLatest(
        types.GET_CANDIDATE_CV_REQUESTED,
        startGetCandidateCvWorker
    );
    yield takeLatest(
        types.SAVE_CANDIDATE_CUSTOM_FIELD_REQUESTED,
        startSaveCandidateCustomFieldWorker
    );
    yield takeLatest(
        types.GET_CANDIDATE_CUSTOM_FIELD_REQUESTED,
        startGetCandidateCustomFieldWorker
    );
    yield takeLatest(
        types.DELETE_CANDIDATE_CUSTOM_FIELD_REQUESTED,
        startDeleteCandidateCustomFieldWorker
    );
    yield takeLatest(
        types.REMOVE_SUBJECTS_REQUESTED,
        startRemoveCandidateFromCampaignWorker
    );
    yield takeLatest(types.DELETE_TESTS_REQUESTED, startDeleteTestsWorker);
    yield takeLatest(
        types.EXPORT_SUBJECTS_TO_CSV_REQUESTED,
        startExportSubjectsToCSVWorker
    );
}

// Saga callback
function* startGetSubjectWorker({ subjectId, callback }) {
    try {
        const response = yield call(getSubject, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_SUCCEEDED,
            result: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_SUBJECT_FAILED, error: e.error });
    }
}

function* startSaveCandidateCvWorker({ cvFile, subjectId, callback }) {
    try {
        const response = yield call(callCandidateCV, { cvFile, subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_CANDIDATE_CV_SUCCEEDED,
            result: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.SAVE_CANDIDATE_CV_FAILED, error: e.error });
        callFail(callback, e.errors);
    }
}

function* startGetCandidateCvWorker({ subjectId, callback }) {
    try {
        const response = yield call(getCandidateCV, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));

        yield put({
            type: types.GET_CANDIDATE_CV_SUCCEEDED,
            result: url
        });

        callSuccess(callback, url);
    } catch (e) {
        yield put({
            type: types.GET_CANDIDATE_CV_FAILED,
            error: "Candidate CV not found"
        });
        callFail(callback, e.errors);
    }
}

function* startSaveCandidateCustomFieldWorker({
    subjectId,
    customFieldId,
    data,
    callback
}) {
    try {
        const response = yield call(saveCandidateCustomField, {
            subjectId,
            customFieldId,
            data
        });

        if (!response.ok) {
            throw response.data;
        }

        if (customFieldId) {
            yield put({
                type: types.EDIT_CANDIDATE_CUSTOM_FIELD_SUCCEEDED,
                result: response.data.data.customField
            });
        } else {
            yield put({
                type: types.SAVE_CANDIDATE_CUSTOM_FIELD_SUCCEEDED,
                result: response.data.data.customField
            });
        }

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.SAVE_CANDIDATE_CUSTOM_FIELD_FAILED,
            error: e.error
        });
    }
}

function* startGetCandidateCustomFieldWorker({ subjectId, callback }) {
    try {
        const response = yield call(getCandidateCustomField, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CANDIDATE_CUSTOM_FIELD_SUCCEEDED,
            result: response.data.data.customFields
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_CANDIDATE_CUSTOM_FIELD_FAILED,
            error: e.error
        });
    }
}

function* startDeleteCandidateCustomFieldWorker({ customFieldId, callback }) {
    try {
        const response = yield call(deleteCandidateCustomField, {
            customFieldId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_CANDIDATE_CUSTOM_FIELD_SUCCEEDED,
            result: customFieldId
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.DELETE_CANDIDATE_CUSTOM_FIELD_FAILED,
            error: e.error
        });
    }
}

function* startGetTestNotesWorker({ subjectId, callback }) {
    try {
        const response = yield call(callTestNotes, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_TEST_NOTES_SUCCEEDED,
            result: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_TEST_NOTES_FAILED, error: e.error });
    }
}

function* startSaveCandidatesProfileWorker({ data, subjectId, callback }) {
    try {
        const response = yield call(saveCandidateProfile, { data, subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.CANDIDATE_PROFILE_NOTES_SUCCEEDED
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.CANDIDATE_PROFILE_NOTES_FAILED,
            error: e.error
        });
    }
}

function* startGetCandidatesByPageWorker({ page, callback }) {
    try {
        const response = yield call(getCandidatesByPage, { page });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_CANDIDATES_BY_PAGE_SUCCEEDED,
            result: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_CANDIDATES_FAILED, error: e.error });
    }
}

function* startGetSubjectsWorker({ callback }) {
    try {
        const response = yield call(getSubjects);

        if (!response.ok) {
            throw response.data;
        }

        typeof callback == "function" && callback(response.data.data);
    } catch (e) {
        yield put({ type: types.GET_SUBJECT_FAILED, error: e.error });
    }
}

function* startSubjectProfileWorker({ subjectId, callback }) {
    try {
        const response = yield call(callSubjectProfile, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_SUBJECT_PROFILE_SUCCEEDED,
            payload: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_SUBJECT_PROFILE_FAILED, error: e.errors });
    }
}

function* startGetFilteredCampaignSubjectsWorker({
    status,
    campaignId,
    page,
    testType,
    name,
    perPage,
    order,
    callback
}) {
    try {
        const response = yield call(getFilteredCampaignSubjects, {
            status,
            campaignId,
            page,
            testType,
            name,
            perPage,
            order
        });

        if (!response.ok) {
            throw response.data;
        }

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_FILTERED_CAMPAIGN_SUBJECTS_FAILED,
            error: e.error
        });
    }
}

function* startGetFilteredAssessmentsSubjectsWorker({
    status,
    page,
    testType,
    name,
    perPage,
    order,
    callback
}) {
    try {
        const response = yield call(getFilteredAssessmentsSubjects, {
            status,
            page,
            testType,
            name,
            perPage,
            order
        });

        if (!response.ok) {
            throw response.data;
        }

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_FILTERED_ASSESSMENTS_SUBJECTS_FAILED,
            error: e.error
        });
    }
}

function* startGetFilteredCandidatesSubjectsWorker({
    page,
    name,
    label,
    perPage,
    testTypes,
    callback
}) {
    try {
        const response = yield call(getFilteredCandidatesSubjects, {
            page,
            name,
            label,
            perPage,
            testTypes
        });

        if (!response.ok) {
            throw response.data;
        }

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_FILTERED_CANDIDATES_SUBJECTS_FAILED,
            error: e.error
        });
    }
}

function* startGetAllCampaignSubjectsWorker({ campaignId, callback }) {
    try {
        const response = yield call(getAllCampaignSubjects, { campaignId });

        if (!response.ok) {
            throw response.data;
        }

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.GET_ALL_CAMPAIGN_SUBJECTS_FAILED,
            error: e.error
        });
    }
}

function* startGetMagicLinkWorker({ subjectId, callback }) {
    try {
        const response = yield call(getMagicLink, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        typeof callback == "function" && callback(e);

        yield put({
            type: types.GET_MAGIC_LINK_FAILED,
            error: e.error
        });
    }
}

function* startRemoveCandidateFromCampaignWorker({
    campaignId,
    subjectId,
    callback
}) {
    try {
        const response = yield call(removeSubjectFromCampaign, {
            campaignId,
            subjectId
        });

        if (!response.ok) {
            throw response.data;
        }

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({
            type: types.REMOVE_SUBJECTS_FAILED,
            error: e.error
        });
    }
}

function* startDeleteTestsWorker({ subjectId, tests, callback }) {
    try {
        const response = yield call(deleteTests, {
            subjectId,
            tests
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_TESTS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({ type: types.DELETE_TESTS_FAILED, error: e.error });
        callFail(callback, e.errors);
    }
}

function* startExportSubjectsToCSVWorker({ subjects, callback }) {
    try {
        const response = yield call(exportSubjectsToCSV, {
            subjects
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EXPORT_SUBJECTS_TO_CSV_SUCCEEDED
        });

        callSuccess(callback, response);
    } catch (e) {
        yield put({
            type: types.EXPORT_SUBJECTS_TO_CSV_FAILED,
            error: e.error
        });
        callFail(callback, e.errors);
    }
}

function removeSubjectFromCampaign({ campaignId, subjectId }) {
    return api.delete(
        `/api/admin/campaign/${campaignId}/subject/${subjectId}/remove`
    );
}

function getSubject({ subjectId }) {
    return api.get("/api/admin/subjects/" + subjectId);
}

function getSubjects() {
    return api.get("/api/admin/subjects");
}

function callSubjectProfile({ subjectId }) {
    return api.get(`/api/admin/subject-profile/${subjectId}`);
}

function saveCandidateProfile({ data, subjectId }) {
    return api.post(`/api/admin/tests/notes/${subjectId}`, { text: data });
}

function callTestNotes({ subjectId }) {
    return api.get(`/api/admin/tests/notes/${subjectId}`);
}

function callCandidateCV({ cvFile, subjectId }) {
    let form = new FormData();

    api.setHeader("Content-Type", "multipart/form-data");

    if (cvFile) {
        form.append("cv_file", cvFile);
    }

    return api.post(`/api/admin/subject-profile/upload-cv/${subjectId}`, form);
}

function saveCandidateCustomField({ subjectId, customFieldId, data }) {
    let sendData = data;

    if (customFieldId) {
        sendData = {
            ...sendData,
            customFieldId: customFieldId
        };
    }

    return api.put(`/api/admin/subject-profile/custom-field/${subjectId}`, {
        ...sendData
    });
}

function getCandidateCustomField({ subjectId }) {
    return api.get(`/api/admin/subject-profile/custom-field/${subjectId}`);
}

function deleteCandidateCustomField({ customFieldId }) {
    return api.delete(
        `/api/admin/subject-profile/custom-field/${customFieldId}/delete`
    );
}

function getCandidateCV({ subjectId }) {
    return api.get(`/api/admin/subject-profile/download-cv/${subjectId}`, {
        responseType: "blob"
    });
}

function addFilters(url, filters) {
    let first = true;

    if (filters.order) {
        url = url + (first ? "?" : "&") + `order=${filters.order}`;
        first = false;
    }

    if (filters.campaignId) {
        url = url + (first ? "?" : "&") + `campaign=${filters.campaignId}`;
        first = false;
    }

    if (filters.status) {
        url = url + (first ? "?" : "&") + `status=${filters.status}`;
        first = false;
    }

    if (filters.page) {
        url = url + (first ? "?" : "&") + `page=${filters.page}`;
        first = false;
    }

    if (filters.perPage) {
        url = url + (first ? "?" : "&") + `perPage=${filters.perPage}`;
        first = false;
    }

    if (filters.testType) {
        url = url + (first ? "?" : "&") + `testType=${filters.testType}`;
        first = false;
    }

    if (filters.name) {
        url = url + (first ? "?" : "&") + `name=${filters.name}`;
        first = false;
    }

    if (filters.label && parseInt(filters.label)) {
        url = url + (first ? "?" : "&") + `label=${filters.label}`;
        first = false;
    }

    return url;
}

function getFilteredCampaignSubjects({
    status = "",
    campaignId = 0,
    page = 1,
    testType = "",
    name = "",
    perPage,
    order = ""
}) {
    let url = addFilters(`/api/admin/subjects/campaign/filter`, {
        status,
        campaignId,
        page,
        testType,
        name,
        perPage,
        order
    });

    return api.get(url);
}

function getFilteredAssessmentsSubjects({
    status = "",
    page = 1,
    testType = "",
    name = "",
    perPage,
    order = ""
}) {
    let url = addFilters(`/api/admin/subjects/assessment/filter`, {
        status,
        page,
        testType,
        name,
        perPage,
        order
    });

    return api.get(url);
}

function getFilteredCandidatesSubjects({
    page = 1,
    name = "",
    label = 0,
    perPage,
    testTypes = ""
}) {
    let url = addFilters(`/api/admin/subjects/candidate/filter`, {
        page,
        name,
        label,
        perPage,
        testType: testTypes
    });

    return api.get(url);
}

function getAllCampaignSubjects({ campaignId }) {
    return api.get(`/api/admin/campaigns/${campaignId}/subjects`);
}

function getMagicLink({ subjectId }) {
    return api.get(`/api/admin/subjects/${subjectId}/get-invitation-link`);
}

function getCandidatesByPage({ page }) {
    if (!page) {
        page = 1;
    }
    return api.get(`/api/admin/subjects/all?page=${page}`);
}

function deleteTests({ subjectId, tests }) {
    return api.delete(`/api/super-admin/delete-tests`, {
        data: {
            subject_id: subjectId,
            test_type_ids: tests
        }
    });
}

function exportSubjectsToCSV({ subjects }) {
    return api.get(
        `/api/admin/subjects/download-csv?subjects=${subjects.join(",")}`,
        {
            responseType: "blob"
        }
    );
}
