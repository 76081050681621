// eslint-disable-next-line
import React from "react";
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useRouteMatch } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

import routes, { getIdRoute } from "Pages/routes";
import adminRoutes from "Pages/admin/routes";
import useUser from "Util/user";
import useTest from "Util/test";
import useInterview from "Util/interview";
import NavLink from "Components/Nav/NavLink";
import ProfileMenu from "Components/Nav/ProfileMenu";
import MenuItem from "@material-ui/core/MenuItem";

const Nav = () => {
    const matchHome = useRouteMatch({
        path: "/",
        exact: true
    });

    const { logout, isAdmin, isAuth } = useUser();
    const {
        hasCulture,
        hasIQ,
        hasPersonality,
        latestBurnout,
        hasBurnout,
        hasMCQTest
    } = useTest();
    const { hasInterviews } = useInterview();

    const matchPersonalityTest = useRouteMatch(routes.PERSONALITY_TEST);
    const matchPersonalityResults = useRouteMatch(routes.PERSONALITY_RESULTS);
    const matchCultureTest = useRouteMatch(routes.CULTURE_TEST);
    const matchSkills = useRouteMatch(routes.MCQTEST_TEST);
    const matchSkillsTest = useRouteMatch(routes.MCQTEST_TEST_LIST);
    const matchSkillsResults = useRouteMatch(routes.MCQTEST_TEST_RESULTS);
    const matchHappiness = useRouteMatch(routes.BURNOUT_TEST);
    const matchHappinessResults = useRouteMatch(routes.BURNOUT_RESULTS);
    const matchIQTest = useRouteMatch(routes.IQ_TEST_WELCOME);
    const matchIQResults = useRouteMatch(routes.IQ_TEST_RESULTS);
    const matchCultureResults = useRouteMatch(routes.CULTURE_RESULTS);
    const matchLogin = useRouteMatch(routes.LOGIN);
    const matchVideoInterview = useRouteMatch(routes.VIDEO_INTERVIEW_START);
    const matchVideoList = useRouteMatch(routes.VIDEO_INTERVIEW_LIST);
    const matchVideoDone = useRouteMatch(routes.VIDEO_INTERVIEW_DONE);

    return (
        <AppBar
            position="fixed"
            elevation={1}
            css={css`
                background-color: #fff;
            `}
        >
            <Toolbar
                variant="dense"
                css={css`
                    padding: 0;
                `}
            >
                <div
                    css={css`
                        width: 100%;
                        display: flex;
                        padding: 0 0.5rem;
                    `}
                >
                    <div
                        css={css`
                            white-space: nowrap;
                        `}
                    >
                        <NavLink
                            key="Home"
                            active={!!matchHome}
                            component={Link}
                            to={routes.HOME}
                            css={css`
                                border: none;
                            `}
                        >
                            <img
                                css={css`
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin: -0.5em 0;
                                    width: 2.5em;
                                `}
                                src="/apple-icon-180x180.png"
                                alt="Talent Data Labs"
                            />
                            &nbsp;<b>Assessments</b>
                        </NavLink>
                    </div>
                    <div
                        css={css`
                            display: flex;
                            width: 100%;
                            justify-content: center;
                        `}
                    >
                        {isAuth() ? (
                            <>
                                <NavLink
                                    key="Home"
                                    active={!!matchHome}
                                    component={Link}
                                    to={routes.HOME}
                                >
                                    Home
                                </NavLink>
                                {hasPersonality() ? (
                                    <NavLink
                                        key="Personality"
                                        active={
                                            !!matchPersonalityTest ||
                                            !!matchPersonalityResults
                                        }
                                        component={Link}
                                        to={routes.PERSONALITY_TEST}
                                    >
                                        Personality
                                    </NavLink>
                                ) : null}
                                {hasIQ() ? (
                                    <NavLink
                                        key="Logical"
                                        active={
                                            !!matchIQTest || !!matchIQResults
                                        }
                                        component={Link}
                                        to={routes.IQ_TEST_WELCOME}
                                    >
                                        Logical
                                    </NavLink>
                                ) : null}
                                {hasCulture() ? (
                                    <NavLink
                                        key="Culture"
                                        active={
                                            !!matchCultureTest ||
                                            !!matchCultureResults
                                        }
                                        component={Link}
                                        to={routes.CULTURE_TEST}
                                    >
                                        Culture
                                    </NavLink>
                                ) : null}
                                {hasBurnout() ? (
                                    <NavLink
                                        key="Happiness"
                                        component={Link}
                                        active={
                                            !!matchHappiness ||
                                            !!matchHappinessResults
                                        }
                                        to={
                                            hasBurnout()
                                                ? getIdRoute(
                                                      routes.BURNOUT_TEST,
                                                      latestBurnout().id
                                                  )
                                                : ""
                                        }
                                    >
                                        Happiness
                                    </NavLink>
                                ) : null}
                                {hasMCQTest() ? (
                                    <NavLink
                                        key="Skills"
                                        component={Link}
                                        active={
                                            !!matchSkills ||
                                            !!matchSkillsTest ||
                                            !!matchSkillsResults
                                        }
                                        to={routes.MCQTEST_TEST_LIST}
                                    >
                                        Skills
                                    </NavLink>
                                ) : null}
                                {hasInterviews() ? (
                                    <NavLink
                                        key="Video"
                                        active={
                                            !!matchVideoInterview ||
                                            !!matchVideoDone ||
                                            !!matchVideoList
                                        }
                                        component={Link}
                                        to={routes.VIDEO_INTERVIEW_LIST}
                                    >
                                        Video
                                    </NavLink>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <div
                        css={css`
                            width: 180px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        `}
                    >
                        {isAuth() ? (
                            <ProfileMenu>
                                {isAdmin()
                                    ? [
                                          <MenuItem
                                              css={theme => css`
                                                  color: ${theme.palette
                                                      .darkBlue};
                                                  &:hover {
                                                      color: ${theme.palette
                                                          .primary.main};
                                                  }
                                              `}
                                              key="Admin"
                                              component={Link}
                                              to={adminRoutes.ADMIN}
                                          >
                                              Switch to Manager View
                                          </MenuItem>,
                                          <Divider key="divider1" light />
                                      ]
                                    : null}
                                {isAuth() ? (
                                    <MenuItem
                                        css={theme => css`
                                            &:hover {
                                                color: ${theme.palette.primary
                                                    .main};
                                            }
                                        `}
                                        key="Profile"
                                        component={Link}
                                        to={routes.PROFILE}
                                    >
                                        Edit Talent Profile
                                    </MenuItem>
                                ) : null}
                                <Divider light />
                                <MenuItem
                                    css={theme => css`
                                        &:hover {
                                            color: ${theme.palette.red};
                                        }
                                    `}
                                    key="Logout"
                                    onClick={() => {
                                        logout();
                                    }}
                                >
                                    Logout
                                </MenuItem>
                            </ProfileMenu>
                        ) : (
                            <NavLink
                                active={!!matchLogin}
                                component={Link}
                                to={routes.LOGIN}
                            >
                                Login
                            </NavLink>
                        )}
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Nav;
