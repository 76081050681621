import React from "react";
import { Placeholder } from "semantic-ui-react";

const PlaceholderContent = ({ short }) => {
    return !short ? (
        <Placeholder>
            <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Paragraph>
        </Placeholder>
    ) : (
        <Placeholder>
            <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Paragraph>
        </Placeholder>
    );
};

export default PlaceholderContent;
