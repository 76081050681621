import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const ProfileMenu = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <AccountCircleIcon />
            </IconButton>
            <Menu
                elevation={2}
                id="profile-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {children}
            </Menu>
        </>
    );
};

export default ProfileMenu;
