import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    LINKEDIND_SKILL_SYNC_REQUESTED: "LINKEDIND_SKILL_SYNC_REQUESTED",
    LINKEDIND_SKILL_SYNC_SUCCEEDED: "LINKEDIND_SKILL_SYNC_SUCCEEDED",
    LINKEDIND_SKILL_SYNC_FAILED: "LINKEDIND_SKILL_SYNC_FAILED"
};

// Action Creators
export const actions = {
    syncSkills: (subjectId, callback) => {
        return {
            type: types.LINKEDIND_SKILL_SYNC_REQUESTED,
            subjectId,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    skills: [],
    syncingSkills: false,
    error: ""
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.LINKEDIND_SKILL_SYNC_REQUESTED:
            return {
                ...state,
                skills: [],
                error: "",
                syncingSkills: true
            };
        case types.LINKEDIND_SKILL_SYNC_SUCCEEDED:
            return {
                ...state,
                skills: action.payload,
                error: "",
                syncingSkills: false
            };
        case types.LINKEDIND_SKILL_SYNC_FAILED:
            return {
                ...state,
                skills: [],
                error: action.payload,
                syncingSkills: false
            };

        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.LINKEDIND_SKILL_SYNC_REQUESTED, syncSkillsWorker);
}

// Saga callback
function* syncSkillsWorker({ subjectId, callback }) {
    try {
        const response = yield call(syncSkills, { subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.LINKEDIND_SKILL_SYNC_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.LINKEDIND_SKILL_SYNC_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function syncSkills({ subjectId }) {
    return api.put(`/api/admin/subject-profile/sync/${subjectId}`);
}
