import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";

// Actions
const types = {
    SET_ANSWER: "SET_ANSWER",
    CONFIRM_ANSWER_REQUESTED: "CONFIRM_ANSWER_REQUESTED",
    CONFIRM_ANSWER_SUCCEEDED: "CONFIRM_ANSWER_SUCCEEDED",
    CONFIRM_ANSWER_FAILED: "CONFIRM_ANSWER_FAILED",
    CLEAR_ANSWERS: "CLEAR_ANSWERS"
};

// Action Creators
export const actions = {
    setAnswer: (index, scaleId, questionId, questionType) => {
        return {
            type: types.SET_ANSWER,
            index,
            scaleId,
            questionId,
            questionType
        };
    },
    confirmAnswer: (answer, callback) => {
        return {
            type: types.CONFIRM_ANSWER_REQUESTED,
            answer,
            callback
        };
    },
    clearAnswers: () => {
        return {
            type: types.CLEAR_ANSWERS
        };
    }
};

// Default state
const defaultState = {
    answers: {},
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_ANSWER:
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [action.index]: {
                        index: action.index,
                        questionId: action.questionId,
                        scaleId: action.scaleId,
                        type: action.questionType,
                        confirmed: false
                    }
                }
            };
        case types.CONFIRM_ANSWER_REQUESTED: {
            return {
                ...state,
                loading: true
            };
        }
        case types.CONFIRM_ANSWER_SUCCEEDED: {
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [action.answer.index]: action.answer
                },
                loading: false
            };
        }
        case types.CONFIRM_ANSWER_FAILED: {
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        }
        case types.CLEAR_ANSWERS: {
            return {
                ...state,
                answers: {}
            };
        }
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.CONFIRM_ANSWER_REQUESTED, confirmAnswerWorker);
}

// Saga callback
function* confirmAnswerWorker({ answer, callback }) {
    try {
        answer = { ...answer, confirmed: true };
        const response = yield call(confirmAnswer, { answer });

        if (!response.ok) {
            throw response.data;
        }

        let data = response.data;

        yield put({
            type: types.CONFIRM_ANSWER_SUCCEEDED,
            answer
        });

        typeof callback == "function" && callback(data);
    } catch (e) {
        yield put({ type: types.CONFIRM_ANSWER_FAILED, payload: e.message });
    }
}

// API call
function confirmAnswer({ answer }) {
    if (answer.type == "5-factor") {
        return api.post("/api/personality-test/answers", { ...answer });
    } else if (answer.type == "tendency") {
        return api.post("/api/personality-test/tendencies/answers", {
            ...answer
        });
    }
}
