import { takeEvery, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    NEW_ORG_CULTURE_REQUESTED: "NEW_ORG_CULTURE_REQUESTED",
    NEW_ORG_CULTURE_SUCCEEDED: "NEW_ORG_CULTURE_SUCCEEDED",
    NEW_ORG_CULTURE_FAILED: "NEW_ORG_CULTURE_FAILED",
    GET_ORG_CULTURE_REQUESTED: "GET_ORG_CULTURE_REQUESTED",
    GET_ORG_CULTURE_SUCCEEDED: "GET_ORG_CULTURE_SUCCEEDED",
    GET_ORG_CULTURE_FAILED: "GET_ORG_CULTURE_FAILED",
    DELETE_ORG_CULTURE_REQUESTED: "DELETE_ORG_CULTURE_REQUESTED",
    DELETE_ORG_CULTURE_SUCCEEDED: "DELETE_ORG_CULTURE_SUCCEEDED",
    DELETE_ORG_CULTURE_FAILED: "DELETE_ORG_CULTURE_FAILED"
};

// Action Creators
export const actions = {
    new: (data, callback) => {
        return {
            type: types.NEW_ORG_CULTURE_REQUESTED,
            data,
            callback
        };
    },
    get: (id, callback) => {
        return {
            type: types.GET_ORG_CULTURE_REQUESTED,
            id,
            callback
        };
    },
    delete: (id, callback) => {
        return {
            type: types.DELETE_ORG_CULTURE_REQUESTED,
            id,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    test: null,
    testLoading: true,
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.NEW_ORG_CULTURE_REQUESTED:
            return {
                ...state,
                test: {},
                testLoading: true
            };
        case types.NEW_ORG_CULTURE_SUCCEEDED:
            return {
                ...state,
                test: action.result.data,
                testLoading: false
            };
        case types.NEW_ORG_CULTURE_FAILED:
            return {
                ...state,
                error: action.error,
                testLoading: false
            };
        case types.GET_ORG_CULTURE_REQUESTED:
            return {
                ...state,
                test: {},
                testLoading: true
            };
        case types.GET_ORG_CULTURE_SUCCEEDED:
            return {
                ...state,
                test: action.result.data,
                testLoading: false
            };
        case types.GET_ORG_CULTURE_FAILED:
            return {
                ...state,
                error: action.error,
                testLoading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeEvery(types.NEW_ORG_CULTURE_REQUESTED, startNewOrgCultureWorker);
    yield takeEvery(types.GET_ORG_CULTURE_REQUESTED, startGetOrgCultureWorker);
    yield takeEvery(
        types.DELETE_ORG_CULTURE_REQUESTED,
        startDeleteOrgCultureWorker
    );
}

// Saga callback
function* startNewOrgCultureWorker({ data, callback }) {
    try {
        const response = yield call(newOrgCulture, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.NEW_ORG_CULTURE_SUCCEEDED,
            result: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.error({ e });
        yield put({ type: types.NEW_ORG_CULTURE_FAILED, payload: e.error });
        callFail(callback, e.errors);
    }
}

function* startGetOrgCultureWorker({ id, callback }) {
    try {
        const response = yield call(getOrgCulture, { id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORG_CULTURE_SUCCEEDED,
            result: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.error({ e });
        yield put({ type: types.GET_ORG_CULTURE_FAILED, payload: e.error });
        callFail(callback, e.errors);
    }
}

function* startDeleteOrgCultureWorker({ id, callback }) {
    try {
        const response = yield call(deleteOrgCulture, { id });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_ORG_CULTURE_SUCCEEDED,
            result: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.error({ e });
        yield put({ type: types.DELETE_ORG_CULTURE_FAILED, payload: e.error });
        callFail(callback, e.errors);
    }
}

// API call
function newOrgCulture({ data }) {
    return api.post(`/api/admin/culture-test`, { ...data });
}

function getOrgCulture({ id }) {
    return api.get(`/api/admin/culture-test/${id}/results`);
}

function deleteOrgCulture({ id }) {
    return api.put(`/api/admin/culture-test/${id}/delete`);
}
