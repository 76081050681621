import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_MCQTEST_REQUESTED: "GET_MCQTEST_REQUESTED",
    GET_MCQTEST_SUCCEEDED: "GET_MCQTEST_SUCCEEDED",
    GET_MCQTEST_FAILED: "GET_MCQTEST_FAILED",
    DELETE_MCQTEST_REQUESTED: "DELETE_MCQTEST_REQUESTED",
    DELETE_MCQTEST_SUCCEEDED: "DELETE_MCQTEST_SUCCEEDED",
    DELETE_MCQTEST_FAILED: "DELETE_MCQTEST_FAILED",
    GET_ALL_MCQTEST_REQUESTED: "GET_ALL_MCQTEST_REQUESTED",
    GET_ALL_MCQTEST_SUCCEEDED: "GET_ALL_MCQTEST_SUCCEEDED",
    GET_ALL_MCQTEST_FAILED: "GET_ALL_MCQTEST_FAILED",
    GET_ALL_FOR_MANAGERS_MCQTEST_REQUESTED:
        "GET_ALL_FOR_MANAGERS_MCQTEST_REQUESTED",
    GET_ALL_FOR_MANAGERS_MCQTEST_SUCCEEDED:
        "GET_ALL_FOR_MANAGERS_MCQTEST_SUCCEEDED",
    GET_ALL_FOR_MANAGERS_MCQTEST_FAILED: "GET_ALL_FOR_MANAGERS_MCQTEST_FAILED",
    CREATE_MCQTEST_REQUESTED: "CREATE_MCQTEST_REQUESTED",
    CREATE_MCQTEST_SUCCEEDED: "CREATE_MCQTEST_SUCCEEDED",
    CREATE_MCQTEST_FAILED: "CREATE_MCQTEST_FAILED",
    EDIT_MCQTEST_REQUESTED: "EDIT_MCQTEST_REQUESTED",
    EDIT_MCQTEST_SUCCEEDED: "EDIT_MCQTEST_SUCCEEDED",
    EDIT_MCQTEST_FAILED: "EDIT_MCQTEST_FAILED",
    ADD_QUESTION_MCQTEST_REQUESTED: "ADD_QUESTION_MCQTEST_REQUESTED",
    ADD_QUESTION_MCQTEST_SUCCEEDED: "ADD_QUESTION_MCQTEST_SUCCEEDED",
    ADD_QUESTION_MCQTEST_FAILED: "ADD_QUESTION_MCQTEST_FAILED",
    DELETE_QUESTION_MCQTEST_REQUESTED: "DELETE_QUESTION_MCQTEST_REQUESTED",
    DELETE_QUESTION_MCQTEST_SUCCEEDED: "DELETE_QUESTION_MCQTEST_SUCCEEDED",
    DELETE_QUESTION_MCQTEST_FAILED: "DELETE_QUESTION_MCQTEST_FAILED",
    EDIT_QUESTION_MCQTEST_REQUESTED: "EDIT_QUESTION_MCQTEST_REQUESTED",
    EDIT_QUESTION_MCQTEST_SUCCEEDED: "EDIT_QUESTION_MCQTEST_SUCCEEDED",
    EDIT_QUESTION_MCQTEST_FAILED: "EDIT_QUESTION_MCQTEST_FAILED",
    GET_QUESTION_MCQTEST_REQUESTED: "GET_QUESTION_MCQTEST_REQUESTED",
    GET_QUESTION_MCQTEST_SUCCEEDED: "GET_QUESTION_MCQTEST_SUCCEEDED",
    GET_QUESTION_MCQTEST_FAILED: "GET_QUESTION_MCQTEST_FAILED",
    ADD_QUESTION_OPTION_MCQTEST_REQUESTED:
        "ADD_QUESTION_OPTION_MCQTEST_REQUESTED",
    ADD_QUESTION_OPTION_MCQTEST_SUCCEEDED:
        "ADD_QUESTION_OPTION_MCQTEST_SUCCEEDED",
    ADD_QUESTION_OPTION_MCQTEST_FAILED: "ADD_QUESTION_OPTION_MCQTEST_FAILED",
    ASSIGN_QUESTION_OPTIONS_MCQTEST_REQUESTED:
        "ASSIGN_QUESTION_OPTIONS_MCQTEST_REQUESTED",
    ASSIGN_QUESTION_OPTIONS_MCQTEST_SUCCEEDED:
        "ASSIGN_QUESTION_OPTIONS_MCQTEST_SUCCEEDED",
    ASSIGN_QUESTION_OPTIONS_MCQTEST_FAILED:
        "ASSIGN_QUESTION_OPTIONS_MCQTEST_FAILED",
    DELETE_QUESTION_OPTION_MCQTEST_REQUESTED:
        "DELETE_QUESTION_OPTION_MCQTEST_REQUESTED",
    DELETE_QUESTION_OPTION_MCQTEST_SUCCEEDED:
        "DELETE_QUESTION_OPTION_MCQTEST_SUCCEEDED",
    DELETE_QUESTION_OPTION_MCQTEST_FAILED:
        "DELETE_QUESTION_OPTION_MCQTEST_FAILED",
    EDIT_QUESTION_OPTION_MCQTEST_REQUESTED:
        "EDIT_QUESTION_OPTION_MCQTEST_REQUESTED",
    EDIT_QUESTION_OPTION_MCQTEST_SUCCEEDED:
        "EDIT_QUESTION_OPTION_MCQTEST_SUCCEEDED",
    EDIT_QUESTION_OPTION_MCQTEST_FAILED: "EDIT_QUESTION_OPTION_MCQTEST_FAILED",
    GET_QUESTION_OPTION_MCQTEST_REQUESTED:
        "GET_QUESTION_OPTION_MCQTEST_REQUESTED",
    GET_QUESTION_OPTION_MCQTEST_SUCCEEDED:
        "GET_QUESTION_OPTION_MCQTEST_SUCCEEDED",
    GET_QUESTION_OPTION_MCQTEST_FAILED: "GET_QUESTION_OPTION_MCQTEST_FAILED",
    GET_MCQT_SUBJECT_RESULTS_REQUESTED: "GET_MCQT_SUBJECT_RESULTS_REQUESTED",
    GET_MCQT_SUBJECT_RESULTS_SUCCEEDED: "GET_MCQT_SUBJECT_RESULTS_SUCCEEDED",
    GET_MCQT_SUBJECT_RESULTS_FAILED: "GET_MCQT_SUBJECT_RESULTS_FAILED",
    SAVE_PREDEFINED_OPTIONS_REQUESTED: "SAVE_PREDEFINED_OPTIONS_REQUESTED",
    SAVE_PREDEFINED_OPTIONS_SUCCEEDED: "SAVE_PREDEFINED_OPTIONS_SUCCEEDED",
    SAVE_PREDEFINED_OPTIONS_FAILED: "SAVE_PREDEFINED_OPTIONS_FAILED",
    GET_PREDEFINED_OPTIONS_REQUESTED: "GET_PREDEFINED_OPTIONS_REQUESTED",
    GET_PREDEFINED_OPTIONS_SUCCEEDED: "GET_PREDEFINED_OPTIONS_SUCCEEDED",
    GET_PREDEFINED_OPTIONS_FAILED: "GET_PREDEFINED_OPTIONS_FAILED"
};

// Action Creators
export const actions = {
    get: (mcqTestId, callback) => {
        return {
            type: types.GET_MCQTEST_REQUESTED,
            mcqTestId,
            callback
        };
    },
    getAllForManagers: callback => {
        return {
            type: types.GET_ALL_FOR_MANAGERS_MCQTEST_REQUESTED,
            callback
        };
    },
    getAll: callback => {
        return {
            type: types.GET_ALL_MCQTEST_REQUESTED,
            callback
        };
    },
    create: (data, callback) => {
        return {
            type: types.CREATE_MCQTEST_REQUESTED,
            data,
            callback
        };
    },
    edit: (mcqTestId, data, callback) => {
        return {
            type: types.EDIT_MCQTEST_REQUESTED,
            mcqTestId,
            data,
            callback
        };
    },
    addQuestion: (mcqTestId, data, callback) => {
        return {
            type: types.ADD_QUESTION_MCQTEST_REQUESTED,
            mcqTestId,
            data,
            callback
        };
    },
    editQuestion: (mcqTestQuestionId, data, callback) => {
        return {
            type: types.EDIT_QUESTION_MCQTEST_REQUESTED,
            mcqTestQuestionId,
            data,
            callback
        };
    },
    getQuestion: (mcqTestQuestionId, callback) => {
        return {
            type: types.GET_QUESTION_MCQTEST_REQUESTED,
            mcqTestQuestionId,
            callback
        };
    },
    addOption: (mcqTestQuestionId, data, callback) => {
        return {
            type: types.ADD_QUESTION_OPTION_MCQTEST_REQUESTED,
            mcqTestQuestionId,
            data,
            callback
        };
    },
    assignOptions: (mcqTestQuestionId, options, callback) => {
        return {
            type: types.ASSIGN_QUESTION_OPTIONS_MCQTEST_REQUESTED,
            mcqTestQuestionId,
            options,
            callback
        };
    },
    editOption: (mcqTestQuestionId, data, callback) => {
        return {
            type: types.EDIT_QUESTION_OPTION_MCQTEST_REQUESTED,
            mcqTestQuestionId,
            data,
            callback
        };
    },
    getOption: (mcqTestQuestionOptionId, callback) => {
        return {
            type: types.GET_QUESTION_OPTION_MCQTEST_REQUESTED,
            mcqTestQuestionOptionId,
            callback
        };
    },
    deleteTest: (mcqTestId, callback) => {
        return {
            type: types.DELETE_MCQTEST_REQUESTED,
            mcqTestId,
            callback
        };
    },
    deleteQuestion: (mcqTestQuestionId, callback) => {
        return {
            type: types.DELETE_QUESTION_MCQTEST_REQUESTED,
            mcqTestQuestionId,
            callback
        };
    },
    deleteOption: (mcqTestQuestionOptionId, callback) => {
        return {
            type: types.DELETE_QUESTION_OPTION_MCQTEST_REQUESTED,
            mcqTestQuestionOptionId,
            callback
        };
    },
    getSubjectResultsForTest: (testId, subjectId, callback) => {
        return {
            type: types.GET_MCQT_SUBJECT_RESULTS_REQUESTED,
            testId,
            subjectId,
            callback
        };
    },
    savePredefinedOptions: (groupName, options, callback) => {
        return {
            type: types.SAVE_PREDEFINED_OPTIONS_REQUESTED,
            groupName,
            options,
            callback
        };
    },
    getPredefinedOptions: callback => {
        return {
            type: types.GET_PREDEFINED_OPTIONS_REQUESTED,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    predefinedOptions: null,
    listForManagers: [],
    listForManagerLoading: false,
    list: [],
    listLoading: false,
    single: {},
    singleLoading: false,
    singleQuestion: {},
    singleQuestionLoading: false,
    singleOption: {},
    singleOptionLoading: false,
    results: null,
    resultsLoading: false,
    error: ""
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_MCQT_SUBJECT_RESULTS_REQUESTED:
            return {
                ...state,
                results: null,
                resultsLoading: true
            };
        case types.GET_MCQTEST_REQUESTED:
            return {
                ...state,
                single: {},
                singleLoading: true
            };
        case types.GET_MCQTEST_SUCCEEDED:
            return {
                ...state,
                single: action.payload,
                singleLoading: false
            };
        case types.GET_MCQTEST_FAILED:
            return {
                ...state,
                single: {},
                singleLoading: false
            };
        case types.GET_ALL_MCQTEST_REQUESTED:
            return {
                ...state,
                list: [],
                listLoading: true
            };
        case types.GET_ALL_MCQTEST_SUCCEEDED:
            return {
                ...state,
                list: action.payload,
                listLoading: false
            };
        case types.GET_ALL_MCQTEST_FAILED:
            return {
                ...state,
                list: [],
                listLoading: false
            };
        case types.GET_ALL_FOR_MANAGERS_MCQTEST_REQUESTED:
            return {
                ...state,
                listForManagers: [],
                listForManagersLoading: true
            };
        case types.GET_ALL_FOR_MANAGERS_MCQTEST_SUCCEEDED:
            return {
                ...state,
                listForManagers: action.payload,
                listForManagersLoading: false
            };
        case types.GET_ALL_FOR_MANAGERS_MCQTEST_FAILED:
            return {
                ...state,
                listForManagers: [],
                listForManagersLoading: false
            };
        case types.GET_QUESTION_MCQTEST_REQUESTED:
            return {
                ...state,
                singleQuestion: {},
                singleQuestionLoading: true
            };
        case types.GET_QUESTION_MCQTEST_SUCCEEDED:
            return {
                ...state,
                singleQuestion: action.payload,
                singleQuestionLoading: false
            };
        case types.GET_QUESTION_MCQTEST_FAILED:
            return {
                ...state,
                singleQuestion: {},
                singleQuestionLoading: false
            };
        case types.GET_QUESTION_OPTION_MCQTEST_REQUESTED:
            return {
                ...state,
                singleOption: {},
                singleOptionLoading: true
            };
        case types.GET_QUESTION_OPTION_MCQTEST_SUCCEEDED:
            return {
                ...state,
                singleOption: action.payload,
                singleOptionLoading: false
            };
        case types.GET_QUESTION_OPTION_MCQTEST_FAILED:
            return {
                ...state,
                singleOption: {},
                singleOptionLoading: false
            };
        case types.GET_MCQT_SUBJECT_RESULTS_SUCCEEDED:
            return {
                ...state,
                results: action.payload,
                resultsLoading: false
            };
        case types.GET_MCQT_SUBJECT_RESULTS_FAILED:
            return {
                ...state,
                results: null,
                resultsLoading: false
            };
        case types.GET_PREDEFINED_OPTIONS_SUCCEEDED:
            return {
                ...state,
                predefinedOptions: action.payload
            };
        case types.GET_PREDEFINED_OPTIONS_REQUESTED:
        case types.GET_PREDEFINED_OPTIONS_FAILED:
            return {
                ...state,
                predefinedOptions: null
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_MCQTEST_REQUESTED, getWorker);
    yield takeLatest(types.GET_ALL_MCQTEST_REQUESTED, getAllWorker);
    yield takeLatest(
        types.GET_ALL_FOR_MANAGERS_MCQTEST_REQUESTED,
        getAllForManagersWorker
    );
    yield takeLatest(types.CREATE_MCQTEST_REQUESTED, createWorker);
    yield takeLatest(types.EDIT_MCQTEST_REQUESTED, editWorker);
    yield takeLatest(types.ADD_QUESTION_MCQTEST_REQUESTED, addQuestionWorker);
    yield takeLatest(types.EDIT_QUESTION_MCQTEST_REQUESTED, editQuestionWorker);
    yield takeLatest(types.GET_QUESTION_MCQTEST_REQUESTED, getQuestionWorker);
    yield takeLatest(
        types.ADD_QUESTION_OPTION_MCQTEST_REQUESTED,
        addQuestionOptionWorker
    );
    yield takeLatest(
        types.ASSIGN_QUESTION_OPTIONS_MCQTEST_REQUESTED,
        assignQuestionOptionsWorker
    );
    yield takeLatest(
        types.EDIT_QUESTION_OPTION_MCQTEST_REQUESTED,
        editQuestionOptionWorker
    );
    yield takeLatest(
        types.GET_QUESTION_OPTION_MCQTEST_REQUESTED,
        getQuestionOptionWorker
    );
    yield takeLatest(types.DELETE_MCQTEST_REQUESTED, deleteTestWorker);
    yield takeLatest(
        types.DELETE_QUESTION_MCQTEST_REQUESTED,
        deleteQuestionWorker
    );
    yield takeLatest(
        types.DELETE_QUESTION_OPTION_MCQTEST_REQUESTED,
        deleteQuestionOptionWorker
    );
    yield takeLatest(
        types.GET_MCQT_SUBJECT_RESULTS_REQUESTED,
        getSubjectResultsForTestWorker
    );
    yield takeLatest(
        types.SAVE_PREDEFINED_OPTIONS_REQUESTED,
        savePredefinedOptionsWorker
    );
    yield takeLatest(
        types.GET_PREDEFINED_OPTIONS_REQUESTED,
        getPredefinedOptionsWorker
    );
}

// Saga callback
function* getWorker({ mcqTestId, callback }) {
    try {
        const response = yield call(get, { mcqTestId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getAllWorker({ callback }) {
    try {
        const response = yield call(getAll);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ALL_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_ALL_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getAllForManagersWorker({ callback }) {
    try {
        const response = yield call(getAllForManagers);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ALL_FOR_MANAGERS_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_ALL_FOR_MANAGERS_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* createWorker({ data, callback }) {
    try {
        const response = yield call(create, { data });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.CREATE_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.CREATE_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* editWorker({ mcqTestId, data, callback }) {
    try {
        const response = yield call(edit, {
            mcqTestId,
            data
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EDIT_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.EDIT_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* addQuestionWorker({ mcqTestId, data, callback }) {
    try {
        const response = yield call(addQuestion, {
            mcqTestId,
            data
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ADD_QUESTION_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.ADD_QUESTION_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* editQuestionWorker({ mcqTestQuestionId, data, callback }) {
    try {
        const response = yield call(editQuestion, {
            mcqTestQuestionId,
            data
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EDIT_QUESTION_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.EDIT_QUESTION_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getQuestionWorker({ mcqTestQuestionId, callback }) {
    try {
        const response = yield call(getQuestion, {
            mcqTestQuestionId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_QUESTION_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_QUESTION_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* addQuestionOptionWorker({ mcqTestQuestionId, data, callback }) {
    try {
        const response = yield call(addQuestionOption, {
            mcqTestQuestionId,
            data
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ADD_QUESTION_OPTION_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.ADD_QUESTION_OPTION_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* assignQuestionOptionsWorker({
    mcqTestQuestionId,
    options,
    callback
}) {
    try {
        const response = yield call(assignQuestionOptions, {
            mcqTestQuestionId,
            options
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.ASSIGN_QUESTION_OPTIONS_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.ASSIGN_QUESTION_OPTIONS_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* editQuestionOptionWorker({ mcqTestQuestionId, data, callback }) {
    try {
        const response = yield call(editQuestionOption, {
            mcqTestQuestionId,
            data
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.EDIT_QUESTION_OPTION_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.EDIT_QUESTION_OPTION_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getQuestionOptionWorker({ mcqTestQuestionOptionId, callback }) {
    try {
        const response = yield call(getQuestionOption, {
            mcqTestQuestionOptionId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_QUESTION_OPTION_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_QUESTION_OPTION_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteTestWorker({ mcqTestId, callback }) {
    try {
        const response = yield call(deleteTest, { mcqTestId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.DELETE_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteQuestionWorker({ mcqTestQuestionId, callback }) {
    try {
        const response = yield call(deleteQuestion, {
            mcqTestQuestionId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_QUESTION_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.DELETE_QUESTION_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* deleteQuestionOptionWorker({ mcqTestQuestionOptionId, callback }) {
    try {
        const response = yield call(deleteQuestionOption, {
            mcqTestQuestionOptionId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.DELETE_QUESTION_OPTION_MCQTEST_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.DELETE_QUESTION_OPTION_MCQTEST_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getSubjectResultsForTestWorker({ testId, subjectId, callback }) {
    try {
        const response = yield call(getSubjectResultsForTest, {
            testId,
            subjectId
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_MCQT_SUBJECT_RESULTS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_MCQT_SUBJECT_RESULTS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* savePredefinedOptionsWorker({ groupName, options, callback }) {
    try {
        const response = yield call(savePredefinedOptions, {
            groupName,
            options
        });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SAVE_PREDEFINED_OPTIONS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.SAVE_PREDEFINED_OPTIONS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getPredefinedOptionsWorker({ callback }) {
    try {
        const response = yield call(getPredefinedOptions);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_PREDEFINED_OPTIONS_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({
            type: types.GET_PREDEFINED_OPTIONS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function get({ mcqTestId }) {
    return api.get(`/api/super-admin/multi-choice/test/${mcqTestId}`);
}

function getAll() {
    return api.get(`/api/super-admin/multi-choice/test`);
}

function getAllForManagers() {
    return api.get(`/api/admin/multi-choice/tests`);
}

function create({ data }) {
    if (data.organization_ids && data.organization_ids.length) {
        data.is_universal = false;
    } else {
        data.is_universal = true;
    }

    return api.post(`/api/super-admin/multi-choice/test`, data);
}

function edit({ mcqTestId, data }) {
    if (data.organization_ids && data.organization_ids.length) {
        data.is_universal = false;
    } else {
        data.is_universal = true;
    }

    return api.put(`/api/super-admin/multi-choice/test/${mcqTestId}`, data);
}

function addQuestion({ mcqTestId, data }) {
    api.setHeader("Content-Type", "multipart/form-data");

    let form = new FormData();
    form.append("text", data.text);
    form.append("test_id", Number(mcqTestId));

    if (data.image) {
        form.append("image", data.image);
    }

    return api.post(`/api/super-admin/multi-choice/question`, form);
}

function editQuestion({ mcqTestQuestionId, data }) {
    api.setHeader("Content-Type", "multipart/form-data");

    let form = new FormData();
    form.append("text", data.text);

    if (data.image) {
        form.append("image", data.image);
    }

    form.append("_method", "PUT");

    return api.post(
        `/api/super-admin/multi-choice/question/${mcqTestQuestionId}`,
        form
    );
}

function getQuestion({ mcqTestQuestionId }) {
    return api.get(
        `/api/super-admin/multi-choice/question/${mcqTestQuestionId}`
    );
}

function addQuestionOption({ mcqTestQuestionId, data }) {
    return api.post(`/api/super-admin/multi-choice/option`, {
        text: data.text,
        score: data.score,
        question_id: Number(mcqTestQuestionId),
        is_correct: !!data.score
    });
}

function assignQuestionOptions({ mcqTestQuestionId, options }) {
    return api.post(`/api/super-admin/multi-choice/assign/options`, {
        options: options,
        question_id: Number(mcqTestQuestionId)
    });
}

function editQuestionOption({ mcqTestQuestionId, data }) {
    return api.put(
        `/api/super-admin/multi-choice/option/${mcqTestQuestionId}`,
        {
            text: data.text,
            score: data.score,
            is_correct: !!data.score
        }
    );
}

function getQuestionOption({ mcqTestQuestionOptionId }) {
    return api.get(
        `/api/super-admin/multi-choice/option/${mcqTestQuestionOptionId}`
    );
}

function deleteTest({ mcqTestId }) {
    return api.delete(`/api/super-admin/multi-choice/test/${mcqTestId}`);
}

function deleteQuestion({ mcqTestQuestionId }) {
    return api.delete(
        `/api/super-admin/multi-choice/question/${mcqTestQuestionId}`
    );
}

function deleteQuestionOption({ mcqTestQuestionOptionId }) {
    return api.delete(
        `/api/super-admin/multi-choice/option/${mcqTestQuestionOptionId}`
    );
}

function getSubjectResultsForTest({ testId, subjectId }) {
    return api.get(
        `/api/admin/subject/${subjectId}/skill-tests/${testId}/results`
    );
}

function savePredefinedOptions({ groupName, options }) {
    return api.post(`/api/super-admin/multi-choice/predefined-option-groups`, {
        groupName,
        options
    });
}

function getPredefinedOptions() {
    return api.get(`/api/super-admin/multi-choice/predefined-option-groups`);
}
