import React, { lazy } from "react";
import { Route } from "react-router-dom";

const Login = lazy(() => import("./Login"));
const ChoosePassword = lazy(() => import("./ChoosePassword"));
const Candidates = lazy(() => import("./Candidates"));
const ActionLogs = lazy(() => import("./ActionLogs"));
const Compare = lazy(() => import("./Compare"));
// Personality
const PersonalityReportSingle = lazy(() =>
    import("./reports/PersonalityReportSingle")
);
const PersonalityReportCompare = lazy(() =>
    import("./reports/PersonalityReportCompare")
);
const PersonalityReportTeamCompare = lazy(() =>
    import("./reports/PersonalityReportTeamCompare")
);
// Culture
const CultureReportSingle = lazy(() => import("./reports/CultureReportSingle"));
const CultureReportCompare = lazy(() =>
    import("./reports/CultureReportCompare")
);
// IQ
const LogicalReportSingle = lazy(() => import("./reports/LogicalReportSingle"));
// Video Interview
const InterviewResults = lazy(() => import("./InterviewResults"));

const Dashboard = lazy(() => import("./Dashboard"));
const EditManager = lazy(() => import("./manager/Edit"));
const ManagerProfile = lazy(() => import("./manager/Profile"));
const ManagerOrganization = lazy(() => import("./manager/Organization"));
const EditManagerOrganization = lazy(() =>
    import("./manager/EditOrganization")
);
const SubjectProfile = lazy(() => import("./subjectProfile/SubjectProfile"));

const InactiveOrganization = lazy(() =>
    import("./organization/InactiveOrganization")
);
const SuccessProfileSubjectReview = lazy(() =>
    import("./manager/successProfile/SubjectReview")
);
const SuccessProfileForm = lazy(() => import("./manager/successProfile/Form"));
const SuccessProfileView = lazy(() => import("./manager/successProfile/View"));
const SuccessProfileSearchSubjects = lazy(() =>
    import("./manager/successProfile/SearchSubjects")
);
const SuccessProfileReport = lazy(() =>
    import("./manager/successProfile/Report")
);
const LabelsForm = lazy(() => import("./manager/labels/Form"));

// MCQ Test
const MCQTestList = lazy(() => import("./mcqTest/List"));
const MCQTestForm = lazy(() => import("./mcqTest/Form"));
const MCQTestView = lazy(() => import("./mcqTest/View"));
const MCQTestNewQuestion = lazy(() => import("./mcqTest/NewQuestion"));
const MCQTestEditQuestion = lazy(() => import("./mcqTest/EditQuestion"));
const MCQTestViewQuestion = lazy(() => import("./mcqTest/ViewQuestion"));
const MCQTestNewQuestionOption = lazy(() => import("./mcqTest/NewOption"));
const MCQTestEditQuestionOption = lazy(() => import("./mcqTest/EditOption"));

// Job Profiles
const JobProfileForm = lazy(() => import("./jobProfile/Edit"));
const JobProfileList = lazy(() => import("./jobProfile/List"));

const WorkingFromHomeIndexReport = lazy(() =>
    import("./reports/WorkingFromHomeIndexReport")
);
const BurnoutIndexReport = lazy(() => import("./reports/BurnoutIndexReport"));
const MCQTestReport = lazy(() => import("./reports/MCQTestReport"));

import campaignRoutes from "./campaign";
import organizationRoutes from "./organization/Pages";

import AuthRoute from "AdminUtil/AuthRoute";
import routes from "AdminPages/routes";

const pages = [
    <Route
        key={routes.CHOOSE_PASSWORD}
        path={routes.CHOOSE_PASSWORD}
        component={ChoosePassword}
    />,
    <Route key={routes.LOGIN} path={routes.LOGIN} component={Login} />,
    <AuthRoute
        key={routes.EDIT_MANAGER}
        path={routes.EDIT_MANAGER}
        component={EditManager}
    />,
    <AuthRoute
        key={routes.MANAGER_PROFILE}
        path={routes.MANAGER_PROFILE}
        component={ManagerProfile}
    />,
    <AuthRoute
        key={routes.MANAGER_ORGANIZATION}
        path={routes.MANAGER_ORGANIZATION}
        component={ManagerOrganization}
    />,
    <AuthRoute
        key={routes.EDIT_MANAGER_ORGANIZATION}
        path={routes.EDIT_MANAGER_ORGANIZATION}
        component={EditManagerOrganization}
    />,
    <Route
        key={routes.INACTIVE_ORGANIZATION}
        path={routes.INACTIVE_ORGANIZATION}
        component={InactiveOrganization}
    />,
    <AuthRoute
        key={routes.ASSESSMENTS}
        path={routes.ASSESSMENTS}
        component={props => (
            <Compare {...props} key={window.location.pathname} />
        )}
    />,
    <AuthRoute
        key={routes.CANDIDATES}
        path={routes.CANDIDATES}
        component={props => (
            <Candidates {...props} key={window.location.pathname} />
        )}
    />,
    <AuthRoute
        key={routes.CANDIDATE_PROFILE}
        path={routes.CANDIDATE_PROFILE}
        component={SubjectProfile}
        exact
    />,
    <AuthRoute
        key={routes.SUCCESS_PROFILE_NEW}
        path={routes.SUCCESS_PROFILE_NEW}
        component={SuccessProfileForm}
        exact
    />,
    <AuthRoute
        key={routes.SUCCESS_PROFILE_VIEW}
        path={routes.SUCCESS_PROFILE_VIEW}
        component={SuccessProfileView}
        exact
    />,
    <AuthRoute
        key={routes.SUCCESS_PROFILE_EDIT}
        path={routes.SUCCESS_PROFILE_EDIT}
        component={SuccessProfileForm}
    />,
    <AuthRoute
        key={routes.SUCCESS_PROFILE_SUBJECT_REVIEW}
        path={routes.SUCCESS_PROFILE_SUBJECT_REVIEW}
        component={SuccessProfileSubjectReview}
    />,
    <AuthRoute
        key={routes.SUCCESS_PROFILE_SEARCH_SUBJECTS}
        path={routes.SUCCESS_PROFILE_SEARCH_SUBJECTS}
        component={SuccessProfileSearchSubjects}
    />,
    <AuthRoute
        key={routes.SUCCESS_PROFILE_REPORT}
        path={routes.SUCCESS_PROFILE_REPORT}
        component={SuccessProfileReport}
    />,
    <AuthRoute
        key={routes.MCQ_TEST_LIST}
        path={routes.MCQ_TEST_LIST}
        component={MCQTestList}
        exact
    />,
    <AuthRoute
        key={routes.MCQ_TEST_EDIT}
        path={routes.MCQ_TEST_EDIT}
        component={MCQTestForm}
        exact
    />,
    <AuthRoute
        key={routes.MCQ_TEST_NEW}
        path={routes.MCQ_TEST_NEW}
        component={MCQTestForm}
        exact
    />,
    <AuthRoute
        key={routes.MCQ_TEST_VIEW}
        path={routes.MCQ_TEST_VIEW}
        component={MCQTestView}
        exact
    />,
    <AuthRoute
        key={routes.MCQ_TEST_NEW_QUESTION}
        path={routes.MCQ_TEST_NEW_QUESTION}
        component={MCQTestNewQuestion}
        exact
    />,
    <AuthRoute
        key={routes.MCQ_TEST_EDIT_QUESTION}
        path={routes.MCQ_TEST_EDIT_QUESTION}
        component={MCQTestEditQuestion}
        exact
    />,
    <AuthRoute
        key={routes.MCQ_TEST_VIEW_QUESTION}
        path={routes.MCQ_TEST_VIEW_QUESTION}
        component={MCQTestViewQuestion}
        exact
    />,
    <AuthRoute
        key={routes.MCQ_TEST_NEW_QUESTION_OPTION}
        path={routes.MCQ_TEST_NEW_QUESTION_OPTION}
        component={MCQTestNewQuestionOption}
        exact
    />,
    <AuthRoute
        key={routes.MCQ_TEST_EDIT_QUESTION_OPTION}
        path={routes.MCQ_TEST_EDIT_QUESTION_OPTION}
        component={MCQTestEditQuestionOption}
        exact
    />,
    <AuthRoute
        key={routes.NEW_JOB_PROFILE}
        path={routes.NEW_JOB_PROFILE}
        component={JobProfileForm}
    />,
    <AuthRoute
        key={routes.EDIT_JOB_PROFILE}
        path={routes.EDIT_JOB_PROFILE}
        component={JobProfileForm}
    />,
    <AuthRoute
        key={routes.LIST_JOB_PROFILES}
        path={routes.LIST_JOB_PROFILES}
        component={JobProfileList}
    />,
    <AuthRoute
        key={routes.LABEL_NEW}
        path={routes.LABEL_NEW}
        component={LabelsForm}
        exact
    />,
    <AuthRoute
        key={routes.LABEL_EDIT}
        path={routes.LABEL_EDIT}
        component={LabelsForm}
        exact
    />,
    <AuthRoute
        key={routes.CULTURE_COMPARE_RESULTS}
        path={routes.CULTURE_COMPARE_RESULTS}
        component={CultureReportCompare}
    />,
    <AuthRoute
        key={routes.PERSONALITY_COMPARE_RESULTS}
        path={routes.PERSONALITY_COMPARE_RESULTS}
        component={PersonalityReportCompare}
    />,
    <AuthRoute
        key={routes.PERSONALITY_TEAM_COMPARE_RESULTS}
        path={routes.PERSONALITY_TEAM_COMPARE_RESULTS}
        component={PersonalityReportTeamCompare}
    />,
    <AuthRoute
        key={routes.WFH_INDEX_REPORT}
        path={routes.WFH_INDEX_REPORT}
        component={WorkingFromHomeIndexReport}
    />,
    <AuthRoute
        key={routes.BURNOUT_REPORT}
        path={routes.BURNOUT_REPORT}
        component={BurnoutIndexReport}
    />,
    <AuthRoute
        key={routes.MCQ_TEST_RESULT}
        path={routes.MCQ_TEST_RESULT}
        component={MCQTestReport}
    />,

    ...campaignRoutes,
    ...organizationRoutes,
    <AuthRoute
        key={routes.PERSONALITY_TEST_RESULT}
        path={routes.PERSONALITY_TEST_RESULT}
        component={PersonalityReportSingle}
    />,
    <AuthRoute
        key={routes.LEGACY_PERSONALITY_TEST_RESULT}
        path={routes.LEGACY_PERSONALITY_TEST_RESULT}
        component={PersonalityReportSingle}
    />,
    <AuthRoute
        key={routes.CULTURE_RESULT}
        path={routes.CULTURE_RESULT}
        component={CultureReportSingle}
    />,
    <AuthRoute
        key={routes.LEGACY_CULTURE_RESULT}
        path={routes.LEGACY_CULTURE_RESULT}
        component={CultureReportSingle}
    />,
    <AuthRoute
        key={routes.IQ_TEST_RESULT}
        path={routes.IQ_TEST_RESULT}
        component={LogicalReportSingle}
    />,
    <AuthRoute
        key={routes.LEGACY_IQ_TEST_RESULT}
        path={routes.LEGACY_IQ_TEST_RESULT}
        component={LogicalReportSingle}
    />,
    <AuthRoute
        key={routes.INTERVIEW_RESULT}
        path={routes.INTERVIEW_RESULT}
        component={InterviewResults}
    />,
    <AuthRoute
        key={routes.ACTION_LOGS}
        path={routes.ACTION_LOGS}
        component={ActionLogs}
    />,
    <AuthRoute
        key={routes.DASHBOARD}
        path={routes.DASHBOARD}
        component={props => (
            <Dashboard {...props} key={window.location.pathname} />
        )}
    />,
    <AuthRoute key={routes.ADMIN} path={routes.ADMIN} component={Dashboard} />
];

export default pages;
