import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import campaigns from "./Campaigns";
import dashboard from "./Dashboard";
import interview from "./Interview";
import organizations from "./Organizations";
import organizationCultureTest from "./organizationCulture/Test";
import organizationCultureResult from "./organizationCulture/Result";
import organizationCultureAnswer from "./organizationCulture/Answer";
import organizationCultureQuestion from "./organizationCulture/Question";
import organizationCultureCategory from "./organizationCulture/Category";
import manager from "./Manager";
import labels from "./Labels";
import roles from "./Roles";
import features from "./Features";
import personalityResults from "./personality/Results";
import personalityNorms from "./personality/Norms";
import personalityFactors from "./personality/Factors";
import cultureResult from "./culture/Result";
import IQResult from "./iq-test/Result";
import subjects from "./Subjects";
import superAdmin from "./SuperAdmin";
import note from "./Note";
import subjectReview from "./SubjectReview";
import subjectPins from "./SubjectPins";
import successProfile from "./SuccessProfile";
import mcqTest from "./MCQTest";
import searchSubjectsForReview from "./SearchSubjectsForReview";
import subjectProfile from "./SubjectProfile";
import comparison from "./Comparison";
import jobProfile from "./JobProfiles";
import linkedinSync from "./LinkedinSync";

export default combineReducers({
    form: formReducer,
    campaigns: campaigns.reducer,
    dashboard: dashboard.reducer,
    interview: interview.reducer,
    organizations: organizations.reducer,
    organizationCulture: combineReducers({
        question: organizationCultureQuestion.reducer,
        answer: organizationCultureAnswer.reducer,
        result: organizationCultureResult.reducer,
        category: organizationCultureCategory.reducer,
        test: organizationCultureTest.reducer
    }),
    personality: combineReducers({
        results: personalityResults.reducer,
        norms: personalityNorms.reducer,
        factors: personalityFactors.reducer
    }),
    culture: combineReducers({
        result: cultureResult.reducer
    }),
    iqTest: combineReducers({
        result: IQResult.reducer
    }),
    subject: subjects.reducer,
    manager: manager.reducer,
    superAdmin: superAdmin.reducer,
    note: note.reducer,
    subjectReview: subjectReview.reducer,
    successProfile: successProfile.reducer,
    mcqTest: mcqTest.reducer,
    subjectPins: subjectPins.reducer,
    jobProfile: jobProfile.reducer,
    searchSubjectsForReview: searchSubjectsForReview.reducer,
    labels: labels.reducer,
    roles: roles.reducer,
    features: features.reducer,
    subjectProfile: subjectProfile.reducer,
    comparison: comparison.reducer,
    linkedinSync: linkedinSync.reducer
});
