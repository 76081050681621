import campaigns from "./Campaigns";
import organizations from "./Organizations";
import organizationCultureTest from "./organizationCulture/Test";
import organizationCultureResult from "./organizationCulture/Result";
import organizationCultureAnswer from "./organizationCulture/Answer";
import organizationCultureQuestion from "./organizationCulture/Question";
import organizationCultureCategory from "./organizationCulture/Category";
import manager from "./Manager";

import personalityResults from "./personality/Results";
import personalityNorms from "./personality/Norms";
import personalityFactors from "./personality/Factors";
import interview from "./Interview";
import cultureResult from "./culture/Result";
import IQResult from "./iq-test/Result";
import subjects from "./Subjects";
import superAdmin from "./SuperAdmin";
import note from "./Note";
import dashboard from "./Dashboard";
import labels from "./Labels";
import roles from "./Roles";
import subjectReview from "./SubjectReview";
import successProfile from "./SuccessProfile";
import mcqTest from "./MCQTest";
import subjectPins from "./SubjectPins";
import searchSubjectsForReview from "./SearchSubjectsForReview";
import subjectProfile from "./SubjectProfile";
import comparison from "./Comparison";
import jobProfile from "./JobProfiles";
import linkedinSync from "./LinkedinSync";
import features from "./Features";

export default [
    campaigns.saga,
    dashboard.saga,
    interview.saga,
    organizations.saga,
    organizationCultureTest.saga,
    organizationCultureResult.saga,
    organizationCultureAnswer.saga,
    organizationCultureQuestion.saga,
    organizationCultureCategory.saga,
    personalityResults.saga,
    personalityNorms.saga,
    personalityFactors.saga,
    cultureResult.saga,
    IQResult.saga,
    subjects.saga,
    manager.saga,
    superAdmin.saga,
    note.saga,
    successProfile.saga,
    mcqTest.saga,
    subjectReview.saga,
    searchSubjectsForReview.saga,
    labels.saga,
    roles.saga,
    features.saga,
    subjectProfile.saga,
    comparison.saga,
    jobProfile.saga,
    subjectPins.saga,
    linkedinSync.saga
];
