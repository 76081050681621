import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import store from "Store/store";
import userStore from "Store/User";
import hasRole from "AdminUtil/hasRole";

const isAdmin = user => {
    return hasRole(user, ["admin", "super_admin", "tdl_admin"]);
};

class AuthRoute extends React.PureComponent {
    render() {
        const { component: Component, user, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={props => {
                    if (user.auth) {
                        if (isAdmin(user)) {
                            return <Component {...props} {...rest} />;
                        } else {
                            store.dispatch(
                                userStore.actions.clearAuthDataUser()
                            );
                        }
                    } else {
                        return (
                            <>
                                {user.logoutValue === "logout" ? (
                                    <Redirect
                                        to={{
                                            pathname: `/admin/login`,
                                            state: { from: props.location }
                                        }}
                                    />
                                ) : (
                                    <Redirect
                                        to={`/admin/login?redirectTo=${this.props.location.pathname}`}
                                    />
                                )}
                            </>
                        );
                    }
                }}
            />
        );
    }
}

function mapStateToProps({ user }) {
    return {
        user
    };
}

export default connect(mapStateToProps)(AuthRoute);
