import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useUser from "./user";
import interviewStore from "Store/Interview";

const useInterview = () => {
    const { isAuth } = useUser();

    const interviews = useSelector(state => state.interview.data);
    const loading = useSelector(state => state.interview.loading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuth() && !interviews.base_url && !loading) {
            dispatch(interviewStore.actions.getInterviews());
        }
    }, [isAuth()]);

    const hasInterviews = () => {
        return (
            interviews &&
            interviews.interviews &&
            !!interviews.interviews.length
        );
    };

    const interviewsLoading = () => {
        return !!loading;
    };

    const getInterviews = () => {
        return interviews;
    };

    return {
        interviewsLoading,
        hasInterviews,
        getInterviews
    };
};

export default useInterview;
