import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";

const types = {
    GET_MAJORS_REQUESTED: "GET_MAJORS_REQUESTED",
    GET_MAJORS_SUCCEEDED: "GET_MAJORS_SUCCEEDED",
    GET_MAJORS_FAILED: "GET_MAJORS_FAILED"
};

export const actions = {
    getMajors: () => {
        return {
            type: types.GET_MAJORS_REQUESTED
        };
    }
};

const defaultState = {
    data: [],
    error: "",
    loading: true
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_MAJORS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_MAJORS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        case types.GET_MAJORS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(types.GET_MAJORS_REQUESTED, startGetMajorsWorker);
}

// Saga callback

function* startGetMajorsWorker() {
    try {
        const response = yield call(getMajors);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_MAJORS_SUCCEEDED,
            payload: response.data
        });
    } catch (e) {
        yield put({ type: types.GET_MAJORS_FAILED, payload: e.error });
    }
}

function getMajors() {
    return api.get("/api/subject-profile/majors-by-academic-fields");
}
