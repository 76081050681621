import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

// Actions
const types = {
    GET_ORGANIZATION_CULTURE_ANSWERS_REQUESTED:
        "GET_ORGANIZATION_CULTURE_ANSWERS_REQUESTED",
    GET_ORGANIZATION_CULTURE_ANSWERS_SUCCEEDED:
        "GET_ORGANIZATION_CULTURE_ANSWERS_SUCCEEDED",
    GET_ORGANIZATION_CULTURE_ANSWERS_FAILED:
        "GET_ORGANIZATION_CULTURE_ANSWERS_FAILED",
    SEND_ORGANIZATION_CULTURE_ANSWERS_REQUESTED:
        "SEND_ORGANIZATION_CULTURE_ANSWERS_REQUESTED",
    SEND_ORGANIZATION_CULTURE_ANSWERS_SUCCEEDED:
        "SEND_ORGANIZATION_CULTURE_ANSWERS_SUCCEEDED",
    SEND_ORGANIZATION_CULTURE_ANSWERS_FAILED:
        "SEND_ORGANIZATION_CULTURE_ANSWERS_FAILED"
};

// Action Creators
export const actions = {
    get: (testId, categoryId, callback) => {
        return {
            type: types.GET_ORGANIZATION_CULTURE_ANSWERS_REQUESTED,
            testId,
            categoryId,
            callback
        };
    },
    send: (testId, categoryId, answers, callback) => {
        return {
            type: types.SEND_ORGANIZATION_CULTURE_ANSWERS_REQUESTED,
            testId,
            answers,
            categoryId,
            callback
        };
    }
};

// Default state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SEND_ORGANIZATION_CULTURE_ANSWERS_REQUESTED:
        case types.GET_ORGANIZATION_CULTURE_ANSWERS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.SEND_ORGANIZATION_CULTURE_ANSWERS_SUCCEEDED:
        case types.GET_ORGANIZATION_CULTURE_ANSWERS_SUCCEEDED:
            return {
                data: {
                    ...state.data,
                    [action.payload.categoryId]: action.payload.data.data
                },
                error: "",
                loading: false
            };
        case types.SEND_ORGANIZATION_CULTURE_ANSWERS_FAILED:
        case types.GET_ORGANIZATION_CULTURE_ANSWERS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.SEND_ORGANIZATION_CULTURE_ANSWERS_REQUESTED,
        sendWorker
    );
    yield takeLatest(
        types.GET_ORGANIZATION_CULTURE_ANSWERS_REQUESTED,
        getWorker
    );
}

// Saga callback
function* sendWorker({ testId, categoryId, answers, callback }) {
    try {
        const response = yield call(send, { testId, categoryId, answers });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.SEND_ORGANIZATION_CULTURE_ANSWERS_SUCCEEDED,
            payload: {
                categoryId,
                data: {
                    data: answers.map(a => ({
                        questionId: a.question_id,
                        answerValue: a.value
                    }))
                }
            }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.SEND_ORGANIZATION_CULTURE_ANSWERS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function* getWorker({ testId, categoryId, callback }) {
    try {
        const response = yield call(get, { testId, categoryId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATION_CULTURE_ANSWERS_SUCCEEDED,
            payload: { categoryId, data: response.data }
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_ORGANIZATION_CULTURE_ANSWERS_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

// API call
function send({ testId, categoryId, answers }) {
    return api.post(`/api/admin/culture-test/category/answers`, {
        category_id: categoryId,
        answers,
        test_id: testId
    });
}

function get({ testId, categoryId }) {
    return api.get(
        `/api/admin/culture-test/${testId}/answers/category/${categoryId}`
    );
}
