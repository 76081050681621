import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";

// Actions
const types = {
    GET_TESTS_REQUESTED: "GET_TESTS_REQUESTED",
    GET_TESTS_SUCCEEDED: "GET_TESTS_SUCCEEDED",
    GET_TESTS_FAILED: "GET_TESTS_FAILED"
};

// Action Creators
export const actions = {
    getTests: () => {
        return {
            type: types.GET_TESTS_REQUESTED
        };
    }
};

// Dedault state
const defaultState = {
    data: [],
    invites: [],
    error: "",
    loading: false
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_TESTS_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_TESTS_SUCCEEDED:
            return {
                ...state,
                data: action.payload.data.tests,
                invites: action.payload.data.invites,
                loading: false
            };
        case types.GET_TESTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_TESTS_REQUESTED, getTestsWorker);
}

// Saga callback
function* getTestsWorker({ callback }) {
    try {
        const response = yield call(getTests);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_TESTS_SUCCEEDED,
            payload: response.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        yield put({ type: types.GET_TESTS_FAILED, payload: e.error });
    }
}

function getTests() {
    return api.get(`/api/subject/test-types`);
}
