import { takeLatest, call, put } from "redux-saga/effects";
import { callSuccess, callFail } from "Util/callback";

import api from "Util/api";

// Actions
const types = {
    IQ_GET_SEQUENCE_QUESTIONS_REQUESTED: "IQ_GET_SEQUENCE_QUESTIONS_REQUESTED",
    IQ_GET_SEQUENCE_QUESTIONS_SUCCEEDED: "IQ_GET_SEQUENCE_QUESTIONS_SUCCEEDED",
    IQ_GET_SEQUENCE_QUESTIONS_FAILED: "IQ_GET_SEQUENCE_QUESTIONS_FAILED",
    IQ_GET_SEQUENCE_TIME_REQUESTED: "IQ_GET_SEQUENCE_TIME_REQUESTED",
    IQ_GET_SEQUENCE_TIME_SUCCEEDED: "IQ_GET_SEQUENCE_TIME_SUCCEEDED",
    IQ_GET_SEQUENCE_TIME_FAILED: "IQ_GET_SEQUENCE_TIME_FAILED",
    IQ_SET_CURRENT_SEQUENCE_QUESTION_INDEX:
        "IQ_SET_CURRENT_SEQUENCE_QUESTION_INDEX"
};

// Action Creators
export const actions = {
    getQuestions: callback => {
        return {
            type: types.IQ_GET_SEQUENCE_QUESTIONS_REQUESTED,
            callback
        };
    },
    getTime: callback => {
        return {
            type: types.IQ_GET_SEQUENCE_TIME_REQUESTED,
            callback
        };
    },
    setCurrentQuestionIndex: index => {
        return {
            type: types.IQ_SET_CURRENT_SEQUENCE_QUESTION_INDEX,
            index
        };
    }
};

// Default state
const defaultState = {
    currentQuestionIndex: null,
    questions: [],
    questionsCountTotal: 0,
    time: null,
    loading: false,
    error: ""
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.IQ_GET_SEQUENCE_QUESTIONS_REQUESTED:
        case types.IQ_GET_SEQUENCE_TIME_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.IQ_GET_SEQUENCE_QUESTIONS_SUCCEEDED:
            const { questions, questionsCountTotal, time } = action.payload;
            return {
                ...state,
                questions,
                questionsCountTotal,
                time,
                loading: false,
                error: ""
            };
        case types.IQ_GET_SEQUENCE_TIME_SUCCEEDED:
            return {
                ...state,
                time: action.payload.remainingSeconds,
                loading: false,
                error: ""
            };
        case types.IQ_GET_SEQUENCE_QUESTIONS_FAILED:
        case types.IQ_GET_SEQUENCE_TIME_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case types.IQ_SET_CURRENT_SEQUENCE_QUESTION_INDEX:
            return {
                ...state,
                currentQuestionIndex: action.index
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(
        types.IQ_GET_SEQUENCE_QUESTIONS_REQUESTED,
        getQuestionsWorker
    );
    yield takeLatest(types.IQ_GET_SEQUENCE_TIME_REQUESTED, getTimeWorker);
}

// Saga callback
function* getQuestionsWorker({ callback }) {
    try {
        const response = yield call(getQuestions);
        let questionsCountTotal = 0;
        let questions = [];
        let time = 0;

        if (!response.ok) {
            throw response.data;
        }

        let data = response.data;

        if (data) {
            questionsCountTotal = data.sequenceQuestionsCountTotal;
            questions = data.sequenceQuestions;
            time = data.remainingSeconds;
        }

        yield put({
            type: types.IQ_GET_SEQUENCE_QUESTIONS_SUCCEEDED,
            payload: {
                questions,
                questionsCountTotal,
                time
            }
        });

        callSuccess(callback, data);
    } catch (e) {
        console.log(e);
        yield put({
            type: types.IQ_GET_SEQUENCE_QUESTIONS_FAILED,
            payload: e.message
        });
        callFail(callback, e.errors);
    }
}

function* getTimeWorker({ callback }) {
    try {
        const response = yield call(getTime);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.IQ_GET_SEQUENCE_TIME_SUCCEEDED,
            payload: response.data.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        console.log(e);
        yield put({
            type: types.IQ_GET_SEQUENCE_TIME_FAILED,
            payload: e.message
        });
        callFail(callback, e.errors);
    }
}

// API call
function getQuestions() {
    return api.get("/api/iq-test/questions/sequence");
}

function getTime() {
    return api.get("/api/iq-test/time/sequence");
}
