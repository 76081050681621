import { createStore, compose, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import throttle from "lodash/throttle";
import * as Sentry from "@sentry/react";

import { loadState, saveState } from "./localStorage";
import appReducer from "./reducers";
import rootSaga from "./sagas";

// Disable = {} || loadState()
const presistedState = loadState();

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
});

const rootReducer = (state, action) => {
    if (action.type === "LOGIN_USER_SUCCEEDED") {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

let store = createStore(
    rootReducer,
    presistedState,
    compose(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
);

if (process.env.NODE_ENV === "development") {
    store = createStore(
        rootReducer,
        presistedState,
        composeWithDevTools(applyMiddleware(sagaMiddleware))
    );
}

sagaMiddleware.run(rootSaga);

store.subscribe(
    throttle(() => {
        saveState({
            tests: store.getState().tests,
            user: {
                auth: store.getState().user.auth,
                data: store.getState().user.data,
                roles: store.getState().user.roles
            }
        });
    }, 1000)
);

export default store;
