import { takeLatest, call, put } from "redux-saga/effects";

import api from "Util/api";

// Actions
const types = {
    GET_INTERVIEW_REQUESTED: "GET_INTERVIEW_REQUESTED",
    GET_INTERVIEW_SUCCEEDED: "GET_INTERVIEW_SUCCEEDED",
    GET_INTERVIEW_FAILED: "GET_INTERVIEW_FAILED"
};

// Action Creators
export const actions = {
    get: (interviewId, subjectId, callback) => {
        return {
            type: types.GET_INTERVIEW_REQUESTED,
            interviewId,
            subjectId,
            callback
        };
    }
};

// Dedault state
const defaultState = {
    data: {},
    error: "",
    loading: true
};

// Reducers
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_INTERVIEW_SUCCEEDED:
            return {
                data: action.data,
                error: "",
                loading: false
            };
        case types.GET_INTERVIEW_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case types.GET_INTERVIEW_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

// Sagas
export function* saga() {
    yield takeLatest(types.GET_INTERVIEW_REQUESTED, getInterviewWorker);
}

// Saga callback
function* getInterviewWorker({ interviewId, subjectId, callback }) {
    try {
        const response = yield call(getInterview, { interviewId, subjectId });

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_INTERVIEW_SUCCEEDED,
            data: response.data.data
        });

        typeof callback == "function" && callback(response.data);
    } catch (e) {
        console.log(e.errors);
        yield put({ type: types.GET_INTERVIEW_FAILED, payload: e.errors });
    }
}

// API call
function getInterview({ interviewId, subjectId }) {
    return api.get(`/api/admin/interview/${interviewId}/subject/${subjectId}`);
}
