import { takeLatest, call, put } from "redux-saga/effects";
import api from "Util/api";
import { callSuccess, callFail } from "Util/callback";

const types = {
    GET_ORGANIZATIONS_WITH_PERMISSION_REQUESTED:
        "GET_ORGANIZATIONS_WITH_PERMISSION_REQUESTED",
    GET_ORGANIZATIONS_WITH_PERMISSION_SUCCEEDED:
        "GET_ORGANIZATIONS_WITH_PERMISSION_SUCCEEDED",
    GET_ORGANIZATIONS_WITH_PERMISSION_FAILED:
        "GET_ORGANIZATIONS_WITH_PERMISSION_FAILED"
};

export const actions = {
    getOrganziationsWithPermission: callback => {
        return {
            type: types.GET_ORGANIZATIONS_WITH_PERMISSION_REQUESTED,
            callback
        };
    }
};

const defaultState = {
    organziationsWithPermission: [],
    error: "",
    organziationsWithPermissionLoading: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.GET_ORGANIZATIONS_WITH_PERMISSION_REQUESTED:
            return {
                ...state,
                error: "",
                organziationsWithPermissionLoading: true
            };
        case types.GET_ORGANIZATIONS_WITH_PERMISSION_SUCCEEDED:
            return {
                ...state,
                organziationsWithPermission: action.payload.data,
                organziationsWithPermissionLoading: false
            };
        case types.GET_ORGANIZATIONS_WITH_PERMISSION_FAILED:
            return {
                ...state,
                error: action.payload,
                organziationsWithPermissionLoading: false
            };
        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(
        types.GET_ORGANIZATIONS_WITH_PERMISSION_REQUESTED,
        getOrganizationsWithPermissionWorker
    );
}

// Saga callback

function* getOrganizationsWithPermissionWorker({ callback }) {
    try {
        const response = yield call(getOrganizationsWithPermission);

        if (!response.ok) {
            throw response.data;
        }

        yield put({
            type: types.GET_ORGANIZATIONS_WITH_PERMISSION_SUCCEEDED,
            payload: response.data
        });

        callSuccess(callback, response.data);
    } catch (e) {
        yield put({
            type: types.GET_ORGANIZATIONS_WITH_PERMISSION_FAILED,
            payload: e.errors
        });
        callFail(callback, e.errors);
    }
}

function getOrganizationsWithPermission() {
    return api.get("/api/subject/organizations-with-permission");
}
