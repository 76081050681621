import { useEffect } from "react";
import find from "lodash/find";
import { useSelector, useDispatch } from "react-redux";
import burnoutStore from "Store/Burnout";
import mcqTestStore from "Store/MCQTest";

import useUser from "./user";
import testStore from "Store/Test";

const useTest = (load = true) => {
    const { isAuth } = useUser();

    const tests = useSelector(state => state.test.data);
    const loading = useSelector(state => state.test.loading);

    const burnouts = useSelector(state => state.burnout.list);
    const burnoutsLoading = useSelector(state => state.burnout.listLoading);
    const burnoutsCreating = useSelector(state => state.burnout.createLoading);

    const mcqTests = useSelector(state => state.mcqTest.list);
    const mcqTestsLoading = useSelector(state => state.mcqTest.listLoading);

    const dispatch = useDispatch();

    const hasTests = () => {
        return tests.length;
    };

    const hasBurnout = () => {
        return burnouts.length;
    };

    const hasMCQTest = () => {
        return mcqTests.length;
    };

    const latestBurnout = () => {
        if (!hasBurnout()) {
            return null;
        }

        return burnouts[0];
    };

    const mcqTestsList = () => {
        if (!hasMCQTest()) {
            return null;
        }

        return mcqTests;
    };

    useEffect(() => {
        if (isAuth() && load) {
            if (!loading) {
                dispatch(testStore.actions.getTests());
            }

            if (!burnoutsCreating && !burnoutsLoading && !burnouts.length) {
                dispatch(
                    burnoutStore.actions.getAll((error, response) => {
                        if (!error) {
                            if (!response.data.length) {
                                dispatch(burnoutStore.actions.create());
                            }
                        }
                    })
                );
            }

            if (!mcqTestsLoading && !mcqTests.length) {
                dispatch(mcqTestStore.actions.getAll());
            }
        }
    }, []);

    const hasPersonality = () => {
        return find(tests, { id: 1 });
    };

    const hasIQ = () => {
        return find(tests, { id: 2 });
    };

    const hasCulture = () => {
        return find(tests, { id: 3 });
    };

    const testsLoading = () => {
        return !!loading || !!burnoutsLoading || !!mcqTestsLoading;
    };

    const completePersonality = () => {
        const test = find(tests, { id: 1 });

        if (test) {
            return test.pivot.completed;
        }

        return false;
    };

    const completeIQ = () => {
        const test = find(tests, { id: 2 });

        if (test) {
            return test.pivot.completed;
        }

        return false;
    };

    const completeCulture = () => {
        const test = find(tests, { id: 3 });

        if (test) {
            return test.pivot.completed;
        }

        return false;
    };

    const completeBurnout = () => {
        const burnout = latestBurnout();
        if (burnout && burnout.hasOwnProperty("burnout_score")) {
            return burnout.burnout_score !== null;
        }

        return false;
    };

    return {
        testsLoading,
        hasTests,
        hasBurnout,
        latestBurnout,
        mcqTestsList,
        hasMCQTest,
        hasPersonality,
        hasIQ,
        hasCulture,
        completePersonality,
        completeIQ,
        completeCulture,
        completeBurnout
    };
};

export default useTest;
